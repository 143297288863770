import React from 'react'
import constant from '../../../constant'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Sidebar = () => {
  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <a href={constant.superAdminComponent.dashBoard.url} className="logo logo-dark">
          <span className="logo-sm">
            <img src="/images/logo/recruit-logo.png" alt="" height={47} />
          </span>
          <span className="logo-lg">
            <img src="/images/logo/recruit-logo.png" alt="" height={47} />
          </span>
        </a>
        {/* Light Logo*/}
        <a href={constant.superAdminComponent.dashBoard.url} className="logo logo-light">
          <span className="logo-sm">
            <img src="/images/logo/recruit-logo.png" alt="" height={47} />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-light.png" alt="" height={47} />
          </span>
        </a>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line" />
        </button>
      </div>
      <div id="scrollbar">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
            <li className="menu-title">
              <hr />

            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href={constant.superAdminComponent.dashBoard.url}
              >
                <i className="ri-dashboard-2-line" />
                <span data-key="t-dashboards">Dashboards</span>
              </a>

            </li>

            <li className="nav-item">
              <a
                className="nav-link menu-link"
                href="#sidebarMultilevel"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarMultilevel"
              >
                <i className="bx bx-sitemap" />
                <span data-key="t-multi-level">Client Management</span>
              </a>
              <div className="collapse menu-dropdown" id="sidebarMultilevel">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link to={constant.superAdminComponent.addClient.url} className="nav-link" >

                      Add Client
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      href={constant.superAdminComponent.listClient.url}
                      className="nav-link"
                    >

                      Search Client
                    </a>

                  </li>
                </ul>
              </div>
            </li>


            <li className="nav-item">
              <Link
                className="nav-link"
                to={constant.superAdminComponent.clientLead.url}
              >
                <i className="ri-pages-line" />
                <span data-key="t-pages">Client Lead</span>
              </Link>

            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to={constant.superAdminComponent.offerLetter.url}
              >
                <i className="ri-pages-line" />
                <span data-key="t-pages">Offer Letter</span>
              </Link>

            </li>
          </ul>
        </div>
        {/* Sidebar */}
      </div>

      <div className="sidebar-background" />
    </div>


  )
}

export default Sidebar