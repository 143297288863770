import React, { useEffect, useState } from 'react'
import { v3_dashboard_clients, v3_dashboard_count_stats } from '../../../actions/v3/dashboard'
import CountStats from '../../../components/companyUser/dashboard/count_stats'
import Clients from '../../../components/companyUser/dashboard/clients'
import Reminder from '../../../components/companyUser/dashboard/Reminder'
import ApplicationStatistics from '../../../components/companyUser/dashboard/applicationStatistics'
import { getGreeting, getStorage } from '../../../utils'
import styles from './dashboard.module.css'
import constant from '../../../constant'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import BreadCrumb from '../../../components/common/BreadCrumb'
import Overview from '../../../components/companyUser/dashboard/overview'
const Index = () => {

  const [countStats, setCountStats] = useState(null);
  const [clientList, setClientList] = useState(null);
  const [ud, setUd] = useState(getStorage(constant.keys.companyUserDetails))

  useEffect(() => {
    v3_dashboard_count_stats().then((res) => {
      if (res.status) {
        setCountStats(res.result)
      }
    })

  }, [])





  useEffect(() => {

    v3_dashboard_clients().then((res) => {
      if (res.status) {
        setClientList(res.result.list)
      }
    })

  }, [])

  return (
    <React.Fragment>

      <div className="container-fluid">
        <BreadCrumb
          page={<React.Fragment>{getGreeting()}, <span>{ud.FULL_NAME}</span></React.Fragment>}
          parentSection={'Dashboard'}
        />
        <div className="row">
          <div className="col-md-9">

            <CountStats
              countStats={countStats}
            />
            <div className='row'>
              <ApplicationStatistics />
              <Overview
                countStats={countStats}

              />
            </div>


          </div>

          <Reminder />

          {/*end col*/}
          <div className="col-xl-12 clientTableSection">
            <Clients
              clientList={clientList}
            />
          </div>
        </div>


      </div>


    </React.Fragment>
  )
}

export default Index