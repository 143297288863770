import React from 'react'
import moment from 'moment'
import constant from '../../../../constant'
import BreadCrumb from '../../../../components/common/BreadCrumb'
import styles from './jobList.module.css'

const NonPosted = (props) => {
    return (
        <React.Fragment>
           
                    <div className="container-fluid">
                        <BreadCrumb
                            page={'Incomplete Jobs'}
                            parentSection={
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <a href="javascript: void(0);">Jobs</a>
                                    </li>
                                    <li className="breadcrumb-item active">Incomplete Jobs</li>
                                </ol>
                            }
                        />
                        
                       
                        <div className="row" id="job-list">
                            <div className="col-12">
                                <div className="list-group">
                                    {props.jobList && props.jobList.map((item) => {
                                        return (
                                            <a
                                                href={constant.companyUserComponent.updateJob.url.replace(':step', item.COMPLETED_STEPS).replace(':job_id', item.JOB_ID)}
                                                className={` ${styles.listPendingJobs} list-group-item list-group-item-action`}

                                            >
                                                {/* <div className="float-end"><span class="badge badge-label bg-warning"><i class="mdi mdi-circle-medium"></i> Pending</span> */}
                                                    {/* <div className="alert border-0 alert-warning  mt-2" role="alert">
                                                        {item.COMPLETED_STEPS == 2 ? 'Please Complete Step 3' : item.COMPLETED_STEPS == 3 ? 'Review and Post Job' : ''}
                                                    </div> */}

                                                {/* </div> */}
                                                <div className="flex-grow-1 ms-3">
                                                    <div className={styles.companyMainbox} >
                                                        <div className={styles.companyLogobox}>
                                                            <img
                                                                src={item.LOGO ? `${process.env.REACT_APP_BASE_URL}/v3/client-logo/${item.LOGO}` : '/assets/images/comapnylogo.png'}
                                                            />
                                                        </div>
                                                        <div className={styles.aboutCompanyname}>
                                                            <h5 className={styles.listTitle}>{item.JOB_TITLE}</h5>
                                                            <h6 className={styles.listSubTitle}>{item.COMPANY_NAME}</h6>

                                                            {/* <p className="list-text mb-3 fs-12"><strong>Last Updated </strong> {moment(item.MODIFIED_ON).fromNow()}</p> */}
                                                            <p className={styles.paraExInfo}>Work Experience : <strong>{item.WORK_EXP_MIN + ' Year - ' + item.WORK_EXP_MAX + ' Year'}</strong> , Salary Range : <strong>{item.SALARY_MIN + ' Lakh - ' + item.SALARY_MAX + ' Lakh'}</strong></p>

                                                            <p className="list-text mb-3 fs-12"></p>
                                                            <p className={styles.badgePending}>    {item.COMPLETED_STEPS == 2 ? 'Please Complete Step 3' : item.COMPLETED_STEPS == 3 ? 'Review and Post Job' : ''}</p>

                                                        </div>
                                                    </div>
                                                </div>

                                            </a>)
                                    })
                                    }

                                </div>
                            </div>


                        </div>

                    </div>
            
        </React.Fragment>
    )
}

export default NonPosted