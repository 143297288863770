import React, { useEffect, useState } from "react";
import DrawerModal from "../../../components/common/htmlDrawerModal";
import CanididateDetailDrawerModal from "./canididateDetailDrawerModal";
import Pagination from "../../common/Pagination";
import Modal from "../../common/htmlModal";
import Edit from "./editModal";
import constant from "../../../constant";
import { v3_add_candidate_reminder, v3_edit_candidate, v3_lead_owners, v3_role_wise_lead_owners } from "../../../actions/v3/candidates";
import { ToastContainer, toast } from "react-toastify";
import { v1_application_status_options, v1_candidate_status_options } from "../../../actions/v1/master_data";
import ReminderForm from "./reminderForm";
import moment from "moment";
import UpdateHiringStatusModal from "../jobs/jobApplications/updateHiringStatusModal";
import { v3_update_hiring_status } from "../../../actions/v3/jobApplication";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import styles from '../candidates/candidate.module.css';
import BreadCrumb from '../../../components/common/BreadCrumb';


const List = (props) => {
  const { list, count, currentPage, limit, setLimit } = props;

  const [showCandidateDetailDrawerModal, setShowCandidateDetailDrawerModal] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [showEditModal, setShowEditModal] = useState(null);
  const [showAddReminderModal, setShowAddRemoinderModal] = useState(null);
  const [updateHiringStatus, setUpdateHiringStatus] = useState(null)



  const [searchState, setSearchState] = useState({
    CANDIDATE_NAME: {
      name: "CANDIDATE_NAME",
      value: "",
      error: "",
      isRequired: false,
    },
    EMAIL: {
      name: "EMAIL",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    PHONE: {
      name: "PHONE",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    SKILLS: {
      name: "SKILLS",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    CURRENT_CTC_MIN: {
      name: "CURRENT_CTC_MIN",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    CURRENT_CTC_MAX: {
      name: "CURRENT_CTC_MAX",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    NOTICE_PERIOD: {
      name: "NOTICE_PERIOD",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    EXPERIENCE_MIN: {
      name: "EXPERIENCE_MIN",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    EXPERIENCE_MAX: {
      name: "EXPERIENCE_MAX",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    AVERAGE_RATING: {
      name: "AVERAGE_RATING",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    SOURCE: {
      name: "SOURCE",
      value: "",
      visible: false,
      error: "",
      isRequired: false,
    },
    LEAD_OWNER: {
      name: "LEAD_OWNER",
      value: "",
      options: [],

      visible: false,
      error: "",
      isRequired: false,
    },
    CANDIDATE_STATUS: {
      name: "CANDIDATE_STATUS",
      value: "",
      options: [],

      visible: false,
      error: "",
      isRequired: false,
    },
    APPLICATION_STATUS: {
      name: "APPLICATION_STATUS",
      value: "",
      options: [],

      visible: false,
      error: "",
      isRequired: false,
    },
    FROM_DATE: {
      name: "FROM_DATE",
      value: '',
      options: "",

      visible: false,
      error: "",
      isRequired: false,
    },
    TO_DATE: {
      name: "TO_DATE",
      value: '',
      options: "",

      visible: false,
      error: "",
      isRequired: false,
    },
    ADDED_VIA: {
      name: "ADDED_VIA",
      value: '',
      visible: false,
      error: "",
      isRequired: false,
    },
  });
  const setOptions = (name, options) => {
    setSearchState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        options: options
      }
    }));
  }

  useEffect(() => {
    v3_role_wise_lead_owners().then((res) => {
      if (res.status) {
        setOptions('LEAD_OWNER', res.result)
      }
    })
    // v1_candidate_status_options().then((res) => {
    //   if (res.status) {
    //     setOptions('CANDIDATE_STATUS', res.result)
    //   }
    // })
    v1_application_status_options().then((res) => {
      if (res.status) {
        setOptions('APPLICATION_STATUS', res.result)
      }
    })

  }, [])


  const onUpdateHiringStatus = (model) => {
    v3_update_hiring_status({ ...model, APPLICATION_ID: updateHiringStatus.APPLICATION_ID }).then((res) => {
      if (res.status) {
        toast.success('Hiring Status Changed');
        setUpdateHiringStatus(null);
        props.setRefresh(!props.refresh)

      }
      else {
        toast.error(res.error)

      }
    })

  }

  const onReset = () => {
    setSearchState({
      CANDIDATE_NAME: {
        name: "CANDIDATE_NAME",
        value: "",
        error: "",
        isRequired: false,
      },
      EMAIL: {
        name: "EMAIL",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      PHONE: {
        name: "PHONE",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      SKILLS: {
        name: "SKILLS",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      CURRENT_CTC_MIN: {
        name: "CURRENT_CTC_MIN",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      CURRENT_CTC_MAX: {
        name: "CURRENT_CTC_MAX",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      NOTICE_PERIOD: {
        name: "NOTICE_PERIOD",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      EXPERIENCE_MIN: {
        name: "EXPERIENCE_MIN",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      EXPERIENCE_MAX: {
        name: "EXPERIENCE_MAX",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      AVERAGE_RATING: {
        name: "AVERAGE_RATING",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
      SOURCE: {
        name: "SOURCE",
        value: "",
        visible: false,
        error: "",
        isRequired: false,
      },
    });
    const model = {
      CANDIDATE_NAME: "",
      EMAIL: "",
      PHONE: "",
      SKILLS: "",
      CURRENT_CTC_MIN: "",
      CURRENT_CTC_MAX: "",
      NOTICE_PERIOD: "",
      EXPERIENCE_MIN: "",
      EXPERIENCE_MAX: "",
      AVERAGE_RATING: "",
      SOURCE: "",
      ADDED_VIA: ""
    };

    props.setSearchQuery(model);
  };
  const onSearch = () => {
    const model = {
      CANDIDATE_NAME: searchState.CANDIDATE_NAME.value,
      EMAIL: searchState.EMAIL.value,
      PHONE: searchState.PHONE.value,
      SKILLS: searchState.SKILLS.value,
      CURRENT_CTC_MIN: searchState.CURRENT_CTC_MIN.value,
      CURRENT_CTC_MAX: searchState.CURRENT_CTC_MAX.value,
      NOTICE_PERIOD: searchState.NOTICE_PERIOD.value,
      EXPERIENCE_MIN: searchState.EXPERIENCE_MIN.value,
      EXPERIENCE_MAX: searchState.EXPERIENCE_MAX.value,
      AVERAGE_RATING: searchState.AVERAGE_RATING.value,
      SOURCE: searchState.SOURCE.value,
      LEAD_OWNER: searchState.LEAD_OWNER.value,
      FROM_DATE: searchState.FROM_DATE.value,
      TO_DATE: searchState.TO_DATE.value,
      CANDIDATE_STATUS: searchState.CANDIDATE_STATUS.value,
      APPLICATION_STATUS: searchState.APPLICATION_STATUS.value,
      ADDED_VIA: searchState.ADDED_VIA.value


    };

    props.setSearchQuery(model);
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchState((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
      },
    }));
  };

  const onEdit = (model) => {
    v3_edit_candidate(model).then((res) => {
      if (res.status) {
        setShowEditModal(false)
        toast.success('Candidate Lead Saved')
        props.setRefresh(!props.refresh)
      }
      else {
        toast.error(res.error)
      }
    })
  }
  const onAddRemninder = (model) => {
    v3_add_candidate_reminder(model).then((res) => {
      if (res.status) {
        setShowAddRemoinderModal(null)
        toast.success('Reminder Added Successfully')
      }
      else {
        toast.error(res.error)
      }
    })
  }


  const onDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(list.map((item) => {
      const model = {
        'Candidate ID ': 'RJZSHCL' + item.CANDIDATE_ID,
        'Email': item.EMAIL,
        'Mobile': item.PHONE,
        'Skills': item.SKILLS,
        'Current Company': item.CURRENT_COMPANY ? item.CURRENT_COMPANY : 'Not Available',
        'Current Designation': item.CURRENT_DESIGNATION ? item.CURRENT_DESIGNATION : 'Not Available',
        'Notice Period': item.NOTICE_PERIOD ? item.NOTICE_PERIOD : 'Not Available',
        'Notice Period': item.PREFERRED_ROLE_NAME ? item.PREFERRED_ROLE_NAME : 'Not Available',
        'Hiring Status': item.HIRING_STATUS_NAME,
        'Lead Owner': item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available',
        'Added By': item.CREATED_BY_NAME ? item.CREATED_BY_NAME + ' at ' + moment(item.CREATED_ON).fromNow() : 'Not Available'
      }
      return model
    }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, 'hiring_lead.xlsx');
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <Modal 
              className={styles.profilViewModalSection}
              isOpen={showCandidateDetailDrawerModal}
              onClose={() => {
                setShowCandidateDetailDrawerModal(false);
              }}
            >
              {candidateDetails && (
                <CanididateDetailDrawerModal
                  showEditOption={true}
                  candidateDetails={candidateDetails}
                />
              )}
            </Modal>
            <Modal isOpen={showEditModal} header={'Edit Candidate Lead Details'}
              onClose={() => { setShowEditModal(null) }}
            >
              <Edit
                data={showEditModal}
                onClose={() => { setShowEditModal(null) }}
                onSubmit={(model) => { onEdit(model) }}
              />
            </Modal>
            <Modal isOpen={showAddReminderModal} header={'Add Reminder'}>
              <ReminderForm
                onSubmit={(model) => (onAddRemninder(model))}
                onClose={() => setShowAddRemoinderModal(null)}
                data={showAddReminderModal}
              />
            </Modal>

            <Modal
              isOpen={updateHiringStatus}
              onClose={() => { setUpdateHiringStatus(null) }}
              header={'Update Hiring Status'}
            >
              {
                updateHiringStatus && <UpdateHiringStatusModal
                  onSave={(id) => { onUpdateHiringStatus(id) }}
                />
              }
            </Modal>

            <BreadCrumb
              page={'Hiring Candidates'}
              parentSection={<ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">Candidate Management</a>
                </li>
                <li className="breadcrumb-item active">
                  Hiring Candidates
                </li>
              </ol>}
            />
            {/* <div className="row">
              <div className="col-md-7">
                <div className={styles.searchResultBox}>
                  <span className={styles.spanB1}><i className={` ${styles.toppos} mdi mdi-arrow-left`} /> {count}</span>
                  <span className={styles.spanB2}>profiles found for</span>
                  <span className={styles.spanB3}>Software Developer, Fullstack ...</span>
                  <span className={styles.spanB4}>View Details <i className={` ${styles.topIcoin} ri-arrow-down-s-line`} /></span>
                </div>
              </div>
              <div className="col-md-3">
                <div className={styles.midSection}>
                  <span>Modify search criteria</span>
                </div>
              </div>
              <div className="col-md-2">
                <div className={`${styles.saveSearchSection}`}>
                  <button
                    type="button"
                    className="btn btn-primary add-btn ms-1"
                    onClick={() => onDownloadExcel()}
                  >

                    Save Search <i className="bx bx-bookmark align-bottom me-1" />
                  </button>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-3">
                <div className={styles.leftFilterSection}>
                  <div className={styles.filterTitlebox}>
                    <h5><span className={styles.span1}>Filter By</span>
                      {/* <span className={styles.span2}>Applies (8)</span> */}
                    </h5>

                  </div>
                  <div className={styles.filterInnerbox}>

                    <div className="search-box">
                      <label>Candidate Name: <span className="text-danger">&nbsp;*</span></label>
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Candidate Name"
                        id='candidateName'
                        name={searchState.CANDIDATE_NAME.name}
                        value={searchState.CANDIDATE_NAME.value}
                        onChange={onChange}
                      />
                      <i className={` ${styles.toppos} ri-search-line search-icon`} />
                    </div>
                    <div className="search-box">
                      <label>Email: <span className="text-danger">&nbsp;*</span></label>
                      <input
                        type="text"
                        className="form-control search customRadiusSearch"
                        placeholder="Email"
                        name={searchState.EMAIL.name}
                        value={searchState.EMAIL.value}
                        onChange={onChange}
                      />
                      <i className={`  ${styles.toppos} ri-mail-line search-icon`} />
                    </div>
                    <div className="search-box">
                      <label>Phone Number: <span className="text-danger">&nbsp;*</span></label>
                      <input
                        type="number"
                        min={5}
                        className="form-control search customRadiusSearch"
                        placeholder="Phone"
                        name={searchState.PHONE.name}
                        value={searchState.PHONE.value}
                        onChange={onChange}
                      />
                      <i className={`  ${styles.toppos} ri-phone-line search-icon`} />
                    </div>
                    <div className="">
                      <label>Skills: <span className="text-danger">&nbsp;&nbsp;*</span></label>
                      <input
                        type="text"
                        className="form-control customRadiusSearch"
                        placeholder="Skills"
                        name={searchState.SKILLS.name}
                        value={searchState.SKILLS.value}
                        onChange={onChange}
                      />
                    </div>

                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            CTC
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <div className={`custom-control ${styles.customCheckbox}`} onClick={() => {
                              setSearchState((prevState) => ({
                                ...prevState,
                                ['CURRENT_CTC_MIN']: {
                                  ...prevState['CURRENT_CTC_MIN'],
                                  value: 0,
                                },
                                ['CURRENT_CTC_MAX']: {
                                  ...prevState['CURRENT_CTC_MAX'],
                                  value: 3,
                                },
                              }));
                            }}>
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                checked={searchState.CURRENT_CTC_MIN.value == 0 && searchState.CURRENT_CTC_MAX.value == 3}
                              />
                              <label className="custom-control-label" for="customCheck2">0-3 LPA</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`} onClick={() => {
                              setSearchState((prevState) => ({
                                ...prevState,
                                ['CURRENT_CTC_MIN']: {
                                  ...prevState['CURRENT_CTC_MIN'],
                                  value: 3,
                                },
                                ['CURRENT_CTC_MAX']: {
                                  ...prevState['CURRENT_CTC_MAX'],
                                  value: 5,
                                },
                              }));
                            }}
                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2" checked={searchState.CURRENT_CTC_MIN.value == 3 && searchState.CURRENT_CTC_MAX.value == 5} />
                              <label className="custom-control-label" for="customCheck2">3-5 LPA</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`}
                              onClick={() => {
                                setSearchState((prevState) => ({
                                  ...prevState,
                                  ['CURRENT_CTC_MIN']: {
                                    ...prevState['CURRENT_CTC_MIN'],
                                    value: 5,
                                  },
                                  ['CURRENT_CTC_MAX']: {
                                    ...prevState['CURRENT_CTC_MAX'],
                                    value: 7,
                                  },
                                }));
                              }}

                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                checked={searchState.CURRENT_CTC_MIN.value == 5 && searchState.CURRENT_CTC_MAX.value == 7}
                              />
                              <label className="custom-control-label" for="customCheck2">5-7 LPA</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`}>
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                onClick={() => {
                                  setSearchState((prevState) => ({
                                    ...prevState,
                                    ['CURRENT_CTC_MIN']: {
                                      ...prevState['CURRENT_CTC_MIN'],
                                      value: 7,
                                    },
                                    ['CURRENT_CTC_MAX']: {
                                      ...prevState['CURRENT_CTC_MAX'],
                                      value: 10,
                                    },
                                  }));
                                }}
                              />
                              <label className="custom-control-label" for="customCheck2">7-10 LPA</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`}
                              onClick={() => {
                                setSearchState((prevState) => ({
                                  ...prevState,
                                  ['CURRENT_CTC_MIN']: {
                                    ...prevState['CURRENT_CTC_MIN'],
                                    value: 10,
                                  },
                                  ['CURRENT_CTC_MAX']: {
                                    ...prevState['CURRENT_CTC_MAX'],
                                    value: 15,
                                  },
                                }));
                              }}
                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                checked={searchState.CURRENT_CTC_MIN.value == 10 && searchState.CURRENT_CTC_MAX.value == 15}
                              />
                              <label className="custom-control-label" for="customCheck2">10-15 LPA</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`}
                              onClick={() => {
                                setSearchState((prevState) => ({
                                  ...prevState,
                                  ['CURRENT_CTC_MIN']: {
                                    ...prevState['CURRENT_CTC_MIN'],
                                    value: 15,
                                  },
                                  ['CURRENT_CTC_MAX']: {
                                    ...prevState['CURRENT_CTC_MAX'],
                                    value: 20,
                                  },
                                }));
                              }}
                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2"

                                checked={searchState.CURRENT_CTC_MIN.value == 15 && searchState.CURRENT_CTC_MAX.value == 20}


                              />
                              <label className="custom-control-label" for="customCheck2">15-20 LPA</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`}

                              onClick={() => {
                                setSearchState((prevState) => ({
                                  ...prevState,
                                  ['CURRENT_CTC_MIN']: {
                                    ...prevState['CURRENT_CTC_MIN'],
                                    value: 20,
                                  },
                                  ['CURRENT_CTC_MAX']: {
                                    ...prevState['CURRENT_CTC_MAX'],
                                    value: 30,
                                  },
                                }));
                              }}
                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                checked={searchState.CURRENT_CTC_MIN.value == 20 && searchState.CURRENT_CTC_MAX.value == 30}

                              />
                              <label className="custom-control-label" for="customCheck2">20-30 LPA</label>
                            </div>
                            {/* <a className={styles.showMoreBtn} >Show More</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion" id="accordionExample2">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSecond">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
                            Notice Period
                          </button>
                        </h2>
                        <div id="collapsetwo" className="accordion-collapse collapse show" aria-labelledby="headingSecond" data-bs-parent="#accordionExample2">
                          <div className={`${styles.accordionBody} accordion-body`}>
                            <select
                              className="form-select customRadiusSearch"
                              name={searchState.NOTICE_PERIOD.name}
                              value={searchState.NOTICE_PERIOD.value}
                              onChange={onChange}
                            >
                              <option value=""> Notice Period</option>
                              <option value="Immediate Joiner">
                                {" "}
                                Immediate Joiner
                              </option>
                              <option value="15 Days"> 15 Days</option>
                              <option value="1 Month"> 1 Month</option>
                              <option value="2 Month"> 2 Month</option>
                              <option value="3 Month"> 3 Month or more</option>
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion" id="accordionExample3">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingthree">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                            Experience
                          </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingthree" data-bs-parent="#accordionExample3">
                          <div className="accordion-body">
                            <div className={`custom-control ${styles.customCheckbox}`}
                              onClick={() => {
                                setSearchState((prevState) => ({
                                  ...prevState,
                                  ['EXPERIENCE_MIN']: {
                                    ...prevState['EXPERIENCE_MIN'],
                                    value: 0,
                                  },
                                  ['EXPERIENCE_MAX']: {
                                    ...prevState['EXPERIENCE_MAX'],
                                    value: 1,
                                  },
                                }));
                              }}
                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                checked={searchState.EXPERIENCE_MIN.value == 0 && searchState.EXPERIENCE_MAX.value == 1}

                              />
                              <label className="custom-control-label" for="customCheck2">Fresher</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`}
                              onClick={() => {
                                setSearchState((prevState) => ({
                                  ...prevState,
                                  ['EXPERIENCE_MIN']: {
                                    ...prevState['EXPERIENCE_MIN'],
                                    value: 2,
                                  },
                                  ['EXPERIENCE_MAX']: {
                                    ...prevState['EXPERIENCE_MAX'],
                                    value: 5,
                                  },
                                }));
                              }}
                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                checked={searchState.EXPERIENCE_MIN.value == 2 && searchState.EXPERIENCE_MAX.value == 5}

                              />
                              <label className="custom-control-label" for="customCheck2">2-5 Years</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`}
                              onClick={() => {
                                setSearchState((prevState) => ({
                                  ...prevState,
                                  ['EXPERIENCE_MIN']: {
                                    ...prevState['EXPERIENCE_MIN'],
                                    value: 5,
                                  },
                                  ['EXPERIENCE_MAX']: {
                                    ...prevState['EXPERIENCE_MAX'],
                                    value: 10,
                                  },
                                }));
                              }}
                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                checked={searchState.EXPERIENCE_MIN.value == 5 && searchState.EXPERIENCE_MAX.value == 10}

                              />
                              <label className="custom-control-label" for="customCheck2">5-10 Years</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`}
                              onClick={() => {
                                setSearchState((prevState) => ({
                                  ...prevState,
                                  ['EXPERIENCE_MIN']: {
                                    ...prevState['EXPERIENCE_MIN'],
                                    value: 10,
                                  },
                                  ['EXPERIENCE_MAX']: {
                                    ...prevState['EXPERIENCE_MAX'],
                                    value: 15,
                                  },
                                }));
                              }}
                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                checked={searchState.EXPERIENCE_MIN.value == 10 && searchState.EXPERIENCE_MAX.value == 15}

                              />
                              <label className="custom-control-label" for="customCheck2">10-15 Years</label>
                            </div>
                            <div className={`custom-control ${styles.customCheckbox}`}
                              onClick={() => {
                                setSearchState((prevState) => ({
                                  ...prevState,
                                  ['EXPERIENCE_MIN']: {
                                    ...prevState['EXPERIENCE_MIN'],
                                    value: 15,
                                  },
                                  ['EXPERIENCE_MAX']: {
                                    ...prevState['EXPERIENCE_MAX'],
                                    value: 20,
                                  },
                                }));
                              }}
                            >
                              <input type="checkbox" class="custom-control-input" id="customCheck2"
                                checked={searchState.EXPERIENCE_MIN.value == 15 && searchState.EXPERIENCE_MAX.value == 20}

                              />
                              <label className="custom-control-label" for="customCheck2">15-20 Years</label>
                            </div>
                            {/* <a className={styles.showMoreBtn} >Show More</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="ctc">
                            <input
                              type="number"
                              className="form-control customRadiusSearch"
                              placeholder="CTC Min"
                              name={searchState.CURRENT_CTC_MIN.name}
                              value={searchState.CURRENT_CTC_MIN.value}
                              onChange={onChange}
                            />
                          </div> */}
                    {/* <div className="mxCtc">
                           
                            <input
                              type="number"
                              className="form-control customRadiusSearch"
                              placeholder="CTC Max"
                              name={searchState.CURRENT_CTC_MAX.name}
                              value={searchState.CURRENT_CTC_MAX.value}
                              onChange={onChange}
                            />
                           
                          </div> 
                          <div className="noticePeriod">
                        
                      </div>*/}
                    {/* <div className="ExMin">
                            <input
                              type="number"
                              className="form-control customRadiusSearch"
                              placeholder="Experiece Min"
                              name={searchState.EXPERIENCE_MIN.name}
                              value={searchState.EXPERIENCE_MIN.value}
                              onChange={onChange}
                            />
                          </div> */}
                    {/* <div className="expMax">
                           
                            <input
                              type="number"
                              className="form-control customRadiusSearch"
                              placeholder="Experiece Max"
                              name={searchState.EXPERIENCE_MAX.name}
                              value={searchState.EXPERIENCE_MAX.value}
                              onChange={onChange}
                            />
                            
                          </div> */}


                    <div className="accordion" id="accordionExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                            Lead Source
                          </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample4">
                          <div className={`${styles.accordionBody} accordion-body`}>
                            <select
                              className="form-select customRadiusSearch"
                              name={searchState.SOURCE.name}
                              value={searchState.SOURCE.value}
                              onChange={onChange}
                            >
                              <option value=''> Select Lead Source</option>
                              <option value='Found It / Monster'> Found It / Monster</option>
                              <option value='Naukri'> Naukri</option>
                              <option value='LinkedIn'> LinkedIn</option>
                              <option value='Instagram'> Instagram</option>
                              <option value='apna'> apna</option>
                              <option value='Campus'> Campus</option>
                              <option value='Other Portal'> Other Portal</option>
                              <option value='Referral'> Referral</option>
                              <option value='Social Media'> Social Media</option>
                              <option value='Walk-In'> Walk-In</option>
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="accordion" id="accordionExample4">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                            Rating
                          </button>
                        </h2>
                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample4">
                          <div className={`${styles.accordionBody} accordion-body`}>
                            <select
                              className="form-select customRadiusSearch"
                              name={searchState.AVERAGE_RATING.name}
                              value={searchState.AVERAGE_RATING.value}
                              onChange={onChange}
                            >
                              <option value=''> Select Rating</option>
                              <option value='1'> 1</option>
                              <option value='2'> 2</option>
                              <option value='3'> 3</option>
                              <option value='4'> 4</option>
                              <option value='5'> 5</option>

                            </select>

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion" id="accordionExample5">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFive">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                            Lead Owner
                          </button>
                        </h2>
                        <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#accordionExample5">
                          <div className={`${styles.accordionBody} accordion-body`}>
                            <select
                              className="form-select customRadiusSearch"
                              name={searchState.LEAD_OWNER.name}
                              value={searchState.LEAD_OWNER.value}
                              onChange={onChange}
                            >
                              <option value=""> Lead Owner</option>
                              {searchState.LEAD_OWNER.options && searchState.LEAD_OWNER.options?.map((item) => {
                                return (
                                  <option value={item.USER_ID}> {item.FULL_NAME}</option>

                                )
                              })}
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="ratingBox">
                        <input
                          className="form-control customRadiusSearch"
                          type="number"
                          name={searchState.AVERAGE_RATING.name}
                          value={searchState.AVERAGE_RATING.value}
                          onChange={onChange}
                          placeholder="Rating"
                        />
                        
                      </div> */}
                    {/* <div className="leadowner">
                        <select
                          className="form-select customRadiusSearch"
                          name={searchState.LEAD_OWNER.name}
                          value={searchState.LEAD_OWNER.value}
                          onChange={onChange}
                        >
                          <option value=""> Lead Owner</option>
                          {searchState.LEAD_OWNER.options && searchState.LEAD_OWNER.options?.map((item) => {
                            return (
                              <option value={item.USER_ID}> {item.FULL_NAME}</option>

                            )
                          })}
                        </select>
                      </div> */}
                    <div className="accordion" id="accordionExample6">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSix">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                            Hiring Status
                          </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#accordionExample6">
                          <div className={`${styles.accordionBody} accordion-body`}>
                            <select
                              className="form-select customRadiusSearch"
                              name={searchState.APPLICATION_STATUS.name}
                              value={searchState.APPLICATION_STATUS.value}
                              onChange={onChange}
                            >
                              <option value=""> Hiring Status</option>
                              {searchState.APPLICATION_STATUS.options && searchState.APPLICATION_STATUS.options?.map((item) => {
                                return (
                                  <option value={item.STATUS_ID}> {item.STATUS_NAME}</option>

                                )
                              })}
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="hiringStatus">
                        <select
                          className="form-select customRadiusSearch"
                          name={searchState.APPLICATION_STATUS.name}
                          value={searchState.APPLICATION_STATUS.value}
                          onChange={onChange}
                        >
                          <option value=""> Hiring Status</option>
                          {searchState.APPLICATION_STATUS.options && searchState.APPLICATION_STATUS.options?.map((item) => {
                            return (
                              <option value={item.STATUS_ID}> {item.STATUS_NAME}</option>

                            )
                          })}
                        </select>
                      </div> */}
                    <div className="accordion" id="accordionExample7">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingSeven">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                            Added Via
                          </button>
                        </h2>
                        <div id="collapseSeven" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#accordionExample7">
                          <div className={`${styles.accordionBody} accordion-body`}>
                            <select
                              className="form-select customRadiusSearch"
                              name={searchState.ADDED_VIA.name}
                              value={searchState.ADDED_VIA.value}
                              onChange={onChange}
                            >
                              <option value=""> Added Via</option>
                              <option value="ATS Recruiter"> ATS Recruiter</option>
                              <option value="Quick Add"> Quick Add</option>
                              <option value="Bulk Upload"> Bulk Upload</option>
                              <option value="Walk-in QR"> Walk-in QR</option>
                            </select>

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="addedVia">
                        <select
                          className="form-select customRadiusSearch"
                          name={searchState.ADDED_VIA.name}
                          value={searchState.ADDED_VIA.value}
                          onChange={onChange}
                        >
                          <option value=""> Added Via</option>
                          <option value="ATS Recruiter"> ATS Recruiter</option>
                          <option value="Quick Add"> Quick Add</option>
                          <option value="Bulk Upload"> Bulk Upload</option>
                          <option value="Walk-in QR"> Walk-in QR</option>
                        </select>
                      </div> */}

                    <div className="accordion" id="accordionExample8">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingEight">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">
                            From Date
                          </button>
                        </h2>
                        <div id="collapseEight" className="accordion-collapse collapse show" aria-labelledby="headingEight" data-bs-parent="#accordionExample8">
                          <div className={`${styles.accordionBody} accordion-body`}>
                            <input
                              type="date"
                              id='fromDate'
                              className="form-control customRadiusSearch"
                              name={searchState.FROM_DATE.name}
                              value={searchState.FROM_DATE.value}
                              onChange={onChange}
                              max={searchState.TO_DATE.value}
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion" id="accordionExample9">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingNine">
                          <button className={` ${styles.accordionButton} accordion-button`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">
                            To Date
                          </button>
                        </h2>
                        <div id="collapseNine" className="accordion-collapse collapse show" aria-labelledby="headingNine" data-bs-parent="#accordionExample9">
                          <div className={`${styles.accordionBody} accordion-body`}>
                            <input
                              type="date"
                              id="toDate"
                              className="form-control customRadiusSearch"
                              name={searchState.TO_DATE.name}
                              value={searchState.TO_DATE.value}
                              onChange={onChange}
                              min={searchState.FROM_DATE.value}
                            />

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-floating">

                              <input
                                type="date"
                                id='fromDate'
                                className="form-control customRadiusSearch"
                                name={searchState.FROM_DATE.name}
                                value={searchState.FROM_DATE.value}
                                onChange={onChange}
                                max={searchState.TO_DATE.value}
                              />
                              <label htmlFor="fromDate"> From Date</label>
                            </div> */}



                    {/* <div className="form-floating">

                              <input
                                type="date"
                                id="toDate"
                                className="form-control customRadiusSearch"
                                name={searchState.TO_DATE.name}
                                value={searchState.TO_DATE.value}
                                onChange={onChange}
                                min={searchState.FROM_DATE.value}
                              />
                              <label htmlFor="toDate"> To Date</label>

                            </div> */}

                    <div className={` ${styles.gap10} hstack  align-center`}>
                      <button
                        type="button"
                        onClick={() => {
                          onReset();
                        }}
                        className={`${styles.btncanclePrf} btn  add-btn`}
                      >
                        Reset
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          onSearch();
                        }}
                        className={` ${styles.btnSearchPrf} btn add-btn ms-1`}
                      >
                        {/* <i className="ri-search-line search-icon align-bottom me-1" /> */}
                        Search
                      </button>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className={`${styles.paginationSection}`}>
                  <div className={` row`}>
                    <div className="col-md-6">
                      <div className={styles.rightSection}>
                        <div className={styles.box1}>Show</div>
                        <div className={styles.selectBox}>
                          <select className="form-select"
                            value={limit}
                            onChange={(e) => {
                              setLimit(e.target.value)
                            }}

                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={40}>40</option>
                          </select>

                        </div>
                        <div className={styles.box2}>
                          Records Per Page
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">

                      {count > 0 &&
                        <div className={` ${styles.customPaginationStyle} customPaginationStyle`}>
                          < Pagination
                            limit={20}
                            totalCount={count}
                            range={5}
                            onPageChange={(page) => {
                              props.setCurrentPage(page)
                            }}
                            currentPage={currentPage}
                          />
                        </div>}
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={`${styles.downloadeSection}`}>
                        <button
                          type="button"
                          className="btn btn-primary add-btn ms-1"
                          onClick={() => onDownloadExcel()}
                        >

                          Download Excel <i className="mdi mdi-download align-bottom me-1" />
                        </button>
                      </div>
                    </div>

                  </div>

                </div>
                <div class="col-md-12">
                  <div className={`${styles.clientSectionLeft} card`}>

                    <div className={`${styles.cardbody} card-body`}>
                      {list && list.map(item => {
                        return (
                          <a className={`${styles.clientSectionCard} clientSection`}>
                            <div class="row">

                              <div class="col-md-6 ">
                                <div className={styles.leftSectionCard}>
                                  <div className={styles.profileAbotContact}>
                                    <h3 className={styles.proName}> {item.FULL_NAME} </h3>
                                    <p className={styles.contactdiv}><i class="ri-phone-fill"></i> {item.PHONE}</p>
                                    <p className={styles.contactdiv}><i class="mdi mdi-email-outline"></i>  {item.EMAIL}</p>
                                    <button className={`${styles.ViewProfiebtn}   ms-2"`} onClick={() => {
                                      setCandidateDetails(item);
                                      setShowCandidateDetailDrawerModal(true);
                                    }}> View Full Profile </button>
                                  </div>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}>Current Designation :  </span><span>{item.CURRENT_DESIGNATION ? item.CURRENT_DESIGNATION : 'Not Available'}</span>
                                  </div>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}>Current Company :</span><span>{item.CURRENT_COMPANY ? item.CURRENT_COMPANY : 'Not Available'}</span>
                                  </div>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}> Skills :</span><span>{item.SKILLS || 'Not Available'}</span>
                                  </div>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}>Preferred Role :</span><span>{item.PREFERRED_ROLE_NAME ? item.PREFERRED_ROLE_NAME : 'Not Available'}</span>
                                  </div>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}> Add Via :</span><span>{item.SOURCE_TYPE ? item.SOURCE_TYPE : 'Not Available'}</span>
                                  </div>



                                </div>
                              </div>
                              <div class="col-md-6 clientSectionRight">

                                <div className={`${styles.infoLocationBox} `}>
                                  {item.WORKING_LOCATION && <h6 className={styles.h6box}> <i class="ri-map-pin-fill" />&nbsp;{item.WORKING_LOCATION}&nbsp;&nbsp;</h6>}
                                  {item.TOTAL_EXP_YEARS && <h6 className={styles.h6box}> <i class="ri-suitcase-fill" />&nbsp;{item.TOTAL_EXP_YEARS}&nbsp;&nbsp;</h6>}
                                  {item.CURRENT_CTC && <h6 className={styles.h6box}> <i className={`bx bx-rupee ${styles.rupeeIcon}`} />&nbsp;{item.CURRENT_CTC}&nbsp;&nbsp;</h6>}

                                </div>

                                <div className={styles.rightSectionInfobx}>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}>{" "}
                                      Notice Period :</span> <span>{item.NOTICE_PERIOD ? item.NOTICE_PERIOD : 'Not Available'}</span>
                                  </div>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}>{" "}
                                      Hiring Status :</span> <span>{item.HIRING_STATUS_NAME ? item.HIRING_STATUS_NAME + ` ( ${item.JOB_DETAIL.COMPANY_NAME} for process ${item.JOB_DETAIL.JOB_TITLE} )` : 'Not Available'}</span>
                                  </div>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}>{" "}
                                      Lead Owner :</span> <span>{item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available'}</span>
                                  </div>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}>{" "}
                                      Added By :</span> <span>{item.CREATED_BY_NAME ? item.CREATED_BY_NAME + ' at ' + moment(item.CREATED_ON).fromNow() : 'Not Available'}</span>
                                  </div>
                                  <div className={styles.divinfobox}>
                                    <span className={styles.hdinfo}> Last Modified :</span> <span>{moment(item.MODIFIED_ON).format('DD-MM-YYYY')}</span>

                                  </div>
                                </div>
                                <div className={styles.clientMenuBtnSection}>
                                  <a href="javascript:void(0)" onClick={() => {
                                    setShowEditModal(item)
                                  }}>
                                    <i class="mdi mdi-account-edit"></i>
                                  </a>
                                  <a
                                    onClick={() => {
                                      setCandidateDetails(item);
                                      setShowCandidateDetailDrawerModal(true);
                                    }}
                                  >
                                    <i class="ri-eye-fill"></i>
                                  </a>
                                  <a href={`tel:${item.PHONE}`}>
                                    <i class="ri-phone-fill"></i>
                                  </a>


                                  <a href={
                                    constant.companyUserComponent.candidateProfilePreviewAndApplyJob.url.replace(':id', item.CANDIDATE_ID).replace(':user_name', item.FULL_NAME)
                                  }>
                                    <i class="ri-home-gear-fill"></i>
                                  </a>
                                  <a href="javascript:void(0)" onClick={() => { setShowAddRemoinderModal(item) }}>
                                    <i class="ri-calendar-2-line" />
                                  </a>

                                  <a href="javascript:void(0)"
                                    onClick={() => {
                                      setUpdateHiringStatus(item)
                                    }}>
                                    <i class="ri-edit-box-line"></i>
                                  </a>
                                </div>

                              </div>

                            </div>
                          </a>
                        )
                      })
                      }

                      {count === 0 && <div className="noresult" >
                        <div className="text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: 75, height: 75 }}
                          />
                          <h5 className="mt-2">Sorry! No Result Found</h5>
                          <p className="text-muted mb-0">
                            We've searched more than 150+ leads We did not find any
                            leads for you search.
                          </p>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default List;
