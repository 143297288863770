import React from 'react'
import constant from '../../../constant';
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './dashboard.module.css';
import { Chart } from "react-google-charts";

export const options = {
  hAxis: {
    title: "Month",
  },
  series: {
    1: { curveType: "function" },
  },
};



const Stats = (props) => {
  const { countStats } = props;
  return (
    <React.Fragment>
        <div className="row">
          <div className="col-md-4 ">
            <div className={`${styles.cardBox} card card-animate overflow-hidden customCardGiridBox`}>

              <div className="card-body" style={{ zIndex: 1 }} onClick={() => { props.history.push(constant.companyUserComponent.listCandidate.url) }}>
                <div className="row">

                  <div className={`${styles.pdr} col-md-7`}>

                    <p className={styles.gridTitleStyle}>
                      {" "}
                      Total Candidates
                    </p>
                    <h4 className={styles.gridCountStyle}>
                      <span className="counter-value" >
                        {countStats?.total_candidates ? countStats?.total_candidates : 0}
                      </span>
                    </h4>
                  </div>
                  <div className="col-md-5">
                    <div className={`${styles.setsize} ${styles.chartscontainer}`}>
                      <div className={`${styles.piewrapper} ${styles.progress30}`}>
                        <span className={styles.label}>30<span className={styles.smaller}>%</span></span>
                        <div className={styles.pie}>
                          <div className={`${styles.leftside} ${styles.halfcircle}`}></div>
                          <div className={`${styles.rightside} ${styles.halfcircle}`}></div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 " onClick={() => { props.history.push(constant.companyUserComponent.postedJobs.url) }}>
            <div className={`${styles.cardBox} card card-animate overflow-hidden customCardGiridBox`}>

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-7">
                    <p className={styles.gridTitleStyle}>
                      {" "}
                      Total Jobs
                    </p>
                    <h4 className={styles.gridCountStyle}>
                      <span className="counter-value" >
                        {countStats?.total_jobs ? countStats?.total_jobs : 0}
                      </span>
                    </h4>

                  </div>
                  <div className="col-md-5">
                    <div className={`${styles.setsize} ${styles.chartscontainer}`}>
                      <div className={`${styles.piewrapper} ${styles.progress45}`}>
                        <span className={styles.label}>48<span className={styles.smaller}>%</span></span>
                        <div className={styles.pie}>
                          <div className={`${styles.leftside} ${styles.halfcircle}`}></div>
                          <div className={`${styles.rightside} ${styles.halfcircle}`}></div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="col-md-3 customDashboardGrid">
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox3BG">

              <div className="card-body" style={{ zIndex: 1 }}
               onClick={() => { props.history.push(constant.companyUserComponent.postedJobs.url) }}
               >
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={4305}>
                        {countStats?.active_jobs ? countStats?.active_jobs : 0}
                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Active Jobs
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-add-circle-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-md-3 customDashboardGrid" 
          onClick={() => { props.history.push(constant.companyUserComponent.shortlisted.url) }}
          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox2BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" >
                        {countStats?.apply_jobs ? countStats?.apply_jobs : 0}
                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      {" "}
                      HR Shortlisted
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-find-replace-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.clientShortlisted.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox5BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={3948}>
                        {countStats?.client_shortlisted ? countStats?.client_shortlisted : 0}
                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Client Shortlisted
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-dv-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.interview.url) }}
          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox4BG">
              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={5021}>
                        {countStats?.interviewed ? countStats?.interviewed : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      {" "}
                      Interview Scheduled
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-folder-user-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.rejected.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox12BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={1340}>
                        {countStats?.rejected ? countStats?.rejected : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Rejected
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-close-line"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.selected.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox6BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" >
                        {countStats?.selected ? countStats?.selected : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Selected
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-check-line"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.offered.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox7BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" data-target={1340}>
                        {countStats?.offered ? countStats?.offered : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Offered
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-hand-heart-fill"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.joined.url) }}

          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox9BG">

              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value" >
                        {countStats?.joined ? countStats?.joined : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Joined
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-medal-fill"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-3 customDashboardGrid"
            onClick={() => { props.history.push(constant.companyUserComponent.dropped.url) }}
          >
            <div className="card card-animate overflow-hidden customCardGiridBox customCardGiridBox10BG">
              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-8">
                    <h4 className="gridCountStyle">
                      <span className="counter-value">
                        {countStats?.dropped ? countStats?.dropped : 0}

                      </span>
                    </h4>
                    <p className="gridTitleStyle">
                      Dropped
                    </p>
                  </div>
                  <div className="col-md-4">
                    <div className="gridIconLeft">
                      <i class="ri-paint-fill"></i>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div> */}
          <div className="col-md-4 "
            onClick={() => { props.history.push(constant.companyUserComponent.billing.url) }}
          >
            <div className={`${styles.cardBox} card card-animate overflow-hidden customCardGiridBox`}>
              <div className="card-body" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-md-7">
                    <p className={styles.gridTitleStyle}>
                      Billing Cycle
                    </p>
                    <h4 className={styles.gridCountStyle}>
                      <span className="counter-value">
                        {countStats?.billing ? countStats?.billing : 0}
                      </span>
                    </h4>

                  </div>
                  <div className="col-md-5">
                    <div className={`${styles.setsize} ${styles.chartscontainer}`}>
                      <div className={`${styles.piewrapper} ${styles.progress75}`}>
                        <span className={styles.label}>67<span className={styles.smaller}>%</span></span>
                        <div className={styles.pie}>
                          <div className={`${styles.leftside} ${styles.halfcircle}`}></div>
                          <div className={`${styles.rightside} ${styles.halfcircle}`}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
       
      

    </React.Fragment>
  )
}

export default withRouter(Stats)