import React, { useEffect, useState } from 'react'
import styles from './style.module.css';
import moment from 'moment';
import Edit from '../editModal'
import styled from 'styled-components';

import BoxRating from '../../../common/BoxRating'
import Modal from '../../../common/htmlModal';
import { v3_available_message_templates, v3_edit_candidate } from '../../../../actions/v3/candidates';
import { ToastContainer, toast } from 'react-toastify';
import PDFEmbed from '../../../common/PDFViewer';

const Detail = ({ label, value }) => {
    return (
        <div className={`${styles.detail} ${styles.detailBox}`}>
            <span className={styles.labeleHead}>{label}:</span> <span className={styles.valueLable}>{value}</span>
        </div>
    );
};

const AccordionContainer = styled.div`
  position: relative;
`;

const Button = styled.button`
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
`;

const AccordionContent = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
`;
const TemplateButton = styled.button`
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover::before {
    content: "${props => props.tooltipText || ''}";
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
  }
`;

const DirectMessageButton = styled.button`
  background-color: #007bff !important;
  color: #fff;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
`;
const CanididateDetailDrawerModal = (props) => {
    const { candidateDetails } = props;
    const [showEditModal, setShowEditModal] = useState(false)
    const [showResumeViewModal, setShowResumeViewModal] = useState(null)
    const [templateList, setTemplateList] = useState([])

    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        v3_available_message_templates().then((res) => {
            if (res.status) {
                setTemplateList(res.result)
            }
        })
    }, [])

    const onEdit = (model) => {
        v3_edit_candidate(model).then((res) => {
            if (res.status) {
                setShowEditModal(false)
                toast.success('Candidate Lead Saved')
            }
            else {
                toast.error(res.error)
            }
        })
    }


    return (
        <div className={styles.profilViewModalSection}>
            <ToastContainer />
            <div className="popupCandidateFormStyle">
                <Modal isOpen={showEditModal} header={'Edit Candidate Lead Details'}
                onClose={() => { setShowEditModal(null) }}
                >
                    <Edit
                        data={candidateDetails}
                        onClose={() => { setShowEditModal(false) }}
                        onSubmit={(model) => { onEdit(model) }}
                    />
                </Modal>
                <Modal isOpen={showResumeViewModal} onClose={() => { setShowResumeViewModal(null) }}>
                    {showResumeViewModal && < PDFEmbed
                        file={`${process.env.REACT_APP_BASE_URL}/v3/candidate-resume/${showResumeViewModal}`}
                    />}
                </Modal>

                {
                    <React.Fragment>
                        <div className={styles.modalCandidateBox}>
                            <div className={styles.candidateSideopenStyle}>
                                <h3>Candidate Details </h3>
                                
                            </div>
                            <div className={styles.whatppBox}>
                            <Detail  className={styles.labelNotify} label="Notify Candidate" value={
                                    <AccordionContainer>
                                        <Button className={` ${styles.WhatappButton} mt-2 `} onClick={() => toggleAccordion(!isOpen)}> <i className='mdi mdi-whatsapp' /> WhatsApp</Button>
                                        <Button className={` ${styles.EmailButton} mt-2 `} onClick={() => toggleAccordion(!isOpen)}> <i className='mdi mdi-email' /> Mail</Button>
                                        {isOpen && (
                                            <AccordionContent>
                                                <DirectMessageButton className="loginSubmitBtn" onClick={() => { window.open(`https://api.whatsapp.com/send/?phone=91${candidateDetails.PHONE}&text&type=phone_number&app_absent=0`, '_blank') }}>Direct message</DirectMessageButton>
                                                {templateList && templateList.length > 0 && <h4 style={{textAlign: 'center', margin: '10px 0px 20px', fontSize: 18,}}> Use Template</h4>}
                                                {templateList && templateList.map((item, index) => (
                                                    <TemplateButton key={index} className="loginSubmitBtn" tooltipText={item.TEMPLATE_BODY} onClick={() => { window.open(`https://api.whatsapp.com/send/?phone=91${candidateDetails.PHONE}&text=${item.TEMPLATE_BODY}&type=phone_number&app_absent=0`, '_blank') }}>
                                                        {item.NAME}
                                                    </TemplateButton>
                                                ))}
                                            </AccordionContent>
                                        )}
                                    </AccordionContainer>
                                } />
                              </div>  
                        </div>
                    </React.Fragment>
                }
                <div className={`${styles.detailscontainer} ${styles.detailscontainerBox}`}>

                    <Detail label="Candidate ID" value={'RJZSHCL' + candidateDetails.CANDIDATE_ID} />
                    <Detail label="Full Name" value={candidateDetails.FULL_NAME} />
                    <Detail label="Email" value={candidateDetails.EMAIL} />
                    <Detail label="Phone" value={candidateDetails.PHONE} />
                    <Detail label="Work Location" value={candidateDetails.WORKING_LOCATION} />
                    <Detail label="Residential Location" value={candidateDetails.RESIDENTIAL_LOCATION} />
                    <Detail label="Current Company" value={candidateDetails.CURRENT_COMPANY} />
                    <Detail label="Current CTC" value={candidateDetails.CURRENT_CTC} />
                    <Detail label="Expected CTC" value={candidateDetails.EXPECTED_CTC} />
                    <Detail label="Skills" value={candidateDetails.SKILLS} />
                    <Detail label="Total Experience (Years)" value={candidateDetails.TOTAL_EXP_YEARS} />
                    {
                        candidateDetails.TOTAL_EXP_YEARS === 'Fresher' &&
                        <React.Fragment>
                            <Detail label="College Name" value={candidateDetails.COLLEGE_NAME} />
                            <Detail label="Pass out year" value={candidateDetails.PASS_OUT_YEAR} />
                        </React.Fragment>
                    }
                    <Detail label="Highest Qualification" value={candidateDetails.HIGHEST_QUALIFICATION} />
                    <Detail label="Highest Education Qualification" value={candidateDetails.HIGHEST_EDUCATION_QUALIFICATION} />
                    <Detail label="Course Specialization" value={candidateDetails.COURSE_SPECIALIZATION} />
                    <Detail label="Lead Added On" value={moment(candidateDetails.CREATED_ON).format('DD MMM YYYY')} />
                    <Detail label="Resume" value={<a href='javascript:void(0)' onClick={() => { setShowResumeViewModal(candidateDetails.RESUME_FILE_NAME) }}>{candidateDetails.RESUME_FILE_NAME}</a>} />
                    <Detail label="Notice Period" value={candidateDetails.NOTICE_PERIOD} />
                    <Detail label="Source" value={candidateDetails.SOURCE} />
                    {
                        candidateDetails.STATUS_NAME && <Detail label="Current Status" value={candidateDetails.STATUS_NAME ? candidateDetails.STATUS_NAME + (candidateDetails.CLIENT_NAME ? ' at ' + candidateDetails.CLIENT_NAME : '') : 'Not Available'} />
                    }
                    <Detail label="Lead Owner" value={candidateDetails.LEAD_OWNER_NAME} />
                    <Detail label="Added By" value={candidateDetails.CREATED_BY_NAME ? candidateDetails.CREATED_BY_NAME + ' at ' + moment(candidateDetails.CREATED_ON).fromNow() : 'Not Available'} />
                    <Detail label="Last Modified" value={moment(candidateDetails.MODIFIED_ON).format('DD-MM-YYYY')} />




                    <Detail label="Rating" value={candidateDetails.RATING
                        // <BoxRating
                        //     onChange={() => console.log('not allowed')}
                        //     value={candidateDetails.RATING}
                        // />
                    } />
                    {props.showEditOption && <Detail label="Edit" value={
                        <a
                            onClick={() => { setShowEditModal(true) }}
                            href='javascript:void(0);'
                            className={`${styles.profileEditvalue}  d-inline-block`}
                        >
                            <i className="mdi mdi-account-edit fs-16" /> Edit
                        </a>
                    } />}

<Detail label="Note" value={candidateDetails.NOTE} />

                </div>
                

            </div>
        </div >

    )
}

export default CanididateDetailDrawerModal