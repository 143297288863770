import React from 'react';
import styles from './dashboard.module.css';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import constant from '../../../../constant';

const Overview = (props) => {
    const { countStats } = props;

    // Extract relevant stats for progress calculation
    const relevantStats = {
        active_jobs: countStats?.active_jobs || 0,
        apply_jobs: countStats?.apply_jobs || 0,
        client_shortlisted: countStats?.client_shortlisted || 0,
        interviewed: countStats?.interviewed || 0,
        rejected: countStats?.rejected || 0,
        selected: countStats?.selected || 0,
        offered: countStats?.offered || 0,
        joined: countStats?.joined || 0,
        dropped: countStats?.dropped || 0,
    };

    // Calculate total and percentages
    const total = Object.values(relevantStats).reduce((sum, value) => sum + value, 0);

    const progressData = Object.keys(relevantStats).map((key, index) => ({
        key,
        percentage: total ? (relevantStats[key] / total) * 100 : 0,
        value: relevantStats[key],
        colorClass: getColorClass(index),
        bgClass: getBgClass(index),
    }));

    return (
        <React.Fragment>
            <div className="col-md-5">
                <div className={styles.applicationStatisticsBox}>
                    <div className={styles.applicationTitle}>
                        <h4>Overview</h4>
                    </div>
                    <div className={styles.progressMainBox}>
                        <div className={`${styles.progressBox} progress`}>
                            {progressData.map((item) => (
                                <div
                                    key={item.key}
                                    className={`${styles.progressBar} ${item.bgClass}`}
                                    style={{ width: `${item.percentage}%` }}
                                ></div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.overviewListSection}>
                        {progressData.map((item) => (
                            <div
                                key={item.key}
                                className={styles.overviewListItem}
                                onClick={() => handleNavigation(item.key, props.history)}
                            >
                                <div className={styles.overinfo}>
                                    <div className={`${styles.colorbox} ${item.bgClass}`}>
                                        <span></span>
                                    </div>
                                    <div className={styles.overname}>{getDisplayName(item.key)}</div>
                                </div>
                                <div className={`${styles.countbox} ${item.colorClass}`}>
                                    {item.value}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

    // Helper functions
    function handleNavigation(key, history) {
        const routes = {
            active_jobs: constant.companyUserComponent.postedJobs.url,
            apply_jobs: constant.companyUserComponent.team_shortlisted.url,
            client_shortlisted: constant.companyUserComponent.team_client_shortlisted.url,
            interviewed: constant.companyUserComponent.team_interview.url,
            rejected: constant.companyUserComponent.team_rejected.url,
            selected: constant.companyUserComponent.team_selected.url,
            offered: constant.companyUserComponent.team_offered.url,
            joined: constant.companyUserComponent.team_joined.url,
            dropped: constant.companyUserComponent.team_dropped.url,
        };
        history.push(routes[key]);
    }

    function getDisplayName(key) {
        const names = {
            active_jobs: 'Active Jobs',
            apply_jobs: 'HR Shortlisted',
            client_shortlisted: 'Client Shortlisted',
            interviewed: 'Interview Scheduled',
            rejected: 'Rejected',
            selected: 'Selected',
            offered: 'Offered',
            joined: 'Joined',
            dropped: 'Dropped',
        };
        return names[key] || key;
    }

    function getColorClass(index) {
        const colorClasses = [
            styles.color1,
            styles.color2,
            styles.color3,
            styles.color4,
            styles.color5,
            styles.color6,
            styles.color7,
            styles.color8,
            styles.color9,
        ];
        return colorClasses[index % colorClasses.length];
    }

    function getBgClass(index) {
        const bgClasses = [
            styles.Bgcolor1,
            styles.Bgcolor2,
            styles.Bgcolor3,
            styles.Bgcolor4,
            styles.Bgcolor5,
            styles.Bgcolor6,
            styles.Bgcolor7,
            styles.Bgcolor8,
            styles.Bgcolor9,
        ];
        return bgClasses[index % bgClasses.length];
    }
};

export default withRouter(Overview);
    