import React, { useEffect, useState } from 'react'
import { v1_employment_type } from '../../../../actions/v1/master_data';
import { validateFunctionalForm } from '../../../../utils';
import styles from './step.module.css';

const StepTwo = (props) => {

    const [state, setState] = useState({
        JOB_TITLE: { name: 'JOB_TITLE', value: props.formData ? props.formData.JOB_TITLE : '', error: '', isRequired: true },
        EMPLOYMENT_TYPE: { name: 'EMPLOYMENT_TYPE', value: props.formData ? props.formData.EMPLOYMENT_TYPE : '', options: [], error: '', isRequired: true },
        WORK_EXP_MIN: { name: 'WORK_EXP_MIN', value: props.formData ? props.formData.WORK_EXP_MIN : '', error: '', isRequired: true },
        WORK_EXP_MAX: { name: 'WORK_EXP_MAX', value: props.formData ? props.formData.WORK_EXP_MAX : '', error: '', isRequired: true },
        SALARY_CURRENCY: { name: 'SALARY_CURRENCY', value: props.formData ? props.formData.SALARY_CURRENCY : 'Rs', error: '', isRequired: true },
        SALARY_MIN: { name: 'SALARY_MIN', value: props.formData ? props.formData.SALARY_MIN : '', error: '', isRequired: true },
        SALARY_MAX: { name: 'SALARY_MAX', value: props.formData ? props.formData.SALARY_MAX : '', error: '', isRequired: true },
        JOB_DETAILS: { name: 'JOB_DETAILS', value: props.formData ? props.formData.JOB_DETAILS : '', error: '', isRequired: true },
        JOB_QUESTIONS: { name: 'JOB_QUESTIONS', value: props.formData ? props.formData.JOB_QUESTIONS : [], error: '', isRequired: false },
        INCLUDE_WALK_IN_DETAILS: { name: 'INCLUDE_WALK_IN_DETAILS', value: props.formData ? props.formData.INCLUDE_WALK_IN_DETAILS : 'N', error: '', isRequired: true },
        WALK_IN_START_DATE: { name: 'WALK_IN_START_DATE', value: props.formData ? props.formData.WALK_IN_START_DATE : '', error: '', isRequired: false },
        WALK_IN_START_TIME: { name: 'WALK_IN_START_TIME', value: props.formData ? props.formData.WALK_IN_START_TIME : '', error: '', isRequired: false },
        WALK_IN_END_TIME: { name: 'WALK_IN_END_TIME', value: props.formData ? props.formData.WALK_IN_END_TIME : '', error: '', isRequired: false },
        WALK_IN_CONTACT_PERSON: { name: 'WALK_IN_CONTACT_PERSON', value: props.formData ? props.formData.WALK_IN_CONTACT_PERSON : '', error: '', isRequired: false },
        WALK_IN_CONTACT_NUMBER: { name: 'WALK_IN_CONTACT_NUMBER', value: props.formData ? props.formData.WALK_IN_CONTACT_NUMBER : '', error: '', isRequired: false },
        WALK_IN_VENUE: { name: 'WALK_IN_VENUE', value: props.formData ? props.formData.WALK_IN_VENUE : '', error: '', isRequired: false },
        GOOGLE_MAP_URL: { name: 'GOOGLE_MAP_URL', value: props.formData ? props.formData.GOOGLE_MAP_URL : '', error: '', isRequired: false },
        WALK_IN_DURATION: { name: 'WALK_IN_DURATION', value: props.formData ? props.formData.WALK_IN_DURATION : '', error: '', isRequired: false },


    })
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }
    const YearOptions = (hideFresher) => {
        const options = [<option key={0} value={'00'}>Fresher</option>];

        for (let i = 0; i <= 32; i++) {
            options.push(<option key={i + 1} value={i}>{`${i} Year${i > 1 ? 's' : ''}`}</option>);
        }

        return options;
    };

    useEffect(() => {
        if (state.INCLUDE_WALK_IN_DETAILS.value === 'Y') {
            setState(prevState => ({
                ...prevState,
                ['WALK_IN_START_DATE']: {
                    ...prevState['WALK_IN_START_DATE'],
                    isRequired: true
                },
                ['WALK_IN_START_TIME']: {
                    ...prevState['WALK_IN_START_TIME'],
                    isRequired: true
                },
                ['WALK_IN_END_TIME']: {
                    ...prevState['WALK_IN_END_TIME'],
                    isRequired: true
                },
                ['WALK_IN_CONTACT_PERSON']: {
                    ...prevState['WALK_IN_CONTACT_PERSON'],
                    isRequired: true
                },
                ['WALK_IN_CONTACT_NUMBER']: {
                    ...prevState['WALK_IN_CONTACT_NUMBER'],
                    isRequired: true
                },
                ['WALK_IN_VENUE']: {
                    ...prevState['WALK_IN_VENUE'],
                    isRequired: true
                },
                ['GOOGLE_MAP_URL']: {
                    ...prevState['GOOGLE_MAP_URL'],
                    isRequired: true
                },
                ['WALK_IN_DURATION']: {
                    ...prevState['WALK_IN_DURATION'],
                    isRequired: true
                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['WALK_IN_START_DATE']: {
                    ...prevState['WALK_IN_START_DATE'],
                    isRequired: false,
                    value: ''
                },
                ['WALK_IN_START_TIME']: {
                    ...prevState['WALK_IN_START_TIME'],
                    isRequired: false,
                    value: ''
                },
                ['WALK_IN_END_TIME']: {
                    ...prevState['WALK_IN_END_TIME'],
                    isRequired: false,
                    value: ''
                },
                ['WALK_IN_CONTACT_PERSON']: {
                    ...prevState['WALK_IN_CONTACT_PERSON'],
                    isRequired: false,
                    value: ''
                },
                ['WALK_IN_CONTACT_NUMBER']: {
                    ...prevState['WALK_IN_CONTACT_NUMBER'],
                    isRequired: false,
                    value: ''
                },
                ['WALK_IN_VENUE']: {
                    ...prevState['WALK_IN_VENUE'],
                    isRequired: false,
                    value: ''
                },
                ['GOOGLE_MAP_URL']: {
                    ...prevState['GOOGLE_MAP_URL'],
                    isRequired: false,
                    value: ''
                },
                ['WALK_IN_DURATION']: {
                    ...prevState['WALK_IN_DURATION'],
                    isRequired: false,
                    value: ''
                }
            }));

        }
    }, [state.INCLUDE_WALK_IN_DETAILS.value])


    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    const onSubmit = () => {
        debugger
        if (validateFunctionalForm(state, setState)) {
            const model = {
                JOB_TITLE: state.JOB_TITLE.value,
                EMPLOYMENT_TYPE: state.EMPLOYMENT_TYPE.value,
                WORK_EXP_MIN: state.WORK_EXP_MIN.value,
                WORK_EXP_MAX: state.WORK_EXP_MAX.value,
                SALARY_CURRENCY: state.SALARY_CURRENCY.value,
                SALARY_MIN: state.SALARY_MIN.value,
                SALARY_MAX: state.SALARY_MAX.value,
                JOB_DETAILS: state.JOB_DETAILS.value,
                JOB_QUESTIONS: state.JOB_QUESTIONS.value,
                INCLUDE_WALK_IN_DETAILS: state.INCLUDE_WALK_IN_DETAILS.value,
                WALK_IN_START_DATE: state.WALK_IN_START_DATE.value,
                WALK_IN_START_TIME: state.WALK_IN_START_TIME.value,
                WALK_IN_END_TIME: state.WALK_IN_END_TIME.value,
                WALK_IN_CONTACT_PERSON: state.WALK_IN_CONTACT_PERSON.value,
                WALK_IN_CONTACT_NUMBER: state.WALK_IN_CONTACT_NUMBER.value,
                WALK_IN_VENUE: state.WALK_IN_VENUE.value,
                GOOGLE_MAP_URL: state.GOOGLE_MAP_URL.value,
                WALK_IN_DURATION: state.WALK_IN_DURATION.value,
            }
            props.onSave(model)
        }
    }

    useState(() => {
        v1_employment_type().then((res) => {
            if (res.status) {
                setOptions(state.EMPLOYMENT_TYPE.name, res.result)
            }
            else {
                console.error(res.error)
            }
        })

    }, [])

    return (
        <React.Fragment>
            <div className="tab-content">
                <div
                    className="tab-pane fade active show"
                    id="pills-gen-info"
                    role="tabpanel"
                    aria-labelledby="pills-gen-info-tab"
                >
                    <div>
                        <div className="mb-4">
                            <div className={styles.pageDetailsBox}>
                                <h5 className="mb-1">Job Details</h5>
                                <p className="text-muted">Fill all Information as below</p>
                            </div>
                        </div>
                        <div className={styles.formsSectionBox}> 
                        <div className={` ${styles.postNewJob} row`}>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label
                                        className="form-label"
                                    >
                                        Job Title / Designation
                                        <span className='text-danger'>&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.JOB_TITLE.error && !state.JOB_TITLE.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Job Title or Designation"
                                        name={state.JOB_TITLE.name}
                                        value={state.JOB_TITLE.value}
                                        onChange={onChange}
                                    />
                                    <div className="invalid-feedback">
                                        Please enter a job title
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label
                                        className="form-label"
                                    >
                                        Employment Type
                                        <span className='text-danger'>&nbsp;*</span>

                                    </label>
                                    <select
                                        className={state.EMPLOYMENT_TYPE.error && !state.EMPLOYMENT_TYPE.value ? "form-select is-invalid" : "form-select"}
                                        name={state.EMPLOYMENT_TYPE.name}
                                        value={state.EMPLOYMENT_TYPE.value}
                                        onChange={onChange}
                                    >
                                        <option value=''>Select Employment Type</option>
                                        {state.EMPLOYMENT_TYPE.options.map((item) => {
                                            return (
                                                <option value={item.EMPLOYMENT_TYPE_ID}>{item.EMPLOYMENT_TYPE}</option>
                                            )
                                        })}

                                    </select>
                                    <div className="invalid-feedback">
                                        Please select employment type
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label className="form-label">Work Experience (Year)

                                        <span className='text-danger'>&nbsp;*</span>

                                    </label>
                                    <div className="row">
                                        <div className="col-6">
                                            <select
                                                className={state.WORK_EXP_MIN.error && !state.WORK_EXP_MIN.value ? "form-select is-invalid" : "form-select"}
                                                name='WORK_EXP_MIN'
                                                value={state.WORK_EXP_MIN.value}
                                                onChange={onChange}
                                            >
                                                <option value=''>Select Minimum Experience</option>
                                                <YearOptions />

                                            </select>
                                            <div className="invalid-feedback">
                                                Please enter work experience
                                            </div>
                                        </div>
                                        {
                                        (
                                                <div className="col-6">
                                                    <select
                                                        className={state.WORK_EXP_MAX.error && !state.WORK_EXP_MAX.value ? "form-select is-invalid" : "form-select"}
                                                        name='WORK_EXP_MAX'
                                                        value={state.WORK_EXP_MAX.value}
                                                        onChange={onChange}
                                                    >
                                                        <option value=''>Select Maximum Experience</option>
                                                        {/* <YearOptions /> */}
                                                        {
                                                            [...Array(33).keys()].map(i => (
                                                                <option key={i} value={i}>{`${i} Year${i !== 1 ? 's' : ''}`}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        Please enter work experience
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label className="form-label">Salary Range (Lakh)
                                        <span className='text-danger'>&nbsp;*</span>

                                    </label>
                                    <div className="row">
                                        <div className="col-2">
                                            <select
                                                className={state.SALARY_CURRENCY.error && !state.SALARY_CURRENCY.value ? "form-control is-invalid" : "form-control"}
                                                name='SALARY_CURRENCY'
                                                value={state.SALARY_CURRENCY.value}
                                                onChange={onChange}
                                            >
                                                <option value="Rs">Rs</option>
                                                <option value="$">$</option>
                                            </select>

                                        </div>
                                        <div className="col-5">
                                            <input
                                                type='number'
                                                min={0}

                                                className={state.SALARY_MIN.error && !state.SALARY_MIN.value ? "form-control is-invalid" : "form-control"}
                                                placeholder='Min'
                                                name='SALARY_MIN'
                                                value={state.SALARY_MIN.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please enter salary range
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <input
                                                type='number'
                                                min={0}

                                                className={state.SALARY_MAX.error && !state.SALARY_MAX.value ? "form-control is-invalid" : "form-control"}
                                                placeholder='Max'
                                                name='SALARY_MAX'
                                                value={state.SALARY_MAX.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please enter salary range
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label
                                        className="form-label"
                                    > Job Detail

                                        <span className='text-danger'>&nbsp;*</span>

                                    </label>
                                    <textarea
                                        className={state.JOB_DETAILS.error && !state.JOB_DETAILS.value ? "form-control is-invalid" : "form-control"}
                                        placeholder='Enter Job Details'
                                        rows={5}
                                        name={state.JOB_DETAILS.name}
                                        value={state.JOB_DETAILS.value}
                                        onChange={onChange}

                                    />
                                </div>

                            </div>
                            <div className="col-lg-12">
                              

                                {state.JOB_QUESTIONS.value.length > 0 && (
                                    <div className="mb-3">
                                        <label className="form-label">Questions</label>
                                        {state.JOB_QUESTIONS.value.map((item, index) => (
                                            <div className="row mb-3" key={index}> {/* Added key prop for better performance */}
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder='Enter Job Question'
                                                        name={`JOB_QUESTIONS[${index}].QUESTION`} // Added name attribute with proper indexing
                                                        value={item.QUESTION} // Assuming QUESTION property exists in each question object
                                                        onChange={(event) => {
                                                            setState(prevState => ({
                                                                ...prevState,
                                                                ["JOB_QUESTIONS"]: {
                                                                    ...prevState["JOB_QUESTIONS"],
                                                                    value: prevState["JOB_QUESTIONS"].value.map((q, i) =>
                                                                        i === index ? { ...q, QUESTION: event.target.value } : q
                                                                    ),
                                                                },
                                                            }));
                                                        }}
                                                    />
                                                    <div className="invalid-feedback">Please enter job question</div>
                                                </div>
                                                <div className="col-auto">
                                                    <button
                                                        type="button"
                                                        className={`${styles.btnRemove} btn btn-danger left`}
                                                        onClick={() => {
                                                            const updatedQuestions = state.JOB_QUESTIONS.value.filter(
                                                                (_, i) => i !== index
                                                            );
                                                            setState(prevState => ({
                                                                ...prevState,
                                                                ["JOB_QUESTIONS"]: {
                                                                    ...prevState["JOB_QUESTIONS"],
                                                                    value: updatedQuestions,
                                                                },
                                                            }));
                                                        }}
                                                    >
                                                        <i className="ri-delete-bin-line label-icon align-middle " />
                                                       
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <button
                                    type="button"
                                    className={`${styles.addJobQustionbtn} btn  btn-label left`}
                                    onClick={() => {
                                        setState(prevState => ({
                                            ...prevState,
                                            ['JOB_QUESTIONS']: {
                                                ...prevState['JOB_QUESTIONS'],
                                                value: [...state.JOB_QUESTIONS.value, { QUESTION: '' }]
                                            }
                                        }));
                                    }}
                                >
                                    <i className="ri-add-line align-middle fs-16 mr-5" />

                                    Add Question
                                </button>

                            </div>

                            <div className="col-lg-12">
                                

                                <div className="mb-3 mt-3">
                                    <label className="form-label">
                                        <input
                                            type='checkbox'
                                            checked={state.INCLUDE_WALK_IN_DETAILS.value === 'Y'}
                                            onClick={() => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    ['INCLUDE_WALK_IN_DETAILS']: {
                                                        ...prevState['INCLUDE_WALK_IN_DETAILS'],
                                                        value: state.INCLUDE_WALK_IN_DETAILS.value === 'Y' ? 'N' : 'Y'
                                                    }
                                                }));
                                            }}

                                        />
                                        &nbsp;
                                        include walk-in details</label>
                                </div>
                            </div>


                            {state.INCLUDE_WALK_IN_DETAILS.value === 'Y' &&
                                <React.Fragment>
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label className="form-label">Walk-In Start Date</label>
                                            <input
                                                type='date'
                                                className={state.WALK_IN_START_DATE.error && !state.WALK_IN_START_DATE.value ? "form-control is-invalid" : "form-control"}
                                                name='WALK_IN_START_DATE'
                                                value={state.WALK_IN_START_DATE.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please select start date
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label className="form-label">Walk-In Start Timing</label>
                                            <input
                                                type='time'
                                                className={state.WALK_IN_START_TIME.error && !state.WALK_IN_START_TIME.value ? "form-control is-invalid" : "form-control"}
                                                name='WALK_IN_START_TIME'
                                                value={state.WALK_IN_START_TIME.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please select start time
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label className="form-label">Walk-In End Time</label>
                                            <input
                                                type='time'
                                                className={state.WALK_IN_END_TIME.error && !state.WALK_IN_END_TIME.value ? "form-control is-invalid" : "form-control"}
                                                name='WALK_IN_END_TIME'
                                                value={state.WALK_IN_END_TIME.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please select end time
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label className="form-label">Duration (Days)</label>
                                            <input
                                                type='number'
                                                min={0}

                                                className={state.WALK_IN_DURATION.error && !state.WALK_IN_DURATION.value ? "form-control is-invalid" : "form-control"}
                                                name='WALK_IN_DURATION'
                                                value={state.WALK_IN_DURATION.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please select start date
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label className="form-label">Contact Person</label>
                                            <input
                                                type='text'
                                                className={state.WALK_IN_CONTACT_PERSON.error && !state.WALK_IN_CONTACT_PERSON.value ? "form-control is-invalid" : "form-control"}
                                                name='WALK_IN_CONTACT_PERSON'
                                                value={state.WALK_IN_CONTACT_PERSON.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please select start date
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="mb-3">
                                            <label className="form-label">Contact Number</label>
                                            <input
                                                type='number'
                                                min={0}

                                                className={state.WALK_IN_CONTACT_NUMBER.error && !state.WALK_IN_CONTACT_NUMBER.value ? "form-control is-invalid" : "form-control"}
                                                name='WALK_IN_CONTACT_NUMBER'
                                                value={state.WALK_IN_CONTACT_NUMBER.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please select start date
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Venue</label>
                                            <input
                                                type='text'
                                                className={state.WALK_IN_VENUE.error && !state.WALK_IN_VENUE.value ? "form-control is-invalid" : "form-control"}
                                                name='WALK_IN_VENUE'
                                                value={state.WALK_IN_VENUE.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please select start date
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label className="form-label">Google Map URL</label>
                                            <input
                                                type='text'
                                                className={state.GOOGLE_MAP_URL.error && !state.GOOGLE_MAP_URL.value ? "form-control is-invalid" : "form-control"}
                                                name='GOOGLE_MAP_URL'
                                                value={state.GOOGLE_MAP_URL.value}
                                                onChange={onChange}
                                            />
                                            <div className="invalid-feedback">
                                                Please select start date
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>

                            }
                        </div>

                        </div>

                    </div>
                    <div className="d-flex align-items-end justify-content-end gap-3 mt-4">
                        <button
                            type="button"
                            onClick={() => { props.onPrevious() }}
                           className={`${styles.cancelBtn} btn btn-primary btn-label left`}
                        >
                            {/* <i className="ri-arrow-left-line label-icon align-middle fs-16" /> */}

                            Previous
                        </button>

                        <button
                            type="button"
                            className={` ${styles.buttonNext} btn  nexttab nexttab`}
                            onClick={() => { onSubmit() }}
                        >
                            {/* <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" /> */}
                            Save and Next
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default StepTwo