import React, { useState } from 'react'
import moment from 'moment'
import constant from '../../../../constant'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getStorage } from '../../../../utils'
import styles from './jobList.module.css'
import BreadCrumb from '../../../../components/common/BreadCrumb'
const NonPosted = (props) => {
    const isAdministrator = getStorage(constant.keys.companyUserHavingAdminRights);
    const { searchKeyword, setSearchKeyword } = props


    return (
        <React.Fragment>
           
                    <div className="container-fluid">
                        <BreadCrumb
                            page={props.Title}
                            parentSection={
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <a href="javascript: void(0);">Jobs</a>
                                    </li>
                                    <li className="breadcrumb-item active">{props.Title}</li>
                                </ol>
                            }
                        />

                        <div className="row">
                            <div className="col-lg-12">
                                <div className={`${styles.filterCardBox} card`}>
                                    <div className="card-body">
                                        <form>
                                            <div className="row g-3">
                                                <div className="col-xxl-4 col-sm-4">

                                                    <div className="search-box">
                                                        <label className="form-label">
                                                            &nbsp;

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control search "
                                                            placeholder="Search for jobs or companies..."
                                                            value={searchKeyword}
                                                            onChange={(e) => { setSearchKeyword(e.target.value) }}
                                                        />
                                                        <i className={`ri-search-line search-icon ${styles.secrchIcon}`} />
                                                    </div>
                                                </div>
                                                {/* <div className="col-xxl-2 col-sm-2">
                                                    <div className="date-box">
                                                        <label className="form-label">
                                                            Select Date

                                                        </label>
                                                        <input
                                                            type="date"
                                                            className="form-control search bg-light border-light"
                                                            placeholder="Select Date"
                                                        />

                                                    </div>
                                                </div> */}
                                                {/* <div className="col-xxl-2 col-sm-2">
                                                    <div className="date-box">
                                                        <label className="form-label">
                                                            Job Type

                                                        </label>
                                                        <select type="text" className='form-select '>
                                                            <option value="0">Select Job Type</option>
                                                            <option value="1">Full Time</option>
                                                            <option value="2">Part Time</option>

                                                        </select>

                                                    </div>
                                                </div> */}
                                                {/* <div className="col-xxl-2 col-sm-2">
                                                    <div className="date-box">
                                                        <label className="form-label">
                                                            Status

                                                        </label>
                                                        <select type="text" className='form-select '>
                                                            <option value="0">Select Status</option>
                                                            <option value="1">Active</option>
                                                            <option value="2">Close</option>

                                                        </select>


                                                    </div>
                                                </div> */}
                                                {/* <div className="col-xxl-2 col-sm-2">
                                                    <label className="form-label">&nbsp;&nbsp;</label>
                                                    <button
                                                        type="button"
                                                        className={`${styles.btnprimaryfilter} btn  `}
                                                        onClick={() => { onSearch() }}
                                                    >
                                                        <i className="ri-equalizer-fill align-bottom" />{" "}
                                                        Filters
                                                    </button>
                                                </div> */}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                        </div>
                        <div className="row" id="job-list">
                            <div className="col-12">
                                <div className="list-group">
                                    {props.jobList && props.jobList.length > 0 ? props.jobList.map((item) => {
                                        return (<div
                                            className={` ${props.CLOSE_JOB_BUTTON ? styles.listLiveJobs : styles.listExpiredJobs} list-group-item list-group-item-action`}
                                        >
                                            {props.CLOSE_JOB_BUTTON && <div className="float-end">
                                                <p className={styles.editBtnRight}> {isAdministrator &&
                                                    <a href={constant.companyUserComponent.updateJob.url.replace(':step', item.COMPLETED_STEPS).replace(':job_id', item.JOB_ID)}>
                                                        <span ><i class="mdi mdi-pencil" /> </span>
                                                    </a>
                                                }</p>
                                                <div className={`${styles.alertJobcolose}`} onClick={() => { props.updateJobVisibility(item) }}>
                                                    Close Job
                                                </div>
                                                <p className={styles.postedDate}>Posted  <strong>{moment(item.MODIFIED_ON).fromNow()}</strong></p>
                                            </div>}

                                            {props.LIVE_JOB_BUTTON &&
                                                <div className="float-end">
                                                    <p className={styles.editBtnRight}> {isAdministrator &&
                                                        <a href={constant.companyUserComponent.updateJob.url.replace(':step', item.COMPLETED_STEPS).replace(':job_id', item.JOB_ID)}>
                                                            <span ><i class="mdi mdi-pencil" /> </span>
                                                        </a>
                                                    }</p>
                                                    <div className={`${styles.alertJobLive}`} onClick={() => { props.updateJobVisibility(item) }}>
                                                        Live Job
                                                    </div>
                                                    <p className={styles.postedDate}>Posted  <strong>{moment(item.MODIFIED_ON).fromNow()}</strong></p>
                                                </div>
                                            }

                                            <div className="d-flex mb-2 align-items-center"

                                            >

                                                <div className="flex-grow-1 ms-3">
                                                    <div className={styles.companyMainbox} >
                                                        <div className={styles.companyLogobox}>
                                                            <img
                                                                src={item.LOGO ? `${process.env.REACT_APP_BASE_URL}/v3/client-logo/${item.LOGO}` : '/assets/images/comapnylogo.png'}
                                                            />
                                                        </div>
                                                        <div className={styles.aboutCompanyname}>
                                                            <h5 className={styles.listTitle}>
                                                                <Link
                                                                    to={constant.companyUserComponent.jobApplications.url.replace(':job_id', item.JOB_ID).replace(':page_number', 1)}
                                                                >
                                                                    {item.JOB_TITLE}
                                                                </Link>


                                                            </h5>

                                                            <h6 className={styles.listSubTitle}>{item.COMPANY_NAME}</h6>
                                                        </div>
                                                    </div>

                                                    <p className={styles.paraExInfo}>Work Experience : <strong>{item.WORK_EXP_MIN + ' Year - ' + item.WORK_EXP_MAX + ' Year'}</strong> , Salary Range : <strong>{item.SALARY_MIN + ' Lakh - ' + item.SALARY_MAX + ' Lakh'}</strong></p>
                                                    {/* <p className="list-text mb-3 fs-12"></p> */}
                                                </div>
                                            </div>

                                        </div>)
                                    }) :
                                        <div className="list-group-item noresult" >
                                            <div className="text-center">
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/msoeawqm.json"
                                                    trigger="loop"
                                                    colors="primary:#121331,secondary:#08a88a"
                                                    style={{ width: 75, height: 75 }}
                                                />
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">
                                                    We've searched more than 150+ leads We did not find any
                                                    leads for you search.
                                                </p>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>


                        </div>
                        {/* <div className="row g-0 justify-content-end mb-4" id="pagination-element">
                            <div className="col-sm-6">
                                <div className="pagination-block pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                                    <div className="page-item">
                                        <a
                                            href="javascript:void(0);"
                                            className="page-link"
                                            id="page-prev"
                                        >
                                            Previous
                                        </a>
                                    </div>
                                    <span id="page-num" className="pagination" />
                                    <div className="page-item">
                                        <a
                                            href="javascript:void(0);"
                                            className="page-link"
                                            id="page-next"
                                        >
                                            Next
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                

        </React.Fragment>
    )
}

export default NonPosted