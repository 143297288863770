import React, { useState } from 'react'
import { validateFunctionalForm } from '../../../../utils';
import { v3_client_options } from '../../../../actions/v3/jobApplication';
import styles from './step.module.css';

const StepOne = (props) => {
    const [state, setState] = useState({
        COMPANY_NAME: { name: 'COMPANY_NAME', value: props.formData ? props.formData.COMPANY_NAME : '', error: '', isRequired: true },
        CLIENT_NAME: { name: 'CLIENT_NAME', value: props.formData ? props.formData.CLIENT_NAME : '', error: '', isRequired: true },
        CLIENT_ADDRESS: { name: 'CLIENT_ADDRESS', value: props.formData ? props.formData.CLIENT_ADDRESS : '', error: '', isRequired: true },
        CLIENT_DETAILS: { name: 'CLIENT_DETAILS', value: props.formData ? props.formData.CLIENT_DETAILS : '', error: '', isRequired: false },
        CLIENT_ID: { name: 'CLIENT_ID', value: props.formData ? props.formData.CLIENT_ID : '', options: [], error: '', isRequired: true },

    })
    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'CLIENT_ID') {
            if (value && value != 0) {
                const valuesForClientId = state.CLIENT_ID.options.filter(option => option.CLIENT_ID == value)[0]
                console.log('Values for Client ID:', valuesForClientId);
                setState(prevState => ({
                    ...prevState,
                    ['COMPANY_NAME']: {
                        ...prevState['COMPANY_NAME'],
                        value: valuesForClientId.COMPANY_NAME
                    },
                    ['CLIENT_NAME']: {
                        ...prevState['CLIENT_NAME'],
                        value: valuesForClientId.CLIENT_NAME
                    },
                    ['CLIENT_ADDRESS']: {
                        ...prevState['CLIENT_ADDRESS'],
                        value: valuesForClientId.CLIENT_ADDRESS
                    },
                    ['CLIENT_DETAILS']: {
                        ...prevState['CLIENT_DETAILS'],
                        value: valuesForClientId.CLIENT_DETAILS
                    }
                }));
            }
        }
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }
    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    useState(() => {
        v3_client_options().then((res) => {
            if (res.status) {
                setOptions('CLIENT_ID', res.result)
            }
        })
    }, [])

    const onSubmit = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                CLIENT_NAME: state.CLIENT_NAME.value,
                CLIENT_DETAILS: state.CLIENT_DETAILS.value,
                CLIENT_ADDRESS: state.CLIENT_ADDRESS.value,
                COMPANY_NAME: state.COMPANY_NAME.value,
                CLIENT_ID: state.CLIENT_ID.value
            }
            props.onSave(model)
        }
    }
    return (
        <React.Fragment>
            <div className="tab-content">
                <div
                    className="tab-pane fade active show"
                    id="pills-gen-info"
                    role="tabpanel"
                    aria-labelledby="pills-gen-info-tab"
                >
                    <div >
                        <div className="mb-4">
                            <div className={styles.pageDetailsBox}>
                                <h5 class="mb-1">Client Details</h5>
                                <p class="">Fill in all information as below.</p>
                                {/* <p class="text-danger">All fields are mandatory <span class="text-danger">*</span></p> */}
                            </div>
                        </div>
                      <div className={styles.formsSectionBox}> 
                        <div className={` ${styles.postNewJob} row`} >
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label
                                        className="form-label"
                                    >
                                        Client
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>
                                    </label>
                                    <select
                                        className={state.CLIENT_ID.error && !state.CLIENT_ID.value ? "form-select is-invalid" : "form-select"}
                                        name={state.CLIENT_ID.name}
                                        value={state.CLIENT_ID.value}
                                        onChange={(e) => { onChange(e) }}

                                    >
                                        <option value=''> Select Client</option>
                                        {state.CLIENT_ID.options && state.CLIENT_ID.options.map((item) => {
                                            return (
                                                <option value={item.CLIENT_ID}> {item.COMPANY_NAME}</option>

                                            )
                                        })}
                                        <option value='0'> Non Client Requirement</option>

                                    </select>
                                    < div className="invalid-feedback">
                                        Please select client
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className={`${styles.postNewJob} row mt-3`}>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label
                                        className="form-label"
                                    >
                                        Client Name
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>
                                    </label>
                                    {/* <select class="form-select" name="CLIENT_ID"><option value=""> Select Client Name:</option><option value="0"> Non Client Requirement</option></select> */}
                                    <input
                                        type="text"
                                        className={state.COMPANY_NAME.error && !state.COMPANY_NAME.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Company Name"
                                        disabled={state.CLIENT_ID.value && state.CLIENT_ID.value != 0 ? true : false}
                                        name={state.COMPANY_NAME.name}
                                        value={state.COMPANY_NAME.value}
                                        onChange={onChange}

                                    />
                                    < div className="invalid-feedback">
                                        Please enter a company name
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label
                                        className="form-label"
                                    >
                                        SPOC Name
                                        <span className='text-danger'>&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.COMPANY_NAME.error && !state.COMPANY_NAME.value ? "form-control is-invalid" : "form-control"}
                                        disabled={state.CLIENT_ID.value && state.CLIENT_ID.value != 0 ? true : false}

                                        placeholder='SPOC Name'
                                        name={state.CLIENT_NAME.name}
                                        value={state.CLIENT_NAME.value}
                                        onChange={onChange}
                                    />
                                    < div className="invalid-feedback">
                                        Please enter a spoc name
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className={`  ${styles.postNewJob} mb-3`}>
                            <label
                                className="form-label"
                                htmlFor="gen-info-password-input"
                            >
                                Client Details
                                <span className='text-danger'>&nbsp;*</span>

                            </label>
                            <textarea
                                className={state.CLIENT_DETAILS.error && !state.CLIENT_DETAILS.value ? "form-control is-invalid" : "form-control"}
                                disabled={state.CLIENT_ID.value && state.CLIENT_ID.value != 0 ? true : false}

                                placeholder="Client Details"
                                name={state.CLIENT_DETAILS.name}
                                value={state.CLIENT_DETAILS.value}
                                onChange={onChange}

                            />
                            < div className="invalid-feedback">
                                Please enter a client details
                            </div>
                        </div>

                        <div className={`${styles.postNewJob} mb-3`}>
                            <label
                                className="form-label"
                            >
                                Client Address
                                <span className='text-danger'>&nbsp;*</span>

                            </label>
                            <textarea
                                className={state.CLIENT_ADDRESS.error && !state.CLIENT_ADDRESS.value ? "form-control is-invalid" : "form-control"}
                                disabled={state.CLIENT_ID.value && state.CLIENT_ID.value != 0 ? true : false}

                                placeholder="Client Adress"
                                name={state.CLIENT_ADDRESS.name}
                                value={state.CLIENT_ADDRESS.value}
                                onChange={onChange}
                            />
                            < div className="invalid-feedback">
                                Please enter a client address
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                            type="button"
                            className={` ${styles.buttonNext} btn  right ms-auto nexttab nexttab`}
                            onClick={() => { onSubmit() }}
                        >
                            {/* <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" /> */}
                            Next
                        </button>
                    </div>
                    </div>  
                </div>
            </div>

        </React.Fragment >
    )
}

export default StepOne