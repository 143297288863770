import React, { useEffect, useState } from 'react'
import { v3_dashboard_clients } from '../../../../actions/v3/dashboard'
import CountStats from '../../../../components/companyUser/team/dashboad/count_stats'
import Clients from '../../../../components/companyUser/team/dashboad/clients'
import Reminder from '../../../../components/companyUser/team/dashboad/Reminder'
import { v3_team_dashboard_count_stats } from '../../../../actions/v3/team'
import ApplicationStatistics from '../../../../components/companyUser/team/dashboad/applicationStatistics'
import BreadCrumb from '../../../../components/common/BreadCrumb'
import Overview from '../../../../components/companyUser/team/dashboad/overview'
const Index = () => {
  const [countStats, setCountStats] = useState(null);
  const [clientList, setClientList] = useState(null);
  const [clientCount, setClientCount] = useState(null);
  const [clientListCurrentPage, setClientListCurrentPage] = useState(1);


  useEffect(() => {
    v3_team_dashboard_count_stats().then((res) => {
      if (res.status) {
        setCountStats(res.result)
      }
    })

  }, [])





  useEffect(() => {

    v3_dashboard_clients(clientListCurrentPage).then((res) => {
      if (res.status) {
        setClientList(res.result.list)
        setClientCount(res.result.count)
      }
    })

  }, [clientListCurrentPage])

  return (
    <React.Fragment>

      <div className="container-fluid">
        <BreadCrumb
          page={<React.Fragment><span>Team Dashboard</span></React.Fragment>}
          parentSection={'Team'}
        />
        <div className="row">
          <div className="col-md-9">

            <CountStats
              countStats={countStats}
            />
            <div className='row'>
              <ApplicationStatistics />
              <Overview
                countStats={countStats}

              />
            </div>


          </div>

          <Reminder />

          {/*end col*/}
          <div className="col-xl-12 clientTableSection">
            <Clients
              clientList={clientList}
            />
          </div>
        </div>


      </div>


    </React.Fragment>
  )
}

export default Index