import React, { useState } from 'react'
import { validateFunctionalForm } from '../../../utils';

const QuickAdd = (props) => {
    const [state, setState] = useState({
        FULL_NAME: { name: 'FULL_NAME', value: '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: false },
        PHONE: { name: 'PHONE', value: '', error: '', isRequired: true },
        SOURCE: { name: 'SOURCE', value: '', error: '', isRequired: true },
        NOTE: { name: 'NOTE', value: '', error: '', isRequired: false },
        RESUME: { name: 'RESUME', value: '', error: '', isRequired: false },


    })


    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        debugger
        if (validateFunctionalForm(state, setState)) {
            const model = {
                FULL_NAME: state.FULL_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                SOURCE: state.SOURCE.value,
                NOTE: state.NOTE.value,
                RESUME: state.RESUME.value,

            }
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }

            props.onSubmit(formData)

        }

    }


    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <form className="live-preview live-previewBox" onSubmit={(e) => { onSubmit(e) }}>
                        <div className="row gy-4">
                            <div className="col-lg-6 mb-1 ">
                                <div>
                                    <label className="form-label">
                                        Full Name
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className={state.FULL_NAME.error.length > 0 && !state.FULL_NAME.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Full Name"
                                        onChange={onChange}

                                        name={state.FULL_NAME.name}
                                        value={state.FULL_NAME.value}
                                    />
                                    <div className="invalid-feedback">Please enter a full name.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-1">
                                <div>
                                    <label className="form-label">
                                        Email
                                        {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                    </label>
                                    <input
                                        type="text"
                                        className={state.EMAIL.error.length > 0 && !state.EMAIL.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Email"
                                        onChange={onChange}
                                        name={state.EMAIL.name}
                                        value={state.EMAIL.value}
                                    />
                                    <div className="invalid-feedback">Please enter email address.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-1 mtp-5">
                                <div>
                                    <label className="form-label">
                                        Contact No: 
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>
                                        &nbsp; <i style={{ cursor: 'pointer' }} className='mdi mdi-whatsapp' onClick={() => { window.open(`https://api.whatsapp.com/send/?phone=91${state.PHONE.value}&text&type=phone_number&app_absent=0`, '_blank') }} />
                                    </label>
                                    <input
                                        type="number"
                                        className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control is-invalid" : "form-control"}
                                        placeholder="Phone"
                                        onChange={onChange}
                                        name={state.PHONE.name}
                                        value={state.PHONE.value}
                                    />
                                    <div className="invalid-feedback">Please enter phone number.</div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-1 mtp-5">
                                <div>
                                    <label className="form-label">
                                        Lead Source
                                        <span className='text-danger'>&nbsp;&nbsp;*</span>

                                    </label>
                                    <select
                                        className={state.SOURCE.error.length > 0 && !state.SOURCE.value ? "form-select is-invalid" : "form-select"}
                                        onChange={onChange}
                                        name={state.SOURCE.name}
                                        value={state.SOURCE.value}
                                    >
                                        <option value=''> Select Lead Source</option>
                                        <option value='Found It / Monster'> Found It / Monster</option>
                                        <option value='Naukri'> Naukri</option>
                                        <option value='LinkedIn'> LinkedIn</option>
                                        <option value='Instagram'> Instagram</option>
                                        <option value='apna'> apna</option>
                                        <option value='Campus'> Campus</option>
                                        <option value='Other Portal'> Other Portal</option>
                                        <option value='Referral'> Referral</option>
                                        <option value='Social Media'> Social Media</option>
                                        <option value='Walk-In'> Walk-In</option>
                                    </select>
                                    <div className="invalid-feedback">Please select Lead Source</div>
                                </div>
                            </div>



                            <div className="col-lg-12  mb-1 mtp-5">
                                <div>
                                    <label className="form-label">
                                        Feedback
                                        {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                    </label>
                                    <textarea
                                        placeholder='Enter Note / Remarks'
                                        className={state.NOTE.error.length > 0 && !state.NOTE.value ? "form-control is-invalid" : "form-control"}
                                        onChange={onChange}
                                        name={state.NOTE.name}
                                        value={state.NOTE.value}
                                        rows={3}
                                    />
                                    <div className="invalid-feedback">Please upload resume.</div>
                                    <p className='namecontantbx'>500/500 Character(s) Left</p>
                                </div>
                            </div>
                            <div className="col-lg-12  mb-1 mtp-5">
                            {/* <label className="form-label">
                                        Resume
                                       

                                    </label> */}
                             <p className='topparaBox'>Add your resume here, and you can upload up to max 5 mb files</p>       
                            <div class="containerbox">


                            <img src="/assets/images/FolderIcon.png"  />
                            
                            <div className='inputParaBox'>Attach Files by dragging your file(s) to start uploading</div>
                             <div className='orSeprestionbox'><h4>or</h4> </div>
                            <label class="btn" for="upload">
                                Browse Files
                                <input
                                        type="file"
                                        id="upload"
                                        class="isVisuallyHidden"
                                        onChange={(e) => {
                                            setState(prevState => ({
                                                ...prevState,
                                                ['RESUME']: {
                                                    ...prevState['RESUME'],
                                                    value: e.target.files[0]
                                                }
                                            }));
                                        }}
                                    />
                            </label>
                            
                       
                            </div>
                            

                                {/* <div>
                              
                                    <input
                                        type="file"
                                        className={state.RESUME.error.length > 0 && !state.RESUME.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                        onChange={(e) => {
                                            setState(prevState => ({
                                                ...prevState,
                                                ['RESUME']: {
                                                    ...prevState['RESUME'],
                                                    value: e.target.files[0]
                                                }
                                            }));
                                        }}
                                    />
                                    <div className="invalid-feedback">Please upload resume.</div>
                                </div> */}
                            </div>
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light-transperent " onClick={() => { props.onClose() }}>
                                    Cancel
                                </button>
                                <button type="submit" className="btn btn-light-solid" id="add-btn">
                                    Add Lead
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div >
        </React.Fragment >
    )
}

export default QuickAdd