import React, { useState } from 'react'
import constant from '../../../../constant'
import { getStorage, removeAllLocalStorage } from '../../../../utils';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import NotificationBadge from '../notificationBadge';
import styles from './header.module.css'


const Header = ({ isSidebarCollapsed, setIsSidebarCollapsed }) => {

    const [ud, setUd] = useState(getStorage(constant.keys.companyUserDetails))
    const havingTeam = getStorage(constant.keys.companyUserHavingTeam);
    const isAdministrator = getStorage(constant.keys.companyUserHavingAdminRights);


    const getFullScreenElement = () => {
        return document.fullscreenElement
            || document.webkitFullscreenElement
            || document.mozFullScreenElement
            || document.msFullscreenElement;
    };

    const toggleFullScreen = () => {
        if (getFullScreenElement()) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            document.documentElement.requestFullscreen().catch((e) => {
                console.error(`Error attempting to enable full-screen mode: ${e.message} (${e.name})`);
            });
        }
    };

    return (
        <header id="page-topbar" className={isSidebarCollapsed ? styles.headerOpen : styles.headerClose}>
            <div className="layout-width">
                <div className={`navbar-header ${styles.stickytop}`}>
                    <div className="d-flex">
                        <button
                            type="button"
                            onClick={() => {
                                setIsSidebarCollapsed(!isSidebarCollapsed)
                            }}
                            className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                            id="topnav-hamburger-icon"
                        >
                            <span className={` ${styles.openClosebtn} hamburger-icon`}>
                                <span />
                                <span />
                                <span />
                            </span>
                        </button>

                        <div>
                            {(havingTeam || isAdministrator) &&
                                <div className="app-search d-none d-md-block">
                                    <div>
                                        <Link className={`${styles.NewDownloadBtn} reportHeaderButton form-control`}
                                            to={constant.companyUserComponent.JobApplicationReport.url}>
                                            <img
                                                src="/assets/images/profile-vector.png"
                                                alt="report"
                                            /> Download Report Card
                                        </Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="dropdown d-md-none topbar-head-dropdown header-item">
                            <button
                                type="button"
                                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                                id="page-header-search-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i className="bx bx-search fs-22" />
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                                aria-labelledby="page-header-search-dropdown"
                            >
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Search ..."
                                                aria-label="Recipient's username"
                                            />
                                            <button className="btn btn-primary" type="submit">
                                                <i className="mdi mdi-magnify" />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                        <div className="ms-1 header-item d-none d-sm-flex">
                            <button
                                type="button"
                                onClick={() => toggleFullScreen()}
                                className={`btn btn-icon btn-topbar btn-ghost-secondary rounded-circle`}
                                data-toggle="fullscreen"
                            >
                                <i className="bx bx-fullscreen fs-22" />
                            </button>
                        </div>
                        <NotificationBadge />

                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <button
                                type="button"
                                className="btn"
                                id="page-header-user-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span className={`${styles.userIconBox} d-flex align-items-center`}>

                                    <span className="text-start ms-xl-2" style={{ marginTop: '-4px' }}>
                                        <span className={`${styles.userNameFont} d-none d-xl-inline-block ms-1 fw-medium user-name-text`}>
                                            {ud.FULL_NAME}
                                        </span>
                                        <span className={`${styles.userProfileText} d-none d-xl-block ms-1 fs-12 user-name-sub-text`}>
                                            {ud.USER_ROLE}
                                        </span>
                                    </span>
                                    {ud.LOGO && <img
                                        className="header-profile-user"
                                        // className="rounded-circle header-profile-user"
                                        src={ud.LOGO}
                                        alt="Header Avatar"
                                    />}
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end customDropdownHeader">
                                {/* item*/}
                                <h6 className="dropdown-header">Welcome {ud.FULL_NAME} !</h6>
                                <a className="dropdown-item" href={constant.companyUserComponent.profilePreview.url}>
                                    <i className="mdi mdi-account-circle fs-16 align-middle me-1" />{" "}
                                    <span className="align-middle">Profile</span>
                                </a>

                                <div className="dropdown-divider" />
                                <a className="dropdown-item" href={'javascript:void(0)'}
                                    onClick={() => {
                                        removeAllLocalStorage();
                                        window.location.href = constant.nonAuthedcomponent.login.url.replace(':user_type', 'user');
                                    }}>
                                    <i className="mdi mdi-logout fs-16 align-middle me-1" />{" "}
                                    <span className="align-middle" >
                                        Logout
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header >


    )
}

export default Header