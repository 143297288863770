import React, { useRef, useEffect } from "react";
import { Chart } from "react-google-charts";

export const convertJsonToChartData = (json) => {
    const data = json.map((user) => [
        {
            v: user.USER_ID.toString(),
            f: user.FULL_NAME.toString() + `<div>${user.DESIGNATION_NAME || ""}</div>`,
        },
        user.RM_ID ? user.RM_ID.toString() : "", // parent ID
        user.DESIGNATION_NAME || "", // title or designation
    ]);
    return data;
};

export const options = {
    allowHtml: true,
};

export default function OrgChart({ json, setSelected }) {
    const zoomContainerRef = useRef(null); // Reference for the zoomable container
    const chartRef = useRef(null); // Reference for the chart element
    const scaleRef = useRef(1); // Tracks the current zoom level
    const position = useRef({ x: 0, y: 0 }); // Tracks the current pan position
    const isDragging = useRef(false); // Dragging state
    const startPoint = useRef({ x: 0, y: 0 }); // Initial mouse position during dragging

    const data = convertJsonToChartData(json);

    const handleNodeStateChange = ({ chartWrapper }) => {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();

        const selectedNodes = selection.map((item) => {
            const userObject = data[item.row];
            return {
                userId: userObject[0]["v"],
            };
        });

        if (setSelected) {
            setSelected(selectedNodes?.length ? selectedNodes[0]["userId"] : "");
        }
    };

    const handleWheel = (event) => {
        event.preventDefault();
        const zoomStep = 0.1; // Step size for zooming
        let newScale = scaleRef.current;

        if (event.deltaY < 0) {
            newScale += zoomStep; // Zoom in
        } else {
            newScale = Math.max(0.5, newScale - zoomStep); // Zoom out, minimum 50%
        }

        scaleRef.current = newScale;
        applyTransform();
    };

    const handleMouseDown = (event) => {
        isDragging.current = true;
        startPoint.current = { x: event.clientX, y: event.clientY };
    };

    const handleMouseMove = (event) => {
        if (!isDragging.current) return;

        const dx = event.clientX - startPoint.current.x;
        const dy = event.clientY - startPoint.current.y;

        position.current.x += dx / scaleRef.current; // Adjust for scale
        position.current.y += dy / scaleRef.current;

        startPoint.current = { x: event.clientX, y: event.clientY };
        applyTransform();
    };

    const handleMouseUp = () => {
        isDragging.current = false;
    };

    const applyTransform = () => {
        if (zoomContainerRef.current) {
            zoomContainerRef.current.style.transform = `translate(${position.current.x}px, ${position.current.y}px) scale(${scaleRef.current})`;
        }
    };

    const findRootNode = () => {
        const childNodes = new Set(data.map((item) => item[1]));
        const root = data.find((item) => !childNodes.has(item[0].v));
        return root || data[0]; // Default to the first node if root not found
    };

    useEffect(() => {
        const rootNode = findRootNode();
        if (rootNode && chartRef.current && zoomContainerRef.current) {
            const containerRect = zoomContainerRef.current.getBoundingClientRect();
            const rootNodeIndex = data.findIndex((item) => item[0].v === rootNode[0].v);

            // Assume approximate node dimensions and calculate the center
            const nodeWidth = 150; // Adjust based on actual node width
            const nodeHeight = 50; // Adjust based on actual node height
            const rootNodeX = rootNodeIndex * nodeWidth; // Approximate X position
            const rootNodeY = nodeHeight / 2; // Center vertically

            position.current = {
                x: containerRect.width / 2 - rootNodeX,
                y: containerRect.height / 2 - rootNodeY,
            };
            applyTransform();
        }
    }, [data]);

    return (
        <div
            className="organizationChartBox"
            style={{
                position: "relative",
                overflow: "hidden",
                width: "100%",
                height: "100%",
            }}
            onWheel={handleWheel}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp} // Stop panning when the mouse leaves the container
        >
            <div
                ref={zoomContainerRef}
                style={{
                    transformOrigin: "0 0", // Transform origin for scaling
                    cursor: isDragging.current ? "grabbing" : "grab", // Adjust cursor
                }}
            >
                <div ref={chartRef}>
                    <Chart
                        chartType="OrgChart"
                        data={[
                            [{ v: "", f: "" }, "", ""], // Header row
                            ...data,
                        ]}
                        options={options}
                        width="100%"
                        chartEvents={[
                            {
                                eventName: "select",
                                callback: handleNodeStateChange,
                            },
                        ]}
                    />
                </div>
            </div>
        </div>
    );
}
