import React, { useState } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import constant from '../../constant'
import { validateFunctionalForm } from '../../utils'
import styles from './login.module.css'
const Login = (props) => {
    const [state, setState] = useState({
        USER_NAME: { name: 'USER_NAME', value: '', error: '', isRequired: true },
        PASSWORD: { name: 'PASSWORD', value: '', visible: false, error: '', isRequired: true }
    })
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value
            }
        }));

    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                USER_NAME: state.USER_NAME.value,
                PASSWORD: state.PASSWORD.value
            }
            props.onSubmit(model)
        }
    }
    return (
        <React.Fragment>
            <div className={`${styles.authBgCover} auth-page-wrapper d-flex justify-content-center align-items-center min-vh-100`}>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <div className="container">
                    <div className={`${styles.cardLoginBox} overflow-hidden`}> 
                        <div className="row">
                            <div className='col-md-7'>
                                <div className={styles.loginLeftContentBox}>
                                      <h3>Accelerate Your Hiring Processwith Our Advanced Recruitment </h3>
                                      <p><span><img src='/assets/images/CheckCircle.svg' alt='check image' /></span> <span><strong>Streamlined Candidate Management: </strong> Track every applicant with customizable pipelines tailored to your workflow.</span></p>
                                      <p><span><img src='/assets/images/CheckCircle.svg' alt='check image' /></span> <span><strong>Data-Driven Decisions: </strong> Leverage insightful recruitment reports to hire smarter.</span></p>
                                      <p><span><img src='/assets/images/CheckCircle.svg' alt='check image' /></span> <span><strong>Seamless Collaboration: </strong> Schedule interviews, manage referrals, and coordinate with your team effortlessly.</span></p>
                                      <p><span><img src='/assets/images/CheckCircle.svg' alt='check image' /></span> <span><strong>AI-Powered Efficiency: </strong> Benefit from intelligent assessments and innovative tools to enhance your hiring process.</span></p>
                                     <div className={styles.badgebox}>
                                          Join 4700+ businesses already scaling faster with us.
                                     </div> 

                                     <div className={styles.logoBusinessBox}>
                                          <div className={styles.logoBox}><img src='/assets/images/clickup.png' alt='check image' /></div>
                                          <div className={styles.logoBox}><img src='/assets/images/dropbox_blue.png' alt='check image' /></div>
                                          <div className={styles.logoBox}><img src='/assets/images/Elasticlogo.png' alt='check image' /></div>
                                          <div className={styles.logoBox}><img src='/assets/images/GitHub_Logo.png' alt='check image' /></div>
                                          <div className={styles.logoBox}><img src='/assets/images/freshbooks.png' alt='check image' /></div>    
                                     </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                             
                                    <div className="row g-0">
                                        {/* <div className="col-lg-6">
                                            <div className="p-lg-5 p-4 auth-one-bg h-100">
                                                <div className="bg-overlay" />
                                                <div className="position-relative h-100 d-flex flex-column">
                                                    <div className="mb-4">
                                                    </div>
                                                    <div className="mt-auto">
                                                        <div
                                                            id="qoutescarouselIndicators"
                                                            className="carousel slide"
                                                            data-bs-ride="carousel"
                                                        >
                                                            <div className="carousel-indicators customcarouselIndicators">
                                                                <button
                                                                    type="button"
                                                                    data-bs-target="#qoutescarouselIndicators"
                                                                    data-bs-slide-to={0}
                                                                    className="active"
                                                                    aria-current="true"
                                                                    aria-label="Slide 1"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    data-bs-target="#qoutescarouselIndicators"
                                                                    data-bs-slide-to={1}
                                                                    aria-label="Slide 2"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    data-bs-target="#qoutescarouselIndicators"
                                                                    data-bs-slide-to={2}
                                                                    aria-label="Slide 3"
                                                                />
                                                            </div>
                                                            <div className="carousel-inner text-center text-white pb-5">
                                                                <div className="carousel-item active">
                                                                    <img src="/assets/images/homepage/dasboard-screen-1.png" width="100%" alt="app" class="img-fluid mask-img" />
                                                                    <p className="fs-21 fst-italic text-white mt-3">
                                                                        " Great! Clean code, clean design, easy for
                                                                        customization. Thanks very much! "
                                                                    </p>
                                                                </div>
                                                                <div className="carousel-item">
                                                                    <img src="/assets/images/homepage/dasboard-screen-3.png" width="100%" alt="app" class="img-fluid mask-img" />
                                                                    <p className="fs-21 fst-italic text-white mt-3">
                                                                        " The theme is really great with an amazing
                                                                        customer support."
                                                                    </p>
                                                                </div>
                                                                <div className="carousel-item">
                                                                <img src="/assets/images/homepage/dasboard-screen-4.png" width="100%" alt="app" class="img-fluid mask-img" />
                                                                    <p className="fs-21 fst-italic text-white mt-3">
                                                                        " Great! Clean code, clean design, easy for
                                                                        customization. Thanks very much! "
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */} 
                                        <div className="col-lg-12">
                                            <div className="p-lg-5 p-4">
                                                <Link to={constant.nonAuthedcomponent.landingPage.url} className="d-block loginLogoImg">
                                                    <img
                                                        src="/images/logo/recruit-logo.png"
                                                        alt=""
                                                        height={55}
                                                    />
                                                </Link>
                                                <div  className={styles.loginRightHed} >
                                                    <h5> 
                                                        Sign in
                                                    </h5>
                                                    <p className="text-muted">Sign in to continue to Jobzshala Recruit.</p>
                                                </div>
                                                <div className={`${styles.loginFormBox} mt-4`}>
                                                    <form className={styles.formLogin} onSubmit={(e) => { onSubmit(e) }}>
                                                        <div className="mb-4">
                                                            {/* <label htmlFor="username" className="form-label">
                                                                Username
                                                            </label> */}
                                                            <input
                                                                type="text"
                                                                className={state.USER_NAME.error ? "form-control is-invalid" : "form-control"}
                                                                id="username"
                                                                placeholder="Enter username"
                                                                name={state.USER_NAME.name}
                                                                value={state.USER_NAME.value}
                                                                onChange={(e) => { onChange(e) }}
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            {/* <label className="form-label" htmlFor="password-input">
                                                                Password
                                                            </label> */}
                                                            <div className="position-relative auth-pass-inputgroup">
                                                                <input
                                                                    type="password"
                                                                    className="form-control pe-5 password-input"
                                                                    placeholder="Enter password"
                                                                    id="password-input"
                                                                    name={state.PASSWORD.name}
                                                                    value={state.PASSWORD.value}
                                                                    onChange={(e) => { onChange(e) }}
                                                                />
                                                                {/* <button
                                                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                                    type="button"
                                                                    id="password-addon"
                                                                > */}
                                                                {/* <i className={state.PASSWORD.visible ? "ri-eye-fill align-middle" : 'ri-eye-close align-middle'} onClick={() => {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            ['PASSWORD']: {
                                                                                ...prevState['PASSWORD'],
                                                                                visible: !state.PASSWORD.visible
                                                                            }
                                                                        }));
                                                                    }} /> */}
                                                                {/* </button> */}
                                                            </div>
                                                            <div>
                                                                {/* <label className="form-label" htmlFor="password-input">
                                                                    <input type="checkbox" id="" name="" value="" style={{marginRight: 5}} />
                                                                    Remember me
                                                                </label> */}
                                                                {/* <div className={styles.forgotPasswordBox}>
                                                                    <a href="forgot-password.php" className="">
                                                                        Forgot password?
                                                                    </a>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        {/* <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                defaultValue=""
                                                                id="auth-remember-check"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="auth-remember-check"
                                                            >
                                                                Remember me
                                                            </label>
                                                        </div> */}
                                                        <div className="mt-4">
                                                            <button className={styles.loginSigninButton} type="submit">
                                                                Sign In
                                                            </button>
                                                        </div>
                                                        {/* <div class="mt-4 text-center">
                                              <div class="signin-other-title">
                                                  <h5 class="fs-13 mb-4 title">Sign In with</h5>
                                              </div>

                                              <div>
                                                  <button type="button" class="btn btn-primary btn-icon waves-effect waves-light"><i class="ri-facebook-fill fs-16"></i></button>
                                                  <button type="button" class="btn btn-danger btn-icon waves-effect waves-light"><i class="ri-google-fill fs-16"></i></button>
                                                  <button type="button" class="btn btn-dark btn-icon waves-effect waves-light"><i class="ri-github-fill fs-16"></i></button>
                                                  <button type="button" class="btn btn-info btn-icon waves-effect waves-light"><i class="ri-twitter-fill fs-16"></i></button>
                                              </div>
                                          </div> */}
                                                    </form>
                                                </div>
                                                {/* <div class="mt-5 text-center">
                                      <p class="mb-0">Don't have an account ? <a href="auth-signup-cover.html" class="fw-semibold text-primary text-decoration-underline"> Signup</a> </p>
                                  </div> */}
                                            </div>
                                        </div>
                                        {/* end col */}
                                    </div>
                                    {/* end row */}
                                
                                {/* end card */}
                            </div>
                            {/* end col */}
                        </div>
                        {/* end row */}
                    </div>
                    </div>
                    {/* end container */}
                </div>
                {/* end auth page content */}
                {/* footer */}
                {/* <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0">
                                        © Jobzshala Recruit Crafted with <i className="mdi mdi-heart text-danger" />{" "}
                                        by AIPL.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
                {/* end Footer */}
            </div>

        </React.Fragment>
    )
}

export default Login