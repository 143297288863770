import React, { useState } from 'react'
import Pagination from '../../../common/Pagination'
import DrawerModal from '../../../../components/common/htmlDrawerModal'
import ClientDetailDrawerModal from '../../administrator/clientManagement/clientDetailDrawerModal'
import moment from 'moment'
import styles from './dashboard.module.css'

const Clients = (props) => {
    const { clientList, clientCount, clientListCurrentPage, setClientListCurrentPage } = props
    const [showClientDetailDrawerModal, setShowClientDetailDrawerModal] = useState(null);

    return (
        <React.Fragment>
            {/* <DrawerModal
                isOpen={showClientDetailDrawerModal}
                onClose={() => { setShowClientDetailDrawerModal(null) }}
            >
                {showClientDetailDrawerModal && <ClientDetailDrawerModal
                    clientDetails={showClientDetailDrawerModal}
                />}
            </DrawerModal> */}
            <div className='col-md-12'>
                <div className={styles.ourClientsSecton}>
                    <div className={`${styles.applicationTitle} ${styles.mrb20}`}>
                        <h4>Clients</h4>
                    </div>

                    <div className='row'>
                        {clientList && clientList.map((item) => {
                            const isExpired = item.AGREEMENT_END_DATE
                                ? moment(item.AGREEMENT_END_DATE).isBefore(moment(), 'day')
                                : false;
                            return (
                                <div className='col-md-4'>
                                    <div className={`${styles.clientCardBox} ${isExpired ? styles.secondBoxBg : ''}`}>
                                        <h4 className='text-truncate' title={item.COMPANY_NAME}> {item.COMPANY_NAME}</h4>
                                        <ul className={styles.clientInfoBox}>
                                            <li>
                                                <span className={styles.spanPerson}>Contact Person:</span>
                                                <span className={styles.spanName}>{item.CLIENT_NAME}</span>
                                            </li>
                                            <li>
                                                <span className={styles.spanPerson}>GST No.:</span>
                                                <span className={styles.spanName}>{item.GST_NO ? item.GST_NO : 'Not Available'}</span>
                                            </li>
                                            <li>
                                                <span className={styles.spanPerson}>Agreement Start:</span>
                                                <span className={styles.spanName}>{item.AGREEMENT_START_DATE ? moment(item.AGREEMENT_START_DATE).format('DD-MM-YYYY') : 'Not Available'}</span>
                                            </li>
                                            <li>
                                                <span className={styles.spanPerson}>Agreement End:</span>
                                                <span className={styles.spanName}>{item.AGREEMENT_END_DATE ? moment(item.AGREEMENT_END_DATE).format('DD-MM-YYYY') : 'Not Available'}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })
                        }


                    </div>

                </div>

            </div>
            {/* <DrawerModal
                isOpen={showClientDetailDrawerModal}
                onClose={() => { setShowClientDetailDrawerModal(null) }}
            >
                {showClientDetailDrawerModal && <ClientDetailDrawerModal
                    clientDetails={showClientDetailDrawerModal}
                />}
            </DrawerModal>

            <div class="col-md-12 mainclientStyleSection">
                <div className="card">
                    <div className="card-header align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">
                            Our Clients
                        </h4>
                    </div>
                    <div className="card-body">

                        {clientList && clientList.map((item) => {
                            return (
                                <a href="javascript:void(0)" class="clientSection">
                                    <div class="row">
                                        <div class="col-md-1 clientProfileImg">
                                            <img style={{ border: '2px solid #e7e7e7' }}
                                                className="rounded-circle"
                                                src={item.LOGO ? `${process.env.REACT_APP_BASE_URL}/v3/client-logo/${item.LOGO}` : "/assets/images/companies/img-2.png"}

                                                alt="Header Avatar"
                                            />
                                        </div>
                                        <div class="col-md-5 clientSectionLeft">
                                            <h3>
                                                {item.COMPANY_NAME}
                                              
                                            </h3>

                                            <p><i class="ri-user-fill"></i> Contact Person Name : {item.CLIENT_NAME}</p>
                                            <p><i class="ri-user-settings-fill"></i> GST No :  {item.GST_NO ? item.GST_NO : 'Not Available'}</p>
                                            <p><i class="ri-mail-fill"></i> Email : xxxxxxx
                                           
                                            </p>
                                            <p><i class="ri-phone-fill"></i> Mobile : xxxxxxx
                                           
                                            </p>

                                        </div>
                                        <div class="col-md-5 clientSectionRight">
                                            <p><i class="ri-building-fill"></i> Agreement Start : {item.AGREEMENT_START_DATE ? moment(item.AGREEMENT_START_DATE).format('DD-MM-YYYY') : 'Not Available'}</p>
                                            <p><i class="ri-bar-chart-grouped-line"></i> Agreement End : {item.AGREEMENT_END_DATE ? moment(item.AGREEMENT_END_DATE).format('DD-MM-YYYY') : 'Not Available'}</p>

                                            <p><i class="ri-chat-new-line"></i> Create On : {moment(item.CREATED_ON).fromNow()}</p>
                                            <p><i class="ri-time-fill"></i> Last Modified : {moment(item.MODIFIED_ON).format('DD-MM-YYYY')}</p>
                                        </div>
                                        <div class="col-md-1 clientMenuBtnSection">
                                            <a href="javascript:void(0)" style={{ visibility: 'hidden' }}>
                                                <i class="ri-more-2-line"></i>
                                            </a>
                                          
                                        </div>
                                    </div>
                                </a>

                            )
                        })}

                        <div className="customPaginationStyle">
                            <Pagination
                                totalCount={clientCount}
                                limit={5}
                                range={3}
                                currentPage={clientListCurrentPage}
                                onPageChange={(page) => props.setClientListCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    )
}

export default Clients