import React, { useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { v3_dashboard_application_statistics } from '../../../actions/v3/dashboard';
import styles from './dashboard.module.css'


export const options = {
    hAxis: {
        title: "Month", // Title for the horizontal axis
    },
    series: {
        1: { curveType: "function" }, // Example series curve
    },
    legend: {
        position: "bottom", // Move the legend to the bottom
    },
    chartArea: {
        top: 20, // Adjust for better layout
        bottom: 50, // Ensure space for the legend
        left: 50,
        right: 30,
    },
};


const ApplicationStatistics = () => {
    const [data, setData] = useState(null)
    const [dateRange, setDateRange] = useState('1 Month')
    useEffect(() => {
        v3_dashboard_application_statistics(dateRange).then(res => {
            if (res.status) {
                setData(res.result)
            }
        })
    }, [dateRange])

    return (
        <React.Fragment>
            <div className='col-md-7'>
                <div className={styles.applicationStatisticsBox}>
                    <div className={styles.applicationTitle}>
                        <h4>Application Statistics</h4>
                    </div>
                    <div className={styles.applicationMap}>
                    {data && <Chart
                           chartType="Bar"
                           width="100%"
                           height="373px"
                           data={data.data}
                           options={options}
                           legendToggle
                        />}
                    </div>

                </div>
            </div>
            {/* <div className="card card-height-100">
                <div className="card-header border-0 align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Applications Statistic</h4>

                    <div className="chartBaseBtn">
                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => {
                            setDateRange('1 Month')
                        }}>
                            1M
                        </button>
                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => {
                            setDateRange('3 Month')
                        }}>
                            3M
                        </button>
                        <button type="button" className="btn btn-soft-primary btn-sm" onClick={() => {
                            setDateRange('6 Month')
                        }}>
                            6Y
                        </button>
                        <button type="button" className="btn btn-soft-secondary btn-sm" onClick={() => {
                            setDateRange('12 Month')
                        }}>
                            1Y
                        </button>
                    </div>
                </div>
                <div className="card-header border-0" style={{ padding: '0px 10px !important', }}>
                    <div className="row g-0 text-center">
                        <div className="col-6 col-sm-3 p-2">
                            <div className="p-3 border border-dashed border-start-0 custondashedStyleSection">
                                <h5 className="mb-1">
                                    <span className="counter-value" >
                                        {data?.totalCandidates}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">New Candidates</p>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 p-2">
                            <div className="p-3 border border-dashed border-start-0 custondashedStyleSection">
                                <h5 className="mb-1">
                                    <span className="counter-value">
                                        {data?.totalApplications}

                                    </span>
                                </h5>
                                <p className="text-muted mb-0">New Applications</p>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 p-2">
                            <div className="p-3 border border-dashed border-start-0 custondashedStyleSection">
                                <h5 className="mb-1">
                                    <span className="counter-value" >
                                        {data?.totalSelected}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Hired</p>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 p-2">
                            <div className="p-3 border border-dashed custondashedStyleSection">
                                <h5 className="mb-1 text-success">
                                    <span className="counter-value" >
                                        {data?.totalJoined}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">Joined</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body p-0 pb-2 customChartStyleLegend">
                    <div className="w-100">
                        {data && <Chart
                            chartType="Bar"
                            width="100%"
                            height="400px"
                            data={data.data}
                            options={options}
                        />}
                    </div>
                </div>
            </div> */}
        </React.Fragment>
    )
}

export default ApplicationStatistics