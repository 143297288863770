import React from 'react'
import styles from './footer.module.css'
const Footer = () => {
    return (
        <React.Fragment>
            <footer className={styles.customFooterSection}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mt-0">
                            <div>
                                <div className={styles.footerLogoBx}>
                                    <img
                                        src="/images/logo/recruit-logo.png"
                                        alt="logo light"
                                        height={55}
                                    />
                                </div>
                                <div className={styles.footerPara}>
                                    <p>Jobzshala Recruit - Advanced Applicant Tracking System</p>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-lg-7 ms-lg-auto">
                            <div className="row">
                                <div className="col-sm-4 mt-0 footerSectionHeadingStyle">
                                    <h5 className={styles.footerTitle}>Company</h5>
                                    <div className={styles.footerSectionInnerStyle}>
                                        <ul className="list-unstyled ff-secondary footer-list fs-15">
                                            <li>
                                                <a href="pages-profile.html">
                                                    <i class="ri-arrow-right-line"></i> About Us
                                                </a>
                                            </li>
                                            <li>
                                                <a href="pages-gallery.html">
                                                    <i class="ri-arrow-right-line"></i>Gallery
                                                </a>
                                            </li>
                                            <li>
                                                <a href="apps-projects-overview.html">
                                                    <i class="ri-arrow-right-line"></i>Projects
                                                </a>
                                            </li>
                                            <li>
                                                <a href="pages-timeline.html">
                                                    <i class="ri-arrow-right-line"></i>Timeline
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-0 footerSectionHeadingStyle">
                                    <h5 className={styles.footerTitle}>Apps Pages</h5>
                                    <div className={styles.footerSectionInnerStyle}>
                                        <ul className="list-unstyled ff-secondary footer-list fs-15">
                                            <li>
                                                <a href="pages-pricing.html">
                                                    <i class="ri-arrow-right-line"></i>Calendar
                                                </a>
                                            </li>
                                            <li>
                                                <a href="apps-mailbox.html">
                                                    <i class="ri-arrow-right-line"></i>Mailbox
                                                </a>
                                            </li>
                                            <li>
                                                <a href="apps-chat.html">
                                                    <i class="ri-arrow-right-line"></i>Chat
                                                </a>
                                            </li>
                                            <li>
                                                <a href="apps-crm-deals.html">
                                                    <i class="ri-arrow-right-line"></i>Deals
                                                </a>
                                            </li>
                                            <li>
                                                <a href="apps-tasks-kanban.html">
                                                    <i class="ri-arrow-right-line"></i>Kanban Board
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-0 footerSectionHeadingStyle">
                                    <h5 className={styles.footerTitle}>Support</h5>
                                    <div className={styles.footerSectionInnerStyle}>
                                        <ul className="list-unstyled ff-secondary footer-list fs-15">
                                            <li>
                                                <a href="pages-faqs.html">
                                                    <i class="ri-arrow-right-line"></i>FAQ
                                                </a>
                                            </li>
                                            <li>
                                                <a href="pages-faqs.html">
                                                    <i class="ri-arrow-right-line"></i>Contact
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <footer className={styles.copyrightFooter}>
            <div className="container">
                    <div className="row row-copyrights">
                       
            <div class="col-md-9 ">
                <p class="copyright-text pb-0 mb-0">
                    Copyrights © 2020. All
                    rights reserved by
                    <a href="" className="copyrighthoverStyle" target="_blank"> Jobzshala - AIPL</a>
                </p>
            </div>
            <div class="col-md-3 ">
          
                                    <ul className="list-inline mb-0 footer-social-link">
                                        <li className="list-inline-item">
                                            <a href="javascript: void(0);" className="avatar-xs d-block">
                                                <div className="avatar-title rounded-circle customAvatharSocial">
                                                    <i className="ri-facebook-fill" />
                                                </div>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript: void(0);" className="avatar-xs d-block">
                                                <div className="avatar-title rounded-circle customAvatharSocial">
                                                    <i className="ri-github-fill" />
                                                </div>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript: void(0);" className="avatar-xs d-block">
                                                <div className="avatar-title rounded-circle customAvatharSocial">
                                                    <i className="ri-linkedin-fill" />
                                                </div>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript: void(0);" className="avatar-xs d-block">
                                                <div className="avatar-title rounded-circle customAvatharSocial">
                                                    <i className="ri-google-fill" />
                                                </div>
                                            </a>
                                        </li>
                                        <li className="list-inline-item">
                                            <a href="javascript: void(0);" className="avatar-xs d-block">
                                                <div className="avatar-title rounded-circle customAvatharSocial">
                                                    <i className="ri-dribbble-line" />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                              
            </div>
            </div>
           
            </div>
            </footer>
            <button
                onclick="topFunction()"
                className="btn btn-danger btn-icon landing-back-top"
                id="back-to-top"
                style={{ display: "none" }}
            >
                <i className="ri-arrow-up-line" />
            </button>
        </React.Fragment>
    )
}

export default Footer