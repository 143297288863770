import React, { useState } from 'react'
import { getStorage, removeAllLocalStorage } from '../../../utils';
import constant from '../../../constant';

const Header = () => {
    const [ud, setUd] = useState(getStorage(constant.keys.superAdminDetails))
    console.log(ud)
    return (
        <header id="page-topbar">
            <div className="layout-width">
                <div className="navbar-header">
                    <div className="d-flex">
                        {/* LOGO */}
                        <button
                            type="button"
                            className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                            id="topnav-hamburger-icon"
                        >
                            <span className="hamburger-icon open">
                                <span />
                                <span />
                                <span />
                            </span>
                        </button>
                        {/* App Search*/}
                        <form className="app-search d-none d-md-block">
                            <div className="position-relative">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    autoComplete="off"
                                    id="search-options"
                                    defaultValue=""
                                />
                                <span className="mdi mdi-magnify search-widget-icon" />
                                <span
                                    className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
                                    id="search-close-options"
                                />
                            </div>
                            <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                                <div data-simplebar="" style={{ maxHeight: 320 }}>
                                    {/* item*/}
                                    <div className="dropdown-header">
                                        <h6 className="text-overflow text-muted mb-0 text-uppercase">
                                            Recent Searches
                                        </h6>
                                    </div>
                                    <div className="dropdown-item bg-transparent text-wrap">
                                        <a
                                            href="index-2.html"
                                            className="btn btn-soft-secondary btn-sm rounded-pill"
                                        >
                                            how to setup <i className="mdi mdi-magnify ms-1" />
                                        </a>
                                        <a
                                            href="index-2.html"
                                            className="btn btn-soft-secondary btn-sm rounded-pill"
                                        >
                                            buttons <i className="mdi mdi-magnify ms-1" />
                                        </a>
                                    </div>
                                    {/* item*/}
                                    <div className="dropdown-header mt-2">
                                        <h6 className="text-overflow text-muted mb-1 text-uppercase">
                                            Pages
                                        </h6>
                                    </div>
                                    {/* item*/}
                                    <a
                                        href="javascript:void(0);"
                                        className="dropdown-item notify-item"
                                    >
                                        <i className="ri-bubble-chart-line align-middle fs-18 text-muted me-2" />
                                        <span>Analytics Dashboard</span>
                                    </a>
                                    {/* item*/}
                                    <a
                                        href="javascript:void(0);"
                                        className="dropdown-item notify-item"
                                    >
                                        <i className="ri-lifebuoy-line align-middle fs-18 text-muted me-2" />
                                        <span>Help Center</span>
                                    </a>
                                    {/* item*/}
                                    <a
                                        href="javascript:void(0);"
                                        className="dropdown-item notify-item"
                                    >
                                        <i className="ri-user-settings-line align-middle fs-18 text-muted me-2" />
                                        <span>My account settings</span>
                                    </a>
                                    {/* item*/}
                                    <div className="dropdown-header mt-2">
                                        <h6 className="text-overflow text-muted mb-2 text-uppercase">
                                            Members
                                        </h6>
                                    </div>
                                    <div className="notification-list">
                                        {/* item */}
                                        <a
                                            href="javascript:void(0);"
                                            className="dropdown-item notify-item py-2"
                                        >
                                            <div className="d-flex">
                                                <img
                                                    src="/assets/images/users/avatar-2.jpg"
                                                    className="me-3 rounded-circle avatar-xs"
                                                    alt="user-pic"
                                                />
                                                <div className="flex-grow-1">
                                                    <h6 className="m-0">Angela Bernier</h6>
                                                    <span className="fs-11 mb-0 text-muted">Manager</span>
                                                </div>
                                            </div>
                                        </a>
                                        {/* item */}
                                        <a
                                            href="javascript:void(0);"
                                            className="dropdown-item notify-item py-2"
                                        >
                                            <div className="d-flex">
                                                <img
                                                    src="/assets/images/users/avatar-3.jpg"
                                                    className="me-3 rounded-circle avatar-xs"
                                                    alt="user-pic"
                                                />
                                                <div className="flex-grow-1">
                                                    <h6 className="m-0">David Grasso</h6>
                                                    <span className="fs-11 mb-0 text-muted">
                                                        Web Designer
                                                    </span>
                                                </div>
                                            </div>
                                        </a>
                                        {/* item */}
                                        <a
                                            href="javascript:void(0);"
                                            className="dropdown-item notify-item py-2"
                                        >
                                            <div className="d-flex">
                                                <img
                                                    src="/assets/images/users/avatar-5.jpg"
                                                    className="me-3 rounded-circle avatar-xs"
                                                    alt="user-pic"
                                                />
                                                <div className="flex-grow-1">
                                                    <h6 className="m-0">Mike Bunch</h6>
                                                    <span className="fs-11 mb-0 text-muted">
                                                        React Developer
                                                    </span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="text-center pt-3 pb-1">
                                    <a
                                        href="pages-search-results.html"
                                        className="btn btn-primary btn-sm"
                                    >
                                        View All Results
                                        <i className="ri-arrow-right-line ms-1" />
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="d-flex align-items-center">
                      
                       
                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <button
                                type="button"
                                className="btn"
                                id="page-header-user-dropdown"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span className="d-flex align-items-center">
                                    <img
                                        className="rounded-circle header-profile-user"
                                        src="/assets/images/users/user-dummy-img.jpg"
                                        alt="Header Avatar"
                                    />
                                    <span className="text-start ms-xl-2">
                                        <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                            {ud?.FULL_NAME}
                                        </span>
                                        <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">
                                        {ud?.ROLE_NAME}
                                        </span>
                                    </span>
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                {/* item*/}
                                <h6 className="dropdown-header">Welcome {ud?.FULL_NAME}!</h6>
                                <a className="dropdown-item" href="pages-profile.html">
                                    <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />{" "}
                                    <span className="align-middle">Profile</span>
                                </a>
                                <a className="dropdown-item" href="apps-chat.html">
                                    <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1" />
                                    <span className="align-middle">Messages</span>
                                </a>
                                <a className="dropdown-item" href="apps-tasks-kanban.html">
                                    <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1" />
                                    <span className="align-middle">Taskboard</span>
                                </a>
                                <a className="dropdown-item" href="pages-faqs.html">
                                    <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1" />{" "}
                                    <span className="align-middle">Help</span>
                                </a>
                                <div className="dropdown-divider" />
                                <a className="dropdown-item" href="javascript:void(0);"
                                    onClick={() => {
                                        removeAllLocalStorage();
                                        window.location.href = constant.nonAuthedcomponent.login.url.replace(':user_type', 'su');

                                    }}>
                                    <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />{" "}
                                    <span className="align-middle" data-key="t-logout">
                                        Logout
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>


    )
}

export default Header