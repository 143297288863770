import React, { useEffect, useState } from 'react';
import { v3_delete_notifications, v3_notifications } from '../../../actions/v3/notification';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import constant from '../../../constant';
import styles from './Header/header.module.css'

const nl2br = (str) => {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
};

const NotificationItem = ({ item, deleteNotification }) => {
    let url = '';
    if (item.TYPE === 'JOB_UPDATE' || item.TYPE === 'LEAD_UPDATE') {
        if (item.TYPE === 'JOB_UPDATE' && item.URL_RECORD) {
            const data = JSON.parse(atob(item.URL_RECORD))
            url = constant.companyUserComponent.jobApplications.url.replace(':job_id', data.id).replace(':page_number', 1)

        }
        if (item.TYPE === 'LEAD_UPDATE' && item.URL_RECORD) {
            const data = JSON.parse(atob(item.URL_RECORD))
            url = constant.companyUserComponent.candidateProfilePreviewAndApplyJob.url.replace(':id', data.id).replace(':user_name', data.name)

        }
    }
    return (
        <div className="text-reset notification-item d-block dropdown-item" key={item.ID}>
            <div className={`${styles.notificationInnerBox} d-flex`}>
                <div className={styles.notifIconbox}>
                   <img src='/assets/images/click-icon.png' alt='notification Icon' />
                </div> 
                <div className="flex-grow-1">
                    <a href={url ? url : "javascript:void(0);"} className="stretched-link">
                        <h6 className={`${styles.notiHead} mt-0 mb-1`}>{item.TITLE}</h6>
                    </a>
                    {item.DESCRIPTION && (
                        <div className={` ${styles.msgPara} text-muted`}>
                            <p className="mb-1">{ReactHtmlParser(nl2br(item.DESCRIPTION))}</p>
                        </div>
                    )}
                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span>
                            <i className="mdi mdi-clock-outline" /> {moment(item.CREATED_ON).fromNow()}
                        </span>
                    </p>
                </div>
                <div className="px-2 fs-15">
                    <span style={{ cursor: 'pointer' }} onClick={() => deleteNotification(item.ID)}> <i class="mdi mdi-close fs-20"></i> </span>
                </div>
            </div>
        </div >
    );
};


const NotificationBadge = () => {
    const [allNotifications, setAllNotifications] = useState([]);
    const [jobUpdateNotifications, setJobUpdateNotifications] = useState([]);
    const [atsUpdateNotifications, setAtsUpdateNotifications] = useState([]);
    const [leadUpdateNotifications, setLeadUpdateNotifications] = useState([]);



    const fetchNotifications = () => {
        v3_notifications().then((res) => {
            if (res.status) {
                const allNotifs = res.result;
                console.log(allNotifs)
                setAllNotifications(allNotifs);
                setJobUpdateNotifications(allNotifs.filter((item) => item.TYPE === 'JOB_UPDATE'));
                setAtsUpdateNotifications(allNotifs.filter((item) => item.TYPE === 'ATS_UPDATE'));
                setLeadUpdateNotifications(allNotifs.filter((item) => item.TYPE === 'LEAD_UPDATE'));

            }
        });
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const deleteNotification = (id) => {
        v3_delete_notifications(id).then((res) => {
            if (res.status) {
                fetchNotifications();
            }
        });
    };

    return (
        <React.Fragment>
            <div className="dropdown topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
                <button
                    type="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                    id="page-header-notifications-dropdown"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <i className="bx bx-bell fs-22" />
                    <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                        {allNotifications.length}
                        <span className="visually-hidden">unread messages</span>
                    </span>
                </button>

                <div className={`${styles.notificationModalBox} dropdown-menu dropdown-menu-xl dropdown-menu-end p-0`} aria-labelledby="page-header-notifications-dropdown">
                    <div className={` ${styles.notificationheadrBox} dropdown-head`}>
                        {/* <div className="p-3">
                            <div className="row align-items-center">
                                <div className="col">
                                    <h6 className="m-0 fs-16 fw-semibold">Notifications</h6>
                                </div>
                                <div className="col-auto dropdown-tabs">
                                    <span className={`${styles.badgeSpan} badge   fs-13`}>{allNotifications.length} New</span>
                                </div>
                            </div>
                        </div> */}
                        <div className="px-2 pt-2 pb-2">
                            <ul className="nav nav-tabs dropdown-tabs nav-tabs-custom d-flex flex-row" data-dropdown-tabs="true" id="notificationItemsTab" role="tablist">
                                <li className="nav-item waves-effect waves-light">
                                    <a className= {`${styles.navItemLink} nav-link active`} data-bs-toggle="tab" href="#all-noti-tab" role="tab" aria-selected="true">
                                        All ({allNotifications.length})
                                    </a>
                                </li>
                                <li className= "nav-item waves-effect waves-light">
                                    <a className={`${styles.navItemLink} nav-link`} data-bs-toggle="tab" href="#lead-updates-tab" role="tab" aria-selected="false">
                                        Lead Updates ({leadUpdateNotifications.length})
                                    </a>
                                </li>
                                <li className="nav-item waves-effect waves-light">
                                    <a className={`${styles.navItemLink} nav-link`} data-bs-toggle="tab" href="#job-updates-tab" role="tab" aria-selected="false">
                                        Job Updates ({jobUpdateNotifications.length})
                                    </a>
                                </li>
                                <li className="nav-item waves-effect waves-light">
                                    <a className={`${styles.navItemLink} nav-link`} data-bs-toggle="tab" href="#ats-updates-tab" role="tab" aria-selected="false">
                                        ATS Updates ({atsUpdateNotifications.length})
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab-content position-relative" id="notificationItemsTabContent" style={{ maxHeight: 'calc(100vh - 150px)', overflowY: 'auto' }}>
                        <div className="tab-pane fade show active py-2 " id="all-noti-tab" role="tabpanel">
                            <div className="pe-2">
                                {allNotifications.length > 0 ? (
                                    allNotifications.map((item) => (
                                        <NotificationItem key={item.ID} item={item} deleteNotification={deleteNotification} />
                                    ))
                                ) : (
                                    'No New Notifications'
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade py-2 " id="lead-updates-tab" role="tabpanel">
                            <div className="pe-2">
                                {leadUpdateNotifications.length > 0 ? (
                                    leadUpdateNotifications.map((item) => (
                                        <NotificationItem key={item.ID} item={item} deleteNotification={deleteNotification} />
                                    ))
                                ) : (
                                    'No New Notifications'
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade py-2 " id="job-updates-tab" role="tabpanel">
                            <div className="pe-2">
                                {jobUpdateNotifications.length > 0 ? (
                                    jobUpdateNotifications.map((item) => (
                                        <NotificationItem key={item.ID} item={item} deleteNotification={deleteNotification} />
                                    ))
                                ) : (
                                    'No New Notifications'
                                )}
                            </div>
                        </div>
                        <div className="tab-pane fade py-2 " id="ats-updates-tab" role="tabpanel">
                            <div className="pe-2">
                                {atsUpdateNotifications.length > 0 ? (
                                    atsUpdateNotifications.map((item) => (
                                        <NotificationItem key={item.ID} item={item} deleteNotification={deleteNotification} />
                                    ))
                                ) : (
                                    'No New Notifications'
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

export default NotificationBadge;
