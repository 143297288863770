import { Route, Switch } from "react-router-dom";
import React, { useState } from 'react';
import constant from "./constant";
import { getStorage } from "./utils";
import FourZeroFour from "./components/common/404";
import Login from './pages/Auth/login'
import AuthMiddleWare from './pages/Auth/middleware.jsx'
import Landingpage from "./pages/nonAuthed/LandingPage/index.jsx";

import ForgetPassword from "./components/Auth/forgetPassword";
import RequestDemo from "./pages/nonAuthed/requestDemo";
import RequestDemoResponse from "./pages/nonAuthed/requestDemoResponse";

import NonAuthedHeader from './components/nonAuthed/common/Header/index.jsx'
import NonAuthedFooter from './components/nonAuthed/common/Footer/index.jsx'
import WalkInOnboarding from "./pages/nonAuthed/walkinOnbaording.jsx";





// Super Admin Components

import SuperAdminHeader from './components/SuperAdmin/common/header';
import SuperAdminFooter from './components/SuperAdmin/common/footer';
import SuperAdminSidebar from './components/SuperAdmin/common/sidebar';
import SuperAdminDashboard from './pages/superAdmin/dashboard.jsx';

import SuperAdminAddClient from './pages/superAdmin/client/add';
import SuperAdminListClient from './pages/superAdmin/client/list';
import SuperAdminClientDetail from './pages/superAdmin/client/clientDetail.jsx';
import SuperAdminPreviewInvoice from './pages/superAdmin/manage_subscription/previewInvoice.jsx';



import SuperAdminClientLead from './pages/superAdmin/client/demoRequestLeads';

import SuperAdminListOfferLetter from './pages/superAdmin/offer_letter/list.js';
import SuperAdminCreateOfferLetter from './pages/superAdmin/offer_letter/create.js';


// import SuperAdminSelectClientPackage from './pages/superAdmin/manage_subscription/selectPackages';






// Super Admin Components


// Company Admin Components

import CompanyAdminHeader from './components/CompanyAdmin/common/header';
import CompanyAdminFooter from './components/CompanyAdmin/common/footer';
import CompanyAdminSidebar from './components/CompanyAdmin/common/sidebar';
import CompanyAdminDashboard from './pages/companyAdmin/dashboard/index.jsx';
import CompanyAdminAddUsers from './pages/companyAdmin/userManagement/addUser';
import CompanyAdminListUsers from './pages/companyAdmin/userManagement/listuser';
import CompanyAdminAddClient from './pages/companyAdmin/clientManagement/add';
import CompanyAdminViewClient from './pages/companyAdmin/clientManagement/list';
import CompanyAdminTeamMapping from './pages/companyAdmin/userManagement/teamMapping';
import CompanyAdminDesignationList from './pages/companyAdmin/settings/designation';
import CompanyAdminWorkLocationList from './pages/companyAdmin/settings/workLocation';
import CompanyAdminRoleList from './pages/companyAdmin/settings/role';
import CompanyAdminDocumentTypeList from './pages/companyAdmin/settings/documentType';
import CompanyAdminProfile from './pages/companyAdmin/profile/preview.jsx';










// Company Admin Components

// Company User Components

import CompanyUserHeader from './components/companyUser/common/Header';
import CompanyUserFooter from './components/companyUser/common/Footer';
import CompanyUserSidebar from './components/companyUser/common/Sidebar';
import CompanyUserDashboard from './pages/companyUser/dashboard';
import CompanyUserPostJob from './pages/companyUser/jobs/postJob';
import CompanyUserUpdateJob from './pages/companyUser/jobs/updateJob';
import CompanyUserNonPostedJobs from './pages/companyUser/jobs/nonPostedJobList';
import CompanyUserPostedJobs from './pages/companyUser/jobs/liveJobList';
import CompanyUserExpiredJobs from './pages/companyUser/jobs/expiredJobList';

import CompanyUserJobApplications from './pages/companyUser/jobApplications/list';
import CompanyUserAddCandidate from './pages/companyUser/candidates/add';
import CompanyUserAddBulkCandidate from './pages/companyUser/candidates/bulkAdd.jsx';
import CompanyUserListCandidate from './pages/companyUser/candidates/list';
import CompanyUserListQrWalkInCandidate from './pages/companyUser/candidates/qrWalkInCandidates.jsx';
import CompanyUserListCallingDirectory from './pages/companyUser/candidates/callingDirectory.jsx';


import CompanyUserProfilePreview from './pages/companyUser/profile/preview';
import CompanyUserCandidateProfilePreviewAndJobApply from './pages/companyUser/candidates/profilePreviewAndJobApply';
import CompanyUserCandidateMessageTemplates from './pages/companyUser/candidates/messageTemplatesList';
import CompanyUserOrgChart from './pages/companyUser/administrator/orgChart/index.jsx';



// Team Options
import CompanyUserTeamInsight from './pages/companyUser/team/teamInsights';
import CompanyUserStatsDashboard from './pages/companyUser/team/dashboard';

// Team Options


// Administrator Options
import CompanyUserAddCRMUser from './pages/companyUser/administrator/CRMUser/addCRMUser';
import CompanyUserEditCRMUser from './pages/companyUser/administrator/CRMUser/EditCRMUserDetailsPage'

import CompanyUserAddClient from './pages/companyUser/administrator/clientManagement/add'
import CompanyUserClientList from './pages/companyUser/administrator/clientManagement/list'
import CompanyUserClientProfile from './pages/companyUser/administrator/clientManagement/profile'

// import CompanyUserTeamMapping from './pages/companyUser/administrator/CRMUser/teamMapping';
import CompanyUserListCrmUser from './pages/companyUser/administrator/CRMUser/listCRMUser';

import CompanyUserOverAllStats from './pages/companyUser/administrator/dashboard/index'
import CompanyUserShortlistedCandidates from './pages/companyUser/statusWiseCandidateList/user/shortlisted.jsx'
import CompanyUserDroppedCandidates from './pages/companyUser/statusWiseCandidateList/user/dropped.jsx'
import CompanyUserInterviewCandidates from './pages/companyUser/statusWiseCandidateList/user/interview.jsx'
import CompanyUserJoinedCandidates from './pages/companyUser/statusWiseCandidateList/user/joined.jsx'
import CompanyUserOfferedCandidates from './pages/companyUser/statusWiseCandidateList/user/offered.jsx'
import CompanyUserRejectedCandidates from './pages/companyUser/statusWiseCandidateList/user/rejected.jsx'
import CompanyUserClientShortlistedCandidates from './pages/companyUser/statusWiseCandidateList/user/client_shortliested.jsx'
import CompanyUserSelectedCandidates from './pages/companyUser/statusWiseCandidateList/user/selected.jsx'
import CompanyUserBillingCandidates from './pages/companyUser/statusWiseCandidateList/user/billing_candidates.jsx'


import CompanyUserCompanyShortlistedCandidates from './pages/companyUser/statusWiseCandidateList/company/shortlisted.jsx'
import CompanyUserCompanyDroppedCandidates from './pages/companyUser/statusWiseCandidateList/company/dropped.jsx'
import CompanyUserCompanyInterviewCandidates from './pages/companyUser/statusWiseCandidateList/company/interview.jsx'
import CompanyUserCompanyJoinedCandidates from './pages/companyUser/statusWiseCandidateList/company/joined.jsx'
import CompanyUserCompanyOfferedCandidates from './pages/companyUser/statusWiseCandidateList/company/offered.jsx'
import CompanyUserCompanyRejectedCandidates from './pages/companyUser/statusWiseCandidateList/company/rejected.jsx'
import CompanyUserCompanyClientShortlistedCandidates from './pages/companyUser/statusWiseCandidateList/company/client_shortliested.jsx'
import CompanyUserCompanySelectedCandidates from './pages/companyUser/statusWiseCandidateList/company/selected.jsx'
import CompanyUserCompanyBillingCandidates from './pages/companyUser/statusWiseCandidateList/company/billing_candidates.jsx'


import CompanyUserTeamShortlistedCandidates from './pages/companyUser/statusWiseCandidateList/team/shortlisted.jsx'
import CompanyUserTeamDroppedCandidates from './pages/companyUser/statusWiseCandidateList/team/dropped.jsx'
import CompanyUserTeamInterviewCandidates from './pages/companyUser/statusWiseCandidateList/team/interview.jsx'
import CompanyUserTeamJoinedCandidates from './pages/companyUser/statusWiseCandidateList/team/joined.jsx'
import CompanyUserTeamOfferedCandidates from './pages/companyUser/statusWiseCandidateList/team/offered.jsx'
import CompanyUserTeamRejectedCandidates from './pages/companyUser/statusWiseCandidateList/team/rejected.jsx'
import CompanyUserTeamClientShortlistedCandidates from './pages/companyUser/statusWiseCandidateList/team/client_shortliested.jsx'
import CompanyUserTeamSelectedCandidates from './pages/companyUser/statusWiseCandidateList/team/selected.jsx'
import CompanyUserTeamBillingCandidates from './pages/companyUser/statusWiseCandidateList/team/billing_candidates.jsx'

import CompanyUserJobApplicationReport from './pages/companyUser/jobApplications/jobApplicationReport.jsx'
import CompanyUserCandidateHiringStatusList from './pages/companyUser/candidates/hiringStatus.jsx'


// Administrator Options















// Company User Components


const Router = () => {
    return (
        <React.Fragment>
            <Switch>
                <NonAuthedRoute exact={true} hideHeader={true} hideFooter={true} path={constant.nonAuthedcomponent.login.url} component={Login} />
                <NonAuthedRoute exact={true} path={constant.nonAuthedcomponent.auth_middleware.url} component={AuthMiddleWare} />

                <NonAuthedRoute exact={true} path={constant.nonAuthedcomponent.landingPage.url} component={Landingpage} />
                <NonAuthedRoute exact={true} path={constant.nonAuthedcomponent.forgetPassword.url} component={ForgetPassword} />
                <NonAuthedRoute exact={true} path={constant.nonAuthedcomponent.requestDemo.url} component={RequestDemo} />
                <NonAuthedRoute exact={true} path={constant.nonAuthedcomponent.requestDemoStatus.url} component={RequestDemoResponse} />
                <NonAuthedRoute exact={true} path={constant.nonAuthedcomponent.walkInOnboarding.url} component={WalkInOnboarding} />



                <SuperAdminRoute exact={true} path={constant.superAdminComponent.dashBoard.url} component={SuperAdminDashboard} />
                <SuperAdminRoute exact={true} path={constant.superAdminComponent.addClient.url} component={SuperAdminAddClient} />
                <SuperAdminRoute exact={true} path={constant.superAdminComponent.listClient.url} component={SuperAdminListClient} />
                <SuperAdminRoute exact={true} path={constant.superAdminComponent.clientDetail.url} component={SuperAdminClientDetail} />
                <SuperAdminRoute exact={true} path={constant.superAdminComponent.clientSubscriptionInvoice.url} component={SuperAdminPreviewInvoice} />


                <SuperAdminRoute exact={true} path={constant.superAdminComponent.clientLead.url} component={SuperAdminClientLead} />
                <SuperAdminRoute exact={true} path={constant.superAdminComponent.createOfferLetter.url} component={SuperAdminCreateOfferLetter} />
                <SuperAdminRoute exact={true} path={constant.superAdminComponent.offerLetter.url} component={SuperAdminListOfferLetter} />

                {/* <SuperAdminRoute exact={true} path={constant.superAdminComponent.selectClientPackage.url} component={SuperAdminSelectClientPackage} /> */}



                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.dashBoard.url} component={CompanyAdminDashboard} />
                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.addUser.url} component={CompanyAdminAddUsers} />
                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.viewUsers.url} component={CompanyAdminListUsers} />
                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.addClient.url} component={CompanyAdminAddClient} />
                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.viewClients.url} component={CompanyAdminViewClient} />
                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.teamMapping.url} component={CompanyAdminTeamMapping} />
                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.profilePreview.url} component={CompanyAdminProfile} />

                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.designations.url} component={CompanyAdminDesignationList} />
                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.workLocation.url} component={CompanyAdminWorkLocationList} />
                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.role.url} component={CompanyAdminRoleList} />
                <CompanyAdminRoute exact={true} path={constant.comapnyAdminComponent.documentType.url} component={CompanyAdminDocumentTypeList} />




                <NewCompanyUserRoutes exact={true} path={constant.companyUserComponent.dashBoard.url} component={CompanyUserDashboard} />
                <NewCompanyUserRoutes exact={true} path={constant.companyUserComponent.postJob.url} component={CompanyUserPostJob} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.updateJob.url} component={CompanyUserUpdateJob} />
                <NewCompanyUserRoutes exact={true} path={constant.companyUserComponent.nonPostedJobs.url} component={CompanyUserNonPostedJobs} />
                <NewCompanyUserRoutes exact={true} path={constant.companyUserComponent.postedJobs.url} component={CompanyUserPostedJobs} />
                <NewCompanyUserRoutes exact={true} path={constant.companyUserComponent.expiredJobs.url} component={CompanyUserExpiredJobs} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.jobApplications.url} component={CompanyUserJobApplications} />
                <NewCompanyUserRoutes exact={true} path={constant.companyUserComponent.addCandidate.url} component={CompanyUserAddCandidate} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.addBulkCandidate.url} component={CompanyUserAddBulkCandidate} />


                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.listCandidate.url} component={CompanyUserListCandidate} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.QrWalkInCandidates.url} component={CompanyUserListQrWalkInCandidate} />

                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.callingDirectory.url} component={CompanyUserListCallingDirectory} />


                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.profilePreview.url} component={CompanyUserProfilePreview} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.candidateProfilePreviewAndApplyJob.url} component={CompanyUserCandidateProfilePreviewAndJobApply} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.messageTemplates.url} component={CompanyUserCandidateMessageTemplates} />
                <NewCompanyUserRoutes exact={true} path={constant.companyUserComponent.orgChart.url} component={CompanyUserOrgChart} />

                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.teamInsights.url} component={CompanyUserTeamInsight} />
                <NewCompanyUserRoutes exact={true} path={constant.companyUserComponent.teamStats.url} component={CompanyUserStatsDashboard} />

                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.addUser.url} component={CompanyUserAddCRMUser} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.clientProfile.url} component={CompanyUserClientProfile} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.viewUsers.url} component={CompanyUserListCrmUser} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.editUser.url} component={CompanyUserEditCRMUser} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.addClient.url} component={CompanyUserAddClient} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.viewClients.url} component={CompanyUserClientList} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.overAllStats.url} component={CompanyUserOverAllStats} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.shortlisted.url} component={CompanyUserShortlistedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.dropped.url} component={CompanyUserDroppedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.interview.url} component={CompanyUserInterviewCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.joined.url} component={CompanyUserJoinedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.offered.url} component={CompanyUserOfferedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.clientShortlisted.url} component={CompanyUserClientShortlistedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.rejected.url} component={CompanyUserRejectedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.selected.url} component={CompanyUserSelectedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.billing.url} component={CompanyUserBillingCandidates} />


                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.team_shortlisted.url} component={CompanyUserTeamShortlistedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.team_dropped.url} component={CompanyUserTeamDroppedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.team_interview.url} component={CompanyUserTeamInterviewCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.team_joined.url} component={CompanyUserTeamJoinedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.team_offered.url} component={CompanyUserTeamOfferedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.team_client_shortlisted.url} component={CompanyUserTeamClientShortlistedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.team_rejected.url} component={CompanyUserTeamRejectedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.team_selected.url} component={CompanyUserTeamSelectedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.team_billing.url} component={CompanyUserTeamBillingCandidates} />


                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.company_shortlisted.url} component={CompanyUserCompanyShortlistedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.company_dropped.url} component={CompanyUserCompanyDroppedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.company_interview.url} component={CompanyUserCompanyInterviewCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.company_joined.url} component={CompanyUserCompanyJoinedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.company_offered.url} component={CompanyUserCompanyOfferedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.company_client_shortlisted.url} component={CompanyUserCompanyClientShortlistedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.company_rejected.url} component={CompanyUserCompanyRejectedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.company_selected.url} component={CompanyUserCompanySelectedCandidates} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.company_billing.url} component={CompanyUserCompanyBillingCandidates} />


                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.JobApplicationReport.url} component={CompanyUserJobApplicationReport} />
                <CompanyUserRoutes exact={true} path={constant.companyUserComponent.CandidateHiringStatus.url} component={CompanyUserCandidateHiringStatusList} />


                <Route component={FourZeroFour} />
            </Switch>

        </React.Fragment>

    )
}


export default Router;

const SuperAdminRoute = (props) => {
    const ut = getStorage(constant.keys.superAdminToken);
    if (ut) {
        const urlKeys = Object.entries(constant.superAdminComponent);
        const path = props.path;
        const match = urlKeys.filter((i) => i[1].url === path);
        if (match.length > 0 && match[0][1].url === path) {
            return (
                <React.Fragment>
                    <div id="layout-wrapper">
                        <SuperAdminHeader />
                        <SuperAdminSidebar />
                        <Route exact={true} path={props.path} component={props.component} />
                        <SuperAdminFooter />
                    </div>

                </React.Fragment>
            )
        }
        else {
            window.location.href = constant.commoncomponent.fourZeroFour.url
        }
    }
    else {
        window.location.href = constant.nonAuthedcomponent.login.url.replace(":user_type", 'su')
    }


}


const CompanyAdminRoute = (props) => {
    const ut = getStorage(constant.keys.companyAdminToken);
    if (ut) {
        const urlKeys = Object.entries(constant.comapnyAdminComponent);
        const path = props.path;
        const match = urlKeys.filter((i) => i[1].url === path);
        if (match.length > 0 && match[0][1].url === path) {
            return (
                <React.Fragment>
                    <div id="layout-wrapper">
                        <CompanyAdminHeader />
                        <CompanyAdminSidebar />
                        <Route exact={true} path={props.path} component={props.component} />
                        <CompanyAdminFooter />
                    </div>

                </React.Fragment>
            )
        }
        else {
            window.location.href = constant.commoncomponent.fourZeroFour.url
        }
    }
    else {
        window.location.href = constant.nonAuthedcomponent.login.url.replace(":user_type", 'company')
    }


}


const CompanyUserRoutes = (props) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);


    const ut = getStorage(constant.keys.companyUserToken);
    if (ut) {
        const urlKeys = Object.entries(constant.companyUserComponent);
        const path = props.path;
        const match = urlKeys.filter((i) => i[1].url === path);
        if (match.length > 0 && match[0][1].url === path) {
            return (
                <React.Fragment>
                    <div id="layout-wrapper">
                        <CompanyUserHeader
                            isSidebarCollapsed={isSidebarCollapsed}
                            setIsSidebarCollapsed={setIsSidebarCollapsed}

                        />
                        <CompanyUserSidebar />

                        <Route exact={true} path={props.path} component={props.component} />
                        <CompanyUserFooter />
                    </div>

                </React.Fragment>
            )
        }
        else {
            window.location.href = constant.commoncomponent.fourZeroFour.url
        }
    }
    else {
        window.location.href = constant.nonAuthedcomponent.login.url.replace(":user_type", 'user')
    }


}


const NewCompanyUserRoutes = (props) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

    const ut = getStorage(constant.keys.companyUserToken);
    if (ut) {
        const urlKeys = Object.entries(constant.companyUserComponent);
        const path = props.path;
        const match = urlKeys.filter((i) => i[1].url === path);
        if (match.length > 0 && match[0][1].url === path) {
            return (
                <React.Fragment>
                    <CompanyUserHeader
                        isSidebarCollapsed={isSidebarCollapsed}
                        setIsSidebarCollapsed={setIsSidebarCollapsed}
                    />
                    {isSidebarCollapsed && <CompanyUserSidebar />}
                    {isSidebarCollapsed ? <div className="main-content">
                        <div className="page-content">
                            <Route exact={true} path={props.path} component={props.component} />

                        </div>
                    </div> :
                        <div className="page-content">
                            <Route exact={true} path={props.path} component={props.component} />

                        </div>

                    }
                            <CompanyUserFooter />


                </React.Fragment>
            )
        }
        else {
            window.location.href = constant.commoncomponent.fourZeroFour.url
        }
    }
    else {
        window.location.href = constant.nonAuthedcomponent.login.url.replace(":user_type", 'user')
    }


}


const NonAuthedRoute = (props) => {
    const sat = getStorage(constant.keys.superAdminToken);
    const cat = getStorage(constant.keys.companyAdminToken);
    const cut = getStorage(constant.keys.companyUserToken);

    if (!sat && !cat && !cut) {
        const urlKeys = Object.entries(constant.nonAuthedcomponent);
        const path = props.path;
        const match = urlKeys.filter((i) => i[1].url === path);
        if (match.length > 0 && match[0][1].url === path) {
            return (
                <React.Fragment>
                    <div className="layout-wrapper landing">
                        {!props.hideHeader && <NonAuthedHeader />}
                        <Route exact={true} path={props.path} component={props.component} />
                        {!props.hideFooter && <NonAuthedFooter />}
                    </div>
                </React.Fragment>
            )
        }
        else {
            window.location.href = constant.nonAuthedcomponent.landingPage.url

        }
    }
    else if (sat) {
        window.location.href = constant.superAdminComponent.dashBoard.url
    }
    else if (cat) {
        window.location.href = constant.comapnyAdminComponent.dashBoard.url
    }
    else if (cut) {
        window.location.href = constant.companyUserComponent.dashBoard.url
    }
    else {
        alert('Something Went Wrong!!')
    }

}