import React, { useState } from 'react'
import StepOne from '../../../components/companyUser/jobs/postJob/step_one'
import StepTwo from '../../../components/companyUser/jobs/postJob/step_two'
import StepThree from '../../../components/companyUser/jobs/postJob/step_three'
import StepFour from '../../../components/companyUser/jobs/postJob/step_four'
import { ToastContainer, toast } from 'react-toastify';
import styles from './postjob.module.css';
import BreadCrumb from '../../../components/common/BreadCrumb'

import { v3_edit_form_details, v3_post_job, v3_update_step_one_job, v3_update_step_three_job, v3_update_step_two_job } from '../../../actions/v3/jobs'
import constant from '../../../constant'
const UpdateJob = (props) => {
    const [currentStep, setCurrentStep] = useState(Number(props.match.params.step));
    const [StepOneFormData, setStepOneFormData] = useState(null);
    const [StepTwoFormData, setStepTwoFormData] = useState(null);
    const [StepThreeFormData, setStepThreeFormData] = useState(null);

    const onSubmitStepTwo = (stepTwoData) => {
        v3_update_step_two_job({ ...stepTwoData, JOB_ID: props.match.params.job_id }).then((res) => {
            if (res.status) {
                setCurrentStep(3);
                toast.success('Saved')

                props.history.push(constant.companyUserComponent.updateJob.url.replace(':step', 3).replace(':job_id', props.match.params.job_id))
            }
        })
    }

    const onSubmitStepOne = (stepTwoOne) => {
        v3_update_step_one_job({ ...stepTwoOne, JOB_ID: props.match.params.job_id }).then((res) => {
            if (res.status) {
                setCurrentStep(2);
                toast.success('Saved')

                props.history.push(constant.companyUserComponent.updateJob.url.replace(':step', 2).replace(':job_id', props.match.params.job_id))
            }
        })
    }

    const onSubmitStepThree = (stepThreeData) => {
        v3_update_step_three_job({ ...stepThreeData, JOB_ID: props.match.params.job_id }).then((res) => {
            if (res.status) {
                setCurrentStep(4);
                toast.success('Saved')

                props.history.push(constant.companyUserComponent.updateJob.url.replace(':step', 4).replace(':job_id', props.match.params.job_id))
            }
        })
    }
    const onPostJob = () => {
        debugger
        v3_post_job(props.match.params.job_id).then((res) => {
            if (res.status) {
                setCurrentStep(5);
                props.history.push(constant.companyUserComponent.updateJob.url.replace(':step', 5).replace(':job_id', props.match.params.job_id))
            }
        })
    }

    useState(() => {
        v3_edit_form_details(props.match.params.job_id).then((res => {
            if (res.status) {
                setStepOneFormData({
                    CLIENT_NAME: res.result.job_detail.CLIENT_NAME,
                    CLIENT_DETAILS: res.result.job_detail.CLIENT_DETAILS,
                    CLIENT_ADDRESS: res.result.job_detail.CLIENT_ADDRESS,
                    COMPANY_NAME: res.result.job_detail.COMPANY_NAME,
                    CLIENT_ID: res.result.job_detail.CLIENT_ID,
                })

                setStepTwoFormData({
                    JOB_TITLE: res.result.job_detail.JOB_TITLE,
                    EMPLOYMENT_TYPE: res.result.job_detail.EMPLOYMENT_TYPE_ID,
                    WORK_EXP_MIN: res.result.job_detail.WORK_EXP_MIN,
                    WORK_EXP_MAX: res.result.job_detail.WORK_EXP_MAX,
                    SALARY_CURRENCY: res.result.job_detail.SALARY_CURRENCY,
                    SALARY_MIN: res.result.job_detail.SALARY_MIN,
                    SALARY_MAX: res.result.job_detail.SALARY_MAX,
                    JOB_DETAILS: res.result.job_detail.JOB_DETAILS,
                    JOB_QUESTIONS: res.result.job_question,
                    INCLUDE_WALK_IN_DETAILS: res.result.job_detail.IS_INCLUDE_WALK_IN,
                    WALK_IN_START_DATE: res.result.job_detail.WALK_IN_START_DATE,
                    WALK_IN_START_TIME: res.result.job_detail.WALK_IN_START_TIME,
                    WALK_IN_END_TIME: res.result.job_detail.WALK_IN_END_TIME,
                    WALK_IN_CONTACT_PERSON: res.result.job_detail.WALK_IN_CONTACT_PERSON,
                    WALK_IN_CONTACT_NUMBER: res.result.job_detail.WALK_IN_CONTACT_NUMBER,
                    WALK_IN_VENUE: res.result.job_detail.WALK_IN_VENUE,
                    GOOGLE_MAP_URL: res.result.job_detail.GOOGLE_MAP_URL,
                    WALK_IN_DURATION: res.result.job_detail.WALK_IN_DURATION,
                })

                setStepThreeFormData({
                    IS_WORK_FROM_HOME_JOB: res.result.job_detail.IS_WORK_FROM_HOME,
                    WORK_FROM_TYPE: res.result.job_detail.TYPE_OF_WORK_FROM_HOME,
                    OFFICE_LOCATION: res.result.job_detail.OFFICE_LOCATION,
                    SKILLS: res.result.job_detail.KEYWORDS,
                    INDUSTRY: res.result.job_detail.INDUSTRY_ID,
                    FUNCTIONAL_AREA: res.result.job_detail.FUNCTIONAL_AREA_ID,
                    ROLE: res.result.job_detail.ROLE_ID,
                    NO_OF_VACANCY: res.result.job_detail.NO_OF_VACANCIES,
                    QUALIFICATION: res.result.qualificationDetails,
                    EDUCATION_QUALIFICATION: res.result.educationQualificationDetails,
                    COURSE_SPECIALIZATION: res.result.specializationDetails,
                    DATE_OF_EXPIRY: res.result.job_detail.DATE_OF_EXPIRY
                })

            }
            else {
                console.error(res.error)
            }
        }))
    }, [])
    return (
        <React.Fragment>
            <ToastContainer />


            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <BreadCrumb
                            page={'Update Job'}
                            parentSection={<ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <a href="javascript: void(0);">Job Management</a>
                                </li>
                                <li className="breadcrumb-item active">Non Posted Job</li>
                            </ol>}
                        />
                        {currentStep < 5 ?
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className={`${styles.cardPostJobBox} card`}>
                                        <div className="card-body">
                                            <div className="form-steps" >
                                                {/* <div className="pt-3 pb-4 mb-1">
                                                    <h4 className={styles.innerTitlebox}>Post Job</h4>
                                                </div> */}
                                                <div id="custom-progress-bar" className="progress-nav mb-4">
                                                    <div className="progress" style={{ height: 1 }}>
                                                        <div
                                                            className="progress-bar"
                                                            role="progressbar"
                                                            style={{ width: "0%" }}
                                                            aria-valuenow={0}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                    <ul
                                                        className="nav nav-pills progress-bar-tab custom-nav"
                                                        role="tablist"
                                                    >
                                                        <li className={`${styles.navItemli} nav-item`} >
                                                            <button
                                                                className={currentStep === 1 ? "nav-link rounded-pill active" : "nav-link rounded-pill"}
                                                            >
                                                                1
                                                            </button><span className={styles.navText}>Client Details</span>
                                                        </li>
                                                        <li className={`${styles.navItemli} nav-item`} >
                                                            <span className={styles.navText}></span>
                                                            <button
                                                                className={currentStep === 2 ? "nav-link rounded-pill active" : "nav-link rounded-pill"}

                                                            >
                                                                2
                                                            </button><span className={styles.navText}>Job Details</span>
                                                        </li>
                                                        <li className={`${styles.navItemli} nav-item`} >
                                                            <span className={styles.navText}></span>
                                                            <button
                                                                className={currentStep === 3 ? "nav-link rounded-pill active" : "nav-link rounded-pill"}

                                                            >
                                                                3
                                                            </button><span className={styles.navText}>Other Details</span>
                                                        </li>

                                                        <li className={`${styles.navItemli} nav-item`} >
                                                            <span className={styles.navText}></span>
                                                            <button
                                                                className={currentStep === 4 ? "nav-link rounded-pill active" : "nav-link rounded-pill"}

                                                                type="button"
                                                            >
                                                                4
                                                            </button><span className={styles.navText}>Preview</span>
                                                        </li>


                                                    </ul>
                                                </div>
                                                {currentStep === 1 && StepOneFormData && <StepOne
                                                    onSave={
                                                        (model) => {
                                                            setStepOneFormData(model);
                                                            onSubmitStepOne(model);
                                                        }
                                                    }
                                                    formData={StepOneFormData}
                                                />}
                                                {currentStep === 2 && StepOneFormData && <StepTwo
                                                    onPrevious={() => { setCurrentStep(1) }}
                                                    onSave={
                                                        (model) => {
                                                            setStepTwoFormData(model);
                                                            onSubmitStepTwo(model)
                                                        }
                                                    }
                                                    formData={StepTwoFormData}
                                                />
                                                }

                                                {currentStep === 3 && StepThreeFormData && <StepThree
                                                    onPrevious={() => { setCurrentStep(2) }}
                                                    onSave={
                                                        (model) => {
                                                            setStepThreeFormData(model);
                                                            onSubmitStepThree(model)
                                                        }
                                                    }
                                                    formData={StepThreeFormData}
                                                />
                                                }
                                                {currentStep === 4 && StepOneFormData && StepTwoFormData && StepThreeFormData && < StepFour
                                                    onPrevious={() => { setCurrentStep(3) }}
                                                    postJob={
                                                        () => {
                                                            onPostJob()
                                                        }
                                                    }
                                                    jobData={{ ...StepOneFormData, ...StepTwoFormData, ...StepThreeFormData }}
                                                />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="mt-3 text-center">
                                                <lord-icon
                                                    src="https://cdn.lordicon.com/lupuorrc.json"
                                                    trigger="loop"
                                                    colors="primary:#0ab39c,secondary:#405189"
                                                    style={{ width: 250, height: 250 }}
                                                />
                                                <div className="mt-4 pt-2 fs-22">
                                                    <h4>Job Posted !</h4>
                                                    <p className="text-muted mx-4 mb-0">
                                                        Aww yeah, you successfully posted job.
                                                    </p>
                                                </div>
                                                <div className=" align-items-start gap-3 mt-4">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary mx-3"
                                                        onClick={() => {
                                                            props.history.push(constant.companyUserComponent.postedJobs.url)
                                                        }}
                                                    >
                                                        {/* <i className="ri-arrow-left-line label-icon align-middle fs-16" /> */}

                                                        View Posted Jobs
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="btn btn-success mx-3"
                                                        onClick={() => {
                                                            props.history.push(constant.companyUserComponent.postJob.url)
                                                        }}
                                                    >
                                                        Post Next Job
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default UpdateJob