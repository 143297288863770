import React, { useEffect, useRef, useState } from 'react'
import { v1_add_education_qualification_option, v1_candidate_status_options, v1_course_specialization, v1_education_qualification, v1_qualification } from '../../../actions/v1/master_data'
import { validateFunctionalForm } from '../../../utils'
import QuickAdd from './quickAdd'
import Modal from '../../common/htmlModal'
import { v3_call_directory_assignee, v3_candidate_preferred_role_options, v3_qucik_add_candidate } from '../../../actions/v3/candidates'
import { toast } from 'react-toastify'
import { v3_all_client_options, v3_client_options } from '../../../actions/v3/jobApplication'
import { v3_client_wise_active_jobs } from '../../../actions/v3/jobs'
import { v4_resume_info } from '../../../actions/v4/resumeParser'
import Loader from '../../common/Loader/circleLoader'
import styles from './candidate.module.css'
import BreadCrumb from '../../../components/common/BreadCrumb'


const Add = (props) => {
    const [state, setState] = useState({
        FULL_NAME: { name: 'FULL_NAME', value: '', error: '', isRequired: true },
        EMAIL: { name: 'EMAIL', value: '', error: '', isRequired: false },
        PHONE: { name: 'PHONE', value: '', error: '', isRequired: true },
        WORKING_LOCATION: { name: 'WORKING_LOCATION', value: '', error: '', isRequired: true },
        RESIDENTIAL_LOCATION: { name: 'RESIDENTIAL_LOCATION', value: '', error: '', isRequired: true },
        CURRENT_COMPANY: { name: 'CURRENT_COMPANY', value: '', error: '', isRequired: true },
        CURRENT_CTC: { name: 'CURRENT_CTC', value: '', error: '', isRequired: true },
        EXPECTED_CTC: { name: 'EXPECTED_CTC', value: '', error: '', isRequired: true },
        SKILLS: { name: 'SKILLS', value: '', error: '', isRequired: true },
        TOTAL_EXP_YEAR: { name: 'TOTAL_EXP_YEAR', value: '', error: '', isRequired: true },
        TOTAL_EXP_MONTH: { name: 'TOTAL_EXP_MONTH', value: '', error: '', isRequired: true },
        HIGHEST_QUALIFICATION: { name: 'HIGHEST_QUALIFICATION', value: '', options: [], error: '', isRequired: true },
        COLLEGE_NAME: { name: 'COLLEGE_NAME', value: '', options: [], error: '', isRequired: false },
        PASS_OUT_YEAR: { name: 'PASS_OUT_YEAR', value: '', options: [], error: '', isRequired: false },
        COURSE: { name: 'COURSE', value: '', options: [], error: '', isRequired: true },
        SPECIALIZATION: { name: 'SPECIALIZATION', value: '', options: [], error: '', isRequired: false },
        NOTICE_PERIOD: { name: 'NOTICE_PERIOD', value: '', error: '', isRequired: false },
        SOURCE: { name: 'SOURCE', value: '', error: '', isRequired: true },
        RATING: { name: 'RATING', value: '', error: '', isRequired: false },
        NOTE: { name: 'NOTE', value: '', error: '', isRequired: false },
        RESUME: { name: 'RESUME', value: '', error: '', isRequired: false },
        CLIENT: { name: 'CLIENT', value: '', options: [], error: '', isRequired: false },
        JOBS: { name: 'JOBS', value: '', options: [], error: '', isRequired: false },
        STATUS: { name: 'STATUS', value: '', options: [], error: '', isRequired: true },
        CURRENT_DESIGNATION: { name: 'CURRENT_DESIGNATION', value: '', error: '', isRequired: false },
        LINED_UP_DATE: { name: 'LINED_UP_DATE', value: '', error: '', isRequired: false },
        LINED_UP_TIME: { name: 'LINED_UP_TIME', value: '', error: '', isRequired: false },
        ROLE_ID: { name: 'ROLE_ID', value: '', options: [], error: '', isRequired: false },
        CALLING_ASSIGNEE_ID: { name: 'CALLING_ASSIGNEE_ID', value: '', options: [], error: '', isRequired: false },
    })
    const [showLoader, setShowLoader] = useState(false)

    const [openQuickAdd, setOpenQuickAdd] = useState(false)
    const ratingChanged = (newRating) => {
        setState(prevState => ({
            ...prevState,
            ['RATING']: {
                ...prevState['RATING'],
                value: newRating
            }
        }));
    };
    useEffect(() => {
        if (state.STATUS.value == 3 || state.STATUS.value == 4 || state.STATUS.value == 7) {
            setState(prevState => ({
                ...prevState,
                ['CLIENT']: {
                    ...prevState['CLIENT'],
                    isRequired: true
                },
                ['JOBS']: {
                    ...prevState['JOBS'],
                    isRequired: true
                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['CLIENT']: {
                    ...prevState['CLIENT'],
                    isRequired: false,
                    value: ''
                },
                ['JOBS']: {
                    ...prevState['JOBS'],
                    isRequired: false,
                    value: ''

                }
            }));
        }

        if (state.STATUS.value == 5 || state.STATUS.value == 6 || state.STATUS.value == 7) {
            setState(prevState => ({
                ...prevState,
                ['LINED_UP_DATE']: {
                    ...prevState['LINED_UP_DATE'],
                    isRequired: true
                },
                ['LINED_UP_TIME']: {
                    ...prevState['LINED_UP_TIME'],
                    isRequired: true
                }
            }));
        }
        else {
            setState(prevState => ({
                ...prevState,
                ['LINED_UP_DATE']: {
                    ...prevState['LINED_UP_DATE'],
                    isRequired: false,
                    value: ''

                },
                ['LINED_UP_TIME']: {
                    ...prevState['LINED_UP_TIME'],
                    isRequired: false,
                    value: ''

                }
            }));
        }
    }, [state.STATUS.value])
    useEffect(() => {
        v1_qualification().then(res => {
            if (res.status) {
                setOptions('HIGHEST_QUALIFICATION', res.result);
            }
        })

        v3_all_client_options().then((res) => {
            if (res.status) {
                setOptions('CLIENT', res.result)
            }
        })
        v1_candidate_status_options().then((res) => {
            if (res.status) {
                setOptions('STATUS', res.result)

            }
            else {

            }
        })
        v3_candidate_preferred_role_options().then((res) => {
            if (res.status) {
                setOptions('ROLE_ID', res.result)

            }
            else {

            }
        })

        v3_call_directory_assignee().then((res) => {
            if (res.status) {
                setOptions('CALLING_ASSIGNEE_ID', res.result)

            }
            else {

            }
        })
    }, [])

    useEffect(() => {
        if (state.CLIENT.value) {
            v3_client_wise_active_jobs(state.CLIENT.value).then((res) => {
                if (res.status) {
                    setOptions('JOBS', res.result)

                }
            })
        }
        else {
            setOptions('JOBS', [])

        }
    }, [state.CLIENT.value])

    useEffect(() => {

        if (state.HIGHEST_QUALIFICATION.value) {
            v1_education_qualification(state.HIGHEST_QUALIFICATION.value).then(res => {
                if (res.status) {
                    setOptions('COURSE', res.result);
                    if (parsedData) {
                        const courseId = res.result.find(item =>
                            item.COURSE_STREAM.toLowerCase() === parsedData?.highest_education?.degree?.toLowerCase()
                        )?.EDUCATION_QUALIFICATION_ID || ''
                        if (courseId) {
                            setState(prevState => ({
                                ...prevState,
                                ['COURSE']: {
                                    ...prevState['COURSE'],
                                    value: courseId
                                },
                            }));
                        }
                        else {
                            v1_add_education_qualification_option({ qualificationId: state.HIGHEST_QUALIFICATION.value, courseStream: parsedData?.highest_education?.degree }).then((add_res) => {
                                if (add_res.status) {
                                    setOptions('COURSE', add_res.result.new_options)
                                    setState(prevState => ({
                                        ...prevState,
                                        ['COURSE']: {
                                            ...prevState['COURSE'],
                                            value: add_res.result.inserted_record
                                        },
                                    }));
                                }
                            })
                        }
                    }
                }
            })
        }
    }, [state.HIGHEST_QUALIFICATION.value])

    useEffect(() => {
        if (state.COURSE.value) {
            v1_course_specialization(state.COURSE.value).then(res => {
                if (res.status) {
                    setOptions('SPECIALIZATION', res.result);
                }
            })
        }
    }, [state.COURSE.value])

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    }

    const onChange = (e) => {
        const { name, value } = e.target;
        if (name === 'PHONE' && value.length > 10) {
            return false
        }
        if (name === state.RATING.name) {
            // Check if the value is greater than or equal to 0
            if (parseFloat(value) >= 0 || value == '') {
                setState(prevState => ({
                    ...prevState,
                    [name]: {
                        ...prevState[name],
                        value: value
                    }
                }));
            }
        }
        else {
            setState(prevState => ({
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value: value
                }
            }));
        }
        if (name === state.TOTAL_EXP_YEAR.name) {
            if (value !== 'Fresher') {
                setState(prevState => ({
                    ...prevState,
                    ['PASS_OUT_YEAR']: {
                        ...prevState['PASS_OUT_YEAR'],
                        value: '',
                        isRequired: false

                    },
                    ['COLLEGE_NAME']: {
                        ...prevState['COLLEGE_NAME'],
                        value: '',
                        isRequired: false

                    },
                    ['TOTAL_EXP_MONTH']: {
                        ...prevState['TOTAL_EXP_MONTH'],
                        isRequired: true
                    }

                }));
            }
            else {
                setState(prevState => ({
                    ...prevState,
                    ['PASS_OUT_YEAR']: {
                        ...prevState['PASS_OUT_YEAR'],
                        isRequired: true
                    },
                    ['COLLEGE_NAME']: {
                        ...prevState['COLLEGE_NAME'],
                        isRequired: true
                    },
                    ['TOTAL_EXP_MONTH']: {
                        ...prevState['TOTAL_EXP_MONTH'],
                        isRequired: false
                    }
                }));
            }

        }
    }

    const onSubmit = () => {
        if (validateFunctionalForm(state, setState)) {
            const model = {
                FULL_NAME: state.FULL_NAME.value,
                EMAIL: state.EMAIL.value,
                PHONE: state.PHONE.value,
                WORKING_LOCATION: state.WORKING_LOCATION.value,
                RESIDENTIAL_LOCATION: state.RESIDENTIAL_LOCATION.value,
                CURRENT_COMPANY: state.CURRENT_COMPANY.value,
                CURRENT_CTC: state.CURRENT_CTC.value,
                EXPECTED_CTC: state.EXPECTED_CTC.value,
                SKILLS: state.SKILLS.value,
                TOTAL_EXP_YEAR: state.TOTAL_EXP_YEAR.value,
                TOTAL_EXP_MONTH: state.TOTAL_EXP_MONTH.value,
                HIGHEST_QUALIFICATION: state.HIGHEST_QUALIFICATION.value,
                COURSE: state.COURSE.value,
                SPECIALIZATION: state.SPECIALIZATION.value,
                NOTICE_PERIOD: state.NOTICE_PERIOD.value,
                RESUME: state.RESUME.value,
                SOURCE: state.SOURCE.value,
                RATING: state.RATING.value,
                COLLEGE_NAME: state.COLLEGE_NAME.value,
                PASS_OUT_YEAR: state.PASS_OUT_YEAR.value,
                NOTE: state.NOTE.value,
                JOB_ID: state.JOBS.value,
                STATUS_ID: state.STATUS.value,
                CURRENT_DESIGNATION: state.CURRENT_DESIGNATION.value,
                CLIENT_ID: state.CLIENT.value,
                LINED_UP_DATE: state.LINED_UP_DATE.value,
                LINED_UP_TIME: state.LINED_UP_TIME.value,
                ROLE_ID: state.ROLE_ID.value,
                CALLING_ASSIGNEE_ID: state.CALLING_ASSIGNEE_ID.value





            }
            const formData = new FormData();
            for (const key in model) {
                if (model.hasOwnProperty(key)) {
                    formData.append(key, model[key]);
                }
            }

            props.onSubmit(formData)

        }

    }

    const onQuickAdd = (model) => {
        v3_qucik_add_candidate(model).then((res) => {
            if (res.status) {
                toast.success('Candidate Lead Added Successfully')
                setOpenQuickAdd(false)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const [parsedData, setParsedData] = useState(null)

    const onAutoFillWithResume = (file) => {
        const formData = new FormData();
        formData.append('resumeFile', file)
        setShowLoader(true);
        v4_resume_info(formData)
            .then((res) => {
                setShowLoader(false);

                // Check if response contains valid data
                if (res.extractedInfo) {
                    const extractedInfo = res.extractedInfo || {};

                    setParsedData(extractedInfo);

                    setState((prevState) => ({
                        ...prevState,
                        ['FULL_NAME']: {
                            ...prevState['FULL_NAME'],
                            value: extractedInfo.name || '' // Default to empty string if missing
                        },
                        ['EMAIL']: {
                            ...prevState['EMAIL'],
                            value: extractedInfo.email || '' // Default to empty string if missing
                        },
                        ['PHONE']: {
                            ...prevState['PHONE'],
                            value: extractedInfo.phone ? extractedInfo.phone.slice(-10) : '' // Safeguard if phone is not available
                        },
                        ['SKILLS']: {
                            ...prevState['SKILLS'],
                            value: extractedInfo.skills && extractedInfo.skills.length ? extractedInfo.skills.join(', ') : '' // Handle undefined skills
                        },
                        ['CURRENT_DESIGNATION']: {
                            ...prevState['CURRENT_DESIGNATION'],
                            value: extractedInfo.current_designation || '' // Default to empty string if missing
                        },
                        ['CURRENT_COMPANY']: {
                            ...prevState['CURRENT_COMPANY'],
                            value: extractedInfo.current_company || '' // Default to empty string if missing
                        },
                        ['TOTAL_EXP_YEAR']: {
                            ...prevState['TOTAL_EXP_YEAR'],
                            value: `${extractedInfo.total_work_expereince?.year || 0} Year` // Safeguard against missing year
                        },
                        ['TOTAL_EXP_MONTH']: {
                            ...prevState['TOTAL_EXP_MONTH'],
                            value: `${extractedInfo.total_work_expereince?.month || 0} Month` // Safeguard against missing month
                        },
                        ['HIGHEST_QUALIFICATION']: {
                            ...prevState['HIGHEST_QUALIFICATION'],
                            value: state.HIGHEST_QUALIFICATION.options.find(item =>
                                item.QUALIFICATION_NAME.toLowerCase() === (extractedInfo?.highest_education?.highest_qualification?.toLowerCase() || '')
                            )?.QUALIFICATION_ID || '' // Handle undefined or missing qualification
                        },
                        // ['COURSE']: {
                        //     ...prevState['COURSE'],
                        //     value: state.COURSE.options.find(item =>
                        //         item.COURSE_STREAM.toLowerCase() === (extractedInfo?.highest_education?.degree?.toLowerCase() || '')
                        //     )?.EDUCATION_QUALIFICATION_ID || '' // Handle undefined or missing course data
                        // },
                    }));
                } else {
                    toast.error(res.error || 'Something went wrong'); // Handle case where there's no error message
                }
            })
            .catch((error) => {
                setShowLoader(false);
                toast.error(error.message || 'An error occurred'); // Handle errors when API call fails
            });
    }
    const fileInputRef = useRef();

    const handleClick = () => {
        fileInputRef.current.click();
    };
    return (
        <React.Fragment>
            {showLoader && <Loader />}

            <Modal isOpen={openQuickAdd} onClose={() => { setOpenQuickAdd(false) }} header='Quick Add'>
                <QuickAdd
                    onClose={() => { setOpenQuickAdd(false) }}
                    onSubmit={(model) => { onQuickAdd(model) }}
                />
            </Modal>

            <div className="container-fluid">
                {/* <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between" style={{ paddingLeft: 34, }}>
                                    <h4 className="mb-sm-0">Add Candidate</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="javascript: void(0);">Candidate Management</a>
                                            </li>
                                            <li className="breadcrumb-item active">Add Candidate</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                <BreadCrumb
                    page={'Add Candidate'}
                    parentSection={<ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Candidate Management</a>
                        </li>
                        <li className="breadcrumb-item active">Add Candidate</li>
                    </ol>}
                />
                <div className="row">
                    <div className="col-lg-12">
                        <div className={`${styles.cardCandidateInfo} card`}>

                            {/* end card header */}
                            <div className="card-body">
                                <div className={`${styles.cardheadertitle} row`}>
                                    <div className={`${styles.leftheaderbox} col-md-8`}>
                                        <h4>Candidate Information</h4>
                                    </div>
                                    <div className={`${styles.rightheaderbox} col-md-4`}>
                                        <button type="submit" className="btn  me-2" onClick={() => { setOpenQuickAdd(true) }}>
                                            <img

                                                src="/assets/images/quikAdd.png"
                                                alt="quick"
                                            /> Quick Add
                                        </button>
                                        <button className="btn "
                                            onClick={handleClick}
                                        >
                                            <img

                                                src="/assets/images/fillresume.png"
                                                alt="quick"
                                            />   Auto fill With Resume
                                        </button>
                                        <input
                                            style={{ display: 'none' }}
                                            type="file"
                                            ref={fileInputRef}
                                            onChange={(e) => {
                                                setState(prevState => ({
                                                    ...prevState,
                                                    ['RESUME']: {
                                                        ...prevState['RESUME'],
                                                        value: e.target.files[0]
                                                    }
                                                }));
                                                onAutoFillWithResume(e.target.files[0])
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.livePreviewBox}`} >
                                    <div className="row gy-4">
                                        <div className="col-lg-12 ">
                                            <div>
                                                <label className="form-label">
                                                    Candidate Full Name:
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.FULL_NAME.error.length > 0 && !state.FULL_NAME.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    placeholder="Full Name"
                                                    onChange={onChange}

                                                    name={state.FULL_NAME.name}
                                                    value={state.FULL_NAME.value}
                                                />
                                                <div className="invalid-feedback">Please enter a full name.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Email
                                                    {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.EMAIL.error.length > 0 && !state.EMAIL.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    placeholder="Email"
                                                    onChange={onChange}
                                                    name={state.EMAIL.name}
                                                    value={state.EMAIL.value}
                                                />
                                                <div className="invalid-feedback">Please enter email address.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Contact No.:
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <input
                                                    type="number"
                                                    className={state.PHONE.error.length > 0 && !state.PHONE.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    placeholder="Phone"
                                                    onChange={onChange}
                                                    name={state.PHONE.name}
                                                    value={state.PHONE.value}
                                                />
                                                <div className="invalid-feedback">Please enter phone number.</div>
                                            </div>
                                        </div>
                                        <div className={`${styles.cardheadertitle} row`}>
                                            <div className={`${styles.leftheaderbox} col-md-12 mt-3 mb-1`}>
                                                <h4>Other Details</h4>
                                            </div>

                                        </div>
                                        <div className="col-lg-4 ">
                                            <div>
                                                <label className="form-label">
                                                    Current Designation

                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.CURRENT_DESIGNATION.error.length > 0 && !state.CURRENT_DESIGNATION.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    placeholder="Current Designation"
                                                    onChange={onChange}
                                                    name={state.CURRENT_DESIGNATION.name}
                                                    value={state.CURRENT_DESIGNATION.value}
                                                />
                                                <div className="invalid-feedback">Please enter Work Location.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 ">
                                            <div>
                                                <label className="form-label">
                                                    Work Location
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.WORKING_LOCATION.error.length > 0 && !state.WORKING_LOCATION.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    placeholder="Work Location"
                                                    onChange={onChange}
                                                    name={state.WORKING_LOCATION.name}
                                                    value={state.WORKING_LOCATION.value}
                                                />
                                                <div className="invalid-feedback">Please enter Work Location.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 ">
                                            <div>
                                                <label className="form-label">
                                                    Residential Location
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.RESIDENTIAL_LOCATION.error.length > 0 && !state.RESIDENTIAL_LOCATION.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    onChange={onChange}
                                                    placeholder="Residential Location"
                                                    name={state.RESIDENTIAL_LOCATION.name}
                                                    value={state.RESIDENTIAL_LOCATION.value}
                                                />
                                                <div className="invalid-feedback">Please enter residential location.</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Current Company
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.CURRENT_COMPANY.error.length > 0 && !state.CURRENT_COMPANY.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    onChange={onChange}
                                                    placeholder="Current Company"
                                                    name={state.CURRENT_COMPANY.name}
                                                    value={state.CURRENT_COMPANY.value}
                                                />
                                                <div className="invalid-feedback">Please enter current company</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Current CTC
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.CURRENT_CTC.error.length > 0 && !state.CURRENT_CTC.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    onChange={onChange}
                                                    placeholder="Current CTC"
                                                    name={state.CURRENT_CTC.name}
                                                    value={state.CURRENT_CTC.value}

                                                />
                                                <div className="invalid-feedback">Please enter current ctc</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Expected CTC
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.EXPECTED_CTC.error.length > 0 && !state.EXPECTED_CTC.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    onChange={onChange}
                                                    placeholder="Expected CTC"
                                                    name={state.EXPECTED_CTC.name}
                                                    value={state.EXPECTED_CTC.value}
                                                />
                                                <div className="invalid-feedback">Please enter expected ctc</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Skills
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <input
                                                    type="text"
                                                    className={state.SKILLS.error.length > 0 && !state.SKILLS.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    onChange={onChange}
                                                    placeholder="Skills"
                                                    name={state.SKILLS.name}
                                                    value={state.SKILLS.value}

                                                />
                                                <div className="invalid-feedback">Please enter skills</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div className="row">
                                                <div className={state.TOTAL_EXP_YEAR.value != 'Fresher' ? "col-lg-6 pdr" : "col-lg-12"}>

                                                    <label className="form-label">
                                                        Total Experience
                                                        <span className='text-danger'>&nbsp;&nbsp;*</span>
                                                    </label>
                                                    <select
                                                        type="text"
                                                        className={state.TOTAL_EXP_YEAR.error.length > 0 && !state.TOTAL_EXP_YEAR.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                        onChange={onChange}
                                                        name={state.TOTAL_EXP_YEAR.name}
                                                        value={state.TOTAL_EXP_YEAR.value}
                                                    >

                                                        <option value=''> Select Experience Year</option>
                                                        <option value='Fresher'> Fresher</option>
                                                        <option value='0 Year'> 0 Year</option>
                                                        <option value='1 Year'> 1 Year</option>
                                                        <option value='2 Year'> 2 Year</option>
                                                        <option value='3 Year'> 3 Year</option>
                                                        <option value='4 Year'> 4 Year</option>
                                                        <option value='5 Year'> 5 Year</option>
                                                        <option value='6 Year'> 6 Year</option>
                                                        <option value='7 Year'> 7 Year</option>
                                                        <option value='8 Year'> 8 Year</option>
                                                        <option value='9 Year'> 9 Year</option>
                                                        <option value='10 Year'> 10 Year</option>
                                                        <option value='11 Year'> 11 Year</option>
                                                        <option value='12 Year'> 12 Year</option>
                                                        <option value='13 Year'> 13 Year</option>
                                                        <option value='14 Year'> 14 Year</option>
                                                        <option value='15 Year'> 15 Year</option>
                                                        <option value='16 Year'> 16 Year</option>
                                                        <option value='17 Year'> 17 Year</option>
                                                        <option value='18 Year'> 18 Year</option>
                                                        <option value='19 Year'> 19 Year</option>
                                                        <option value='20 Year'> 20 Year</option>
                                                        <option value='21 Year'> 21 Year</option>
                                                        <option value='22 Year'> 22 Year</option>
                                                        <option value='23 Year'> 23 Year</option>
                                                        <option value='24 Year'> 24 Year</option>
                                                        <option value='25 Year'> 25 Year</option>
                                                        <option value='26 Year'> 26 Year</option>
                                                        <option value='27 Year'> 27 Year</option>
                                                        <option value='28 Year'> 28 Year</option>
                                                        <option value='29 Year'> 29 Year</option>
                                                        <option value='30 Year'> 30 Year</option>
                                                        <option value='31 Year'> 31 Year</option>
                                                        <option value='32 Year'> 32 Year</option>
                                                    </select>
                                                    <div className="invalid-feedback">Please select work experience</div>
                                                </div>
                                                {state.TOTAL_EXP_YEAR.value != 'Fresher' && <div className={`${styles.pdl} col-lg-6`}>

                                                    <label className="form-label">
                                                        <span className='text-danger'>&nbsp;&nbsp;</span>
                                                    </label>
                                                    <select
                                                        type="text"
                                                        className={state.TOTAL_EXP_MONTH.error.length > 0 && !state.TOTAL_EXP_MONTH.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                        onChange={onChange}
                                                        name={state.TOTAL_EXP_MONTH.name}
                                                        value={state.TOTAL_EXP_MONTH.value}
                                                    >

                                                        <option value=''> Select Experience Month</option>
                                                        <option value='0 Month'> 0 Month</option>
                                                        <option value='1 Month'> 1 Month</option>
                                                        <option value='2 Month'> 2 Month</option>
                                                        <option value='3 Month'> 3 Month</option>
                                                        <option value='4 Month'> 4 Month</option>
                                                        <option value='5 Month'> 5 Month</option>
                                                        <option value='6 Month'> 6 Month</option>
                                                        <option value='7 Month'> 7 Month</option>
                                                        <option value='8 Month'> 8 Month</option>
                                                        <option value='9 Month'> 9 Month</option>
                                                        <option value='10 Month'> 10 Month</option>
                                                        <option value='11 Month'> 11 Month</option>
                                                    </select>
                                                    <div className="invalid-feedback">Please select work experience</div>
                                                </div>}
                                            </div>

                                        </div>
                                        {state.TOTAL_EXP_YEAR.value === 'Fresher' &&
                                            <React.Fragment>

                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label htmlFor="companyname-field" className="form-label">
                                                            College Name
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="text"
                                                            className={state.COLLEGE_NAME.error.length > 0 && !state.COLLEGE_NAME.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            onChange={onChange}
                                                            placeholder="College Name"
                                                            name={state.COLLEGE_NAME.name}
                                                            value={state.COLLEGE_NAME.value}
                                                        />
                                                        <div className="invalid-feedback">Please enter college name</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label htmlFor="companyname-field" className="form-label">
                                                            Passing Year
                                                            <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                        </label>
                                                        <input
                                                            type="month"
                                                            className={state.PASS_OUT_YEAR.error.length > 0 && !state.PASS_OUT_YEAR.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                            onChange={onChange}
                                                            placeholder="Passing Year"
                                                            name={state.PASS_OUT_YEAR.name}
                                                            value={state.PASS_OUT_YEAR.value}
                                                        />

                                                        <div className="invalid-feedback">Please enter work experience</div>
                                                    </div>
                                                </div>
                                            </React.Fragment>

                                        }
                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Highest Qualification
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <select
                                                    className={state.HIGHEST_QUALIFICATION.error.length > 0 && !state.HIGHEST_QUALIFICATION.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                    onChange={onChange}
                                                    placeholder="Highest Qualification"
                                                    name={state.HIGHEST_QUALIFICATION.name}
                                                    value={state.HIGHEST_QUALIFICATION.value}
                                                >
                                                    <option value=''> Select Highest Qualification</option>
                                                    {state.HIGHEST_QUALIFICATION.options && state.HIGHEST_QUALIFICATION.options.map((item) => {
                                                        return (
                                                            <option value={item.QUALIFICATION_ID}> {item.QUALIFICATION_NAME}</option>

                                                        )
                                                    })}
                                                </select>
                                                <div className="invalid-feedback">Please select highest qualification</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Course
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <select
                                                    className={state.COURSE.error.length > 0 && !state.COURSE.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                    onChange={onChange}
                                                    name={state.COURSE.name}
                                                    value={state.COURSE.value}
                                                >
                                                    <option value=''> Select Course</option>
                                                    {
                                                        state.COURSE.options && state.COURSE.options.map((item) => {
                                                            return (<option
                                                                value={item.EDUCATION_QUALIFICATION_ID}

                                                            >
                                                                {item.COURSE_STREAM}
                                                            </option>)
                                                        })

                                                    }
                                                </select>
                                                <div className="invalid-feedback">Please select course</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Specialization
                                                </label>
                                                <select
                                                    type="text"
                                                    className={state.SPECIALIZATION.error.length > 0 && !state.SPECIALIZATION.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                    onChange={onChange}
                                                    placeholder="Specialization"
                                                    name={state.SPECIALIZATION.name}
                                                    value={state.SPECIALIZATION.value}
                                                >
                                                    <option value=''> Select Specialization</option>
                                                    {
                                                        state.SPECIALIZATION.options && state.SPECIALIZATION.options.map((item) => {
                                                            return (<option
                                                                value={item.SPECIALIZATION_ID}
                                                            >
                                                                {item.SPECIALIZATION}
                                                            </option>)
                                                        })

                                                    }
                                                </select>
                                                <div className="invalid-feedback">Please select specialization</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Notice Period
                                                    {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                                </label>
                                                <select
                                                    type="text"
                                                    className={state.NOTICE_PERIOD.error.length > 0 && !state.NOTICE_PERIOD.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                    onChange={onChange}
                                                    name={state.NOTICE_PERIOD.name}
                                                    value={state.NOTICE_PERIOD.value}
                                                >
                                                    <option value=''> Select Notice Period</option>
                                                    <option value='Immediate Joiner'> Immediate Joiner</option>
                                                    <option value='15 Days'> 15 Days</option>
                                                    <option value='1 Month'> 1 Month</option>
                                                    <option value='2 Month'> 2 Month</option>
                                                    <option value='3 Month'> 3 Month or more</option>

                                                </select>
                                                <div className="invalid-feedback">Please select notice period</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Preferred Role
                                                </label>
                                                <select
                                                    className={state.ROLE_ID.error.length > 0 && !state.ROLE_ID.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                    onChange={onChange}
                                                    name={state.ROLE_ID.name}
                                                    value={state.ROLE_ID.value}
                                                >
                                                    <option value=''> Select Preferred Role</option>
                                                    {state.ROLE_ID.options && state.ROLE_ID.options.map((item) => {
                                                        return (
                                                            <option value={item.ROLE_ID}> {item.ROLE_NAME}</option>
                                                        )
                                                    })}

                                                </select>
                                                <div className="invalid-feedback">Please select role</div>
                                            </div>
                                        </div>


                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Lead Source
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <select
                                                    className={state.SOURCE.error.length > 0 && !state.SOURCE.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                    onChange={onChange}
                                                    name={state.SOURCE.name}
                                                    value={state.SOURCE.value}
                                                >
                                                    <option value=''> Select Lead Source</option>
                                                    <option value='Found It / Monster'> Found It / Monster</option>
                                                    <option value='Naukri'> Naukri</option>
                                                    <option value='LinkedIn'> LinkedIn</option>
                                                    <option value='Instagram'> Instagram</option>
                                                    <option value='apna'> apna</option>
                                                    <option value='Campus'> Campus</option>
                                                    <option value='Other Portal'> Other Portal</option>
                                                    <option value='Referral'> Referral</option>
                                                    <option value='Social Media'> Social Media</option>
                                                    <option value='Walk-In'> Walk-In</option>
                                                </select>
                                                <div className="invalid-feedback">Please select Lead Source</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 mb-3">
                                            <div>

                                                <label className="form-label">
                                                    Status
                                                    <span className='text-danger'>&nbsp;&nbsp;*</span>

                                                </label>
                                                <select
                                                    className={state.STATUS.error.length > 0 && !state.STATUS.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                    onChange={onChange}
                                                    name={state.STATUS.name}
                                                    value={state.STATUS.value}
                                                >
                                                    <option value={''}> Choose Status</option>
                                                    {state.STATUS.options && state.STATUS.options.map((item) => {
                                                        return (
                                                            <option value={item.STATUS_ID}>{item.STATUS_NAME}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                                <div className="invalid-feedback">Please select status.</div>
                                            </div>
                                        </div>
                                        {(state.STATUS.value == '3' || state.STATUS.value == '4' || state.STATUS.value == '7') &&
                                            <React.Fragment>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Shortlisted for
                                                        </label>
                                                        <select
                                                            className={state.CLIENT.error.length > 0 && !state.CLIENT.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                            onChange={onChange}
                                                            name={state.CLIENT.name}
                                                            value={state.CLIENT.value}
                                                        >
                                                            <option value=''> Select Company</option>
                                                            {state.CLIENT.options && state.CLIENT.options.map((item) => {
                                                                return (
                                                                    <option value={item.CLIENT_ID}> {item.COMPANY_NAME}</option>
                                                                )
                                                            })
                                                            }
                                                        </select>
                                                        <div className="invalid-feedback">Please select Company</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>

                                                        <label className="form-label">
                                                            Process

                                                        </label>
                                                        <select
                                                            className={state.JOBS.error.length > 0 && !state.JOBS.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                            onChange={onChange}
                                                            name={state.JOBS.name}
                                                            value={state.JOBS.value}
                                                        >
                                                            <option value={''}> Choose Job</option>
                                                            {state.JOBS.options ? state.JOBS.options.map((item) => {
                                                                return (

                                                                    <option value={item.JOB_ID}>{item.JOB_TITLE}</option>
                                                                )

                                                            }) :

                                                                <option value=''>No Active Job Found</option>

                                                            }

                                                        </select>
                                                        <div className="invalid-feedback">Please select process.</div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }

                                        {(state.STATUS.value == '5' || state.STATUS.value == '6' || state.STATUS.value == '7') &&
                                            <React.Fragment>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <label className="form-label">
                                                            Lined up Date
                                                        </label>
                                                        <input
                                                            type='date'
                                                            className={state.LINED_UP_DATE.error.length > 0 && !state.LINED_UP_DATE.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                            onChange={onChange}
                                                            name={state.LINED_UP_DATE.name}
                                                            value={state.LINED_UP_DATE.value}
                                                        />

                                                        <div className="invalid-feedback">Please select date</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>

                                                        <label className="form-label">
                                                            Lined up Time

                                                        </label>
                                                        <input
                                                            type='time'
                                                            className={state.LINED_UP_TIME.error.length > 0 && !state.LINED_UP_TIME.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                            onChange={onChange}
                                                            name={state.LINED_UP_TIME.name}
                                                            value={state.LINED_UP_TIME.value}
                                                        />

                                                        <div className="invalid-feedback">Please select time</div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }


                                        <div className="col-lg-4 mb-3">
                                            <div >
                                                <label className="form-label">
                                                    Candidate Quality
                                                    {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                                </label>
                                                <input
                                                    type='text'
                                                    min={0}
                                                    className={state.RATING.error.length > 0 && !state.RATING.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    onChange={onChange}
                                                    name={state.RATING.name}
                                                    value={state.RATING.value}
                                                    placeholder='Enter Quality Number'
                                                />
                                                {/* <BoxRating
                                                            onChange={ratingChanged}
                                                            value={state.RATING.value}
                                                        /> */}
                                                {state.RATING.error && !state.RATING.value && <span className="text-danger">Please select Lead Source</span>}
                                            </div>
                                        </div>

                                        <div className="col-lg-4  mb-3">
                                            <label className="form-label">
                                                Resume</label>

                                            <div className={styles.fileAddInput}>

                                                <label className={` ${styles.fileInputBtn} form-label`} >
                                                    <span className={styles.spanInput}><i class="bx bx-plus"></i>{!state.RESUME.value ? 'Upload File' : state?.RESUME?.value?.name}</span>

                                                    <input
                                                        type="file"
                                                        className={state.RESUME.error.length > 0 && !state.RESUME.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                        onChange={(e) => {
                                                            setState(prevState => ({
                                                                ...prevState,
                                                                ['RESUME']: {
                                                                    ...prevState['RESUME'],
                                                                    value: e.target.files[0]
                                                                }
                                                            }));
                                                        }}
                                                    />
                                                </label>

                                                <div className="invalid-feedback">Please upload resume.</div>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 mb-3">
                                            <div>
                                                <label className="form-label">
                                                    Assign To
                                                </label>
                                                <select
                                                    className={state.CALLING_ASSIGNEE_ID.error.length > 0 && !state.CALLING_ASSIGNEE_ID.value ? "form-select is-invalid customRadiusSearch" : "form-select customRadiusSearch"}
                                                    onChange={onChange}
                                                    name={state.CALLING_ASSIGNEE_ID.name}
                                                    value={state.CALLING_ASSIGNEE_ID.value}
                                                >
                                                    <option value=''> Select Assignee</option>
                                                    {state.CALLING_ASSIGNEE_ID.options && state.CALLING_ASSIGNEE_ID.options.map((item) => {
                                                        return (
                                                            <option value={item.USER_ID}> {item.FULL_NAME}</option>
                                                        )
                                                    })}

                                                </select>
                                                <div className="invalid-feedback">Please select assignee</div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12  mb-3">
                                            <div >
                                                <label className="form-label">
                                                    Note
                                                    {/* <span className='text-danger'>&nbsp;&nbsp;*</span> */}

                                                </label>
                                                <textarea
                                                    placeholder='Enter Note / Remarks'
                                                    className={state.NOTE.error.length > 0 && !state.NOTE.value ? "form-control is-invalid customRadiusSearch" : "form-control customRadiusSearch"}
                                                    onChange={onChange}

                                                    name={state.NOTE.name}
                                                    value={state.NOTE.value}
                                                    style={{ height: '100px' }}
                                                />
                                                <div className="invalid-feedback">Please upload resume.</div>
                                            </div>
                                        </div>
                                        <div className="hstack gap-2 justify-content-end">
                                            <button type="button" className={` ${styles.cancelButton} btn `} data-bs-dismiss="modal">
                                                Cancel
                                            </button>
                                            <button type="submit" className={` ${styles.addLeadButton} btn btn-success `}
                                                onClick={() => { onSubmit() }}
                                            >
                                                Add Lead

                                            </button>
                                            {/* <button type="button" class="btn btn-success" id="edit-btn">Update</button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </React.Fragment >
    )
}

export default Add