import React, { useEffect, useState } from 'react'
import { v3_admin_user_hierarchy } from '../../../../actions/v3/administrator';
import Hierarchy from '../../../../components/common/Hierarchy'
import PeroformanceTable from '../../../../components/companyUser/administrator/CRMUser/peroformanceTable';
import { getStorage } from '../../../../utils';
import constant from '../../../../constant';
import BreadCrumb from '../../../../components/common/BreadCrumb'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Index = () => {
    const [userHierarchy, setUserHierarchy] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const isAdministrator = getStorage(constant.keys.companyUserHavingAdminRights);

    useEffect(() => {
        v3_admin_user_hierarchy().then((res) => {
            if (res.status) {
                setUserHierarchy(res.result)
            }
        })

    }, [])
    return (

        <div className="container-fluid">
            <BreadCrumb
                page={'Organization Chart'}
                parentSection={<Link to={constant.companyUserComponent.orgChart.url}>Dashboard</Link>}

            />
            <div className="row">
                <div className="col-lg-12">
                    <div className="card cardHierarchy">
                        <div className="card-body customHierarchyStyle">
                            {userHierarchy && <Hierarchy
                                json={userHierarchy}
                                setSelected={setSelectedUser}
                            />}
                        </div>
                    </div>
                </div>
            </div>
            {isAdministrator && selectedUser && <PeroformanceTable
                id={selectedUser}
            />}
        </div>

    )
}

export default Index