import React, { useEffect, useState } from 'react'
import { v3_org_chart_performance } from '../../../../actions/v3/administrator'
import moment from 'moment'

const PeroformanceTable = ({ id }) => {
    const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'))
    const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [list, setList] = useState(null)


    useEffect(() => {
        v3_org_chart_performance(btoa(JSON.stringify(id)), fromDate, toDate).then((res) => {
            if (res.status) {
                setList(res.result)
            }
        })
    }, [id])
    useEffect(() => {
        v3_org_chart_performance(btoa(JSON.stringify(id)), fromDate, toDate).then((res) => {
            if (res.status) {
                setList(res.result)
            }
        })
    }, [fromDate, toDate])

    console.log(list)
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="tab-content pt-4 text-muted">
                        <div className="tab-pane active" id="overview-tab" role="tabpanel">
                            <div className="row">
                                <div className="col-xxl-12">
                                    <div className="card cardPerformanceSection">
                                        <div className="card-body">
                                            <div className="listjs-table" id="customerList">
                                                <div className="row g-4 mb-3">
                                                    <div className="col-sm">
                                                        <div className="d-flex justify-content-start target-value-section mt-2">
                                                           
                                                            <h4>Performance</h4>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm">
                                                        <div className="d-flex justify-content-end target-value-section align-items-center">
                                                            <div className="col-3">
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    value={fromDate}
                                                                    onChange={(e) => { setFromDate(e.target.value) }}
                                                                />
                                                            </div>
                                                            <div className="tobox mx-2">&nbsp; - to - &nbsp;</div>
                                                            <div className="col-3">
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    value={toDate}
                                                                    onChange={(e) => { setToDate(e.target.value) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="table-responsive table-card mt-3 mb-1">
                                                    <table
                                                        className="table table-performance-box align-middle table-nowrap"
                                                    >
                                                        <thead className="table-light table-light-performance">
                                                            <tr>
                                                                <th >
                                                                    Name
                                                                </th>
                                                                <th >
                                                                    HR Shortlisted
                                                                </th>
                                                                <th >
                                                                    Hired
                                                                </th>
                                                                <th >
                                                                    Interviewed
                                                                </th>
                                                                <th >
                                                                    Offered
                                                                </th>
                                                                <th >
                                                                    Joined
                                                                </th> <th >
                                                                    Rejected
                                                                </th> <th >
                                                                    Dropped
                                                                </th>
                                                                <th >
                                                                    Total Candidates
                                                                </th>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="list form-check-all">
                                                            <tr>
                                                                <td className="customer_name">
                                                                    <div className="d-flex align-items-start">
                                                                        <div className="flex-shrink-0 me-3">
                                                                            <div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <h5 className="contact-name fs-13 mb-1">
                                                                                <a href="#" className="link text-body">
                                                                                    All Performance
                                                                                </a>
                                                                            </h5>

                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="email">{list?.overallPerformance?.apply_jobs}</td>
                                                                <td className="email">{list?.overallPerformance?.hired}</td>
                                                                <td className="email">{list?.overallPerformance?.interviewed}</td>

                                                                <td className="email">{list?.overallPerformance?.offered}</td>
                                                                <td className="email">{list?.overallPerformance?.joined}</td>
                                                                <td className="email">{list?.overallPerformance?.rejected}</td>
                                                                <td className="email">{list?.overallPerformance?.dropped}</td>
                                                                <td className="email">{list?.overallPerformance?.total_candidates}</td>


                                                            </tr>

                                                            {list && list.ClickedUserAndTheirTeamMembersPeroformanceIndividually?.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td className="customer_name">
                                                                            <div className="d-flex align-items-start">
                                                                                <div className="flex-shrink-0 me-3">
                                                                                    <div>
                                                                                        <img
                                                                                            className="image avatar-xs rounded-circle"
                                                                                            alt=""
                                                                                            src={item.PROFILE_IMAGE ? `${process.env.REACT_APP_BASE_URL}/v3/profile-image/${item.USER_ID}/${item.PROFILE_IMAGE}` : "/assets/images/users/user-dummy-img.jpg"}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-grow-1 overflow-hidden">
                                                                                    <h5 className="contact-name fs-13 mb-1">
                                                                                        <a href="#" className="link text-body">
                                                                                            {item.FULL_NAME}
                                                                                        </a>
                                                                                    </h5>
                                                                                    <p className="contact-born text-muted mb-0">
                                                                                        {item.DESIGNATION ? item.DESIGNATION : '--'}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="email">{item.STATS.apply_jobs}</td>
                                                                        <td className="email">{item.STATS.hired}</td>
                                                                        <td className="email">{item.STATS.interviewed}</td>

                                                                        <td className="email">{item.STATS.offered}</td>
                                                                        <td className="email">{item.STATS.joined}</td>
                                                                        <td className="email">{item.STATS.rejected}</td>
                                                                        <td className="email">{item.STATS.dropped}</td>
                                                                        <td className="email">{item.STATS.total_candidates}</td>


                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                    {/* <div className="noresult" style={{ display: "none" }}>
                                                        <div className="text-center">
                                                            <lord-icon
                                                                src="https://cdn.lordicon.com/msoeawqm.json"
                                                                trigger="loop"
                                                                colors="primary:#121331,secondary:#08a88a"
                                                                style={{ width: 75, height: 75 }}
                                                            />
                                                            <h5 className="mt-2">Sorry! No Result Found</h5>
                                                            <p className="text-muted mb-0">
                                                                We've searched more than 150+ Orders We did not find
                                                                any orders for you search.
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default PeroformanceTable