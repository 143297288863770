import React from 'react'
import constant from '../../../constant';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './landing.module.css'

const Landingpage = (props) => {
  return (
    <React.Fragment>
      <section className="section nft-hero" id="hero">
        <div className="bg-overlay" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-9 col-lg-9 mx-auto-hero">
              <div className={styles.bannerbox}>
               <img className={styles.bannerlogoFirst} src="/images/banner-logo.jpg" width="100%" alt="app"  />
                <h1 className={styles.bannerhad}>
                  <span style={{ color: '#32bbf9' }}>Jobzshala Recruit</span>
                </h1>
                <h4 className={styles.subtitle}>An Advanced Applicant Tracking System</h4>
                <p className="" >
                  Revolutionize Your Operations: From Candidate Sourcing to Contingent Workforce Management Streamline Your Staffing Journey with Jobzshala Recruit
                </p>
                <div className={styles.buttonBox}>
                  <Link to={constant.nonAuthedcomponent.requestDemo.url} className={styles.bannerButtons} >
                    Request Free Demo <i className='ri-arrow-right-line'></i>{" "}
                    {/* <i className="ri-arrow-right-line align-middle ms-1" /> */}
                  </Link>
                  {/* <a href="javascript:void(0)" className="bannerBtnOutline">
                    Request Free Demo{" "}
                  </a> */}
                </div>
              </div>
            </div>
            {/* <div class="col-md-5 col-lg-6">
              <div class="video-app-wrap ml-auto mt--120 d-none d-sm-none d-md-block d-lg-block">
                <div class="iphone-mask">
                  <img src="/images/BannerRight.svg" width="100%" alt="app" class="img-fluid mask-img" />
                </div>
              </div>
            </div> */}
            {/*end col*/}
          </div>
          {/* end row */}
        </div>
        <div className="shape-bottom overflow-hidden">
          <img
            src="/images/hero-shape.svg" alt="shape" className="bottom-shape"
          />
        </div>
        {/* end container */}
      </section>
      {/* end hero section */}
      {/* start wallet */}
      <section className="section" id="wallet">
        <div className="container">
          <div className="row justify-content-center">
          <div class="col-md-6">
          <Carousel data-bs-theme="dark" className={`${styles.casSlid}, customCarousel`} >
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-12">
                    <img src="/assets/images/homepage/dasboard-screen-1.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <Carousel.Caption>
                   <h5 className={styles.captionFont}>Exclusive Solutions for Staffing Agencies</h5>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-12">
                    <img src="/assets/images/homepage/dasboard-screen-3.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <Carousel.Caption>
                   <h5 className={styles.captionFont}>Exclusive Solutions for Staffing Agencies</h5>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-12">
                    <img src="/assets/images/homepage/dasboard-screen-4.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <Carousel.Caption>
                   <h5 className={styles.captionFont}>Exclusive Solutions for Staffing Agencies</h5>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-12">
                    <img src="/assets/images/homepage/dasboard-screen-5.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <Carousel.Caption>
                   <h5 className={styles.captionFont}>Exclusive Solutions for Staffing Agencies</h5>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            </Carousel>
              </div>

      
              <div class="col-md-6">
                <div className="">
                  {/* <div class="walletSectionHeadingInnerIcon">
                    <i class="ri-user-settings-fill"></i>
                  </div> */}
                <h2 className={styles.h2head}>Exclusively Designed for Staffing Agencies</h2>
             
                <h4 className={styles.h4head}>Addressing Your Data Management Struggles and Work Culture Dynamics
                  </h4>
                  <p className={styles.para2section}>In the dynamic world of staffing and recruitment, efficiency is paramount. Jobzshala Recruit
                    is your comprehensive solution, tailored specifically for staffing agencies. We understand the
                    intricacies of your industry and have crafted a platform that addresses your unique
                    challenges, from data management to fostering a collaborative work culture.
                  </p>
                </div>
              </div>
             
            </div>
            </div>  
          
        {/* end container */}
      </section>
      <section className={styles.AiPoweredSection}>
        <div className="container">
          <div className="row justify-content-center align-items-center">
          

      
              <div class="col-md-6">
                <div className="">
                  {/* <div class="walletSectionHeadingInnerIcon">
                    <i class="ri-user-settings-fill"></i>
                  </div> */}
                <h2 className={styles.h2head}>AI-Powered Recruitment Management</h2>
             
               
                  <p className={styles.para2section}>Transform your recruitment approach with Jobzshala Recruit's innovative AI technology. Witness a remarkable increase in your pool of potential candidates effortlessly. Experience seamless integration of AI into your workflow, enabling swift identification of the perfect match for your organization. Jobzshala Recruit's advanced applicant tracking system streamlines talent acquisition, providing unmatched efficiency and invaluable data insights.


                  </p>
                </div>
              </div>
              <div class="col-md-6">
                   <img src="/assets/images/homepage/ai-Image.jpg" width="100%" alt="app" class="img-fluid mask-img" />
              </div>
             
            </div>
            </div>  
          
        {/* end container */}
      </section>
      <section className={styles.threeSection}>
        <div className="container">
          <div className="row justify-content-center align-items-center">
          <div class="col-md-5">
                   <img className={styles.imgLeft} src="/assets/images/homepage/staffingAgency.jpg" width="100%" alt="app"  />
              </div>

      
              <div class="col-md-7">
                <div className="">
                  {/* <div class="walletSectionHeadingInnerIcon">
                    <i class="ri-user-settings-fill"></i>
                  </div> */}
                <h2 className={styles.h2head}>Exclusively Designed for Staffing Agencies</h2>
                <h4 className={styles.h4head}>Addressing Your Data Management Struggles and Work Culture Dynamics
                </h4>
               
                  <p className={styles.para2section}>In the dynamic world of staffing and recruitment, efficiency is paramount. Jobzshala Recruit is your comprehensive solution, tailored specifically for staffing agencies. We understand the intricacies of your industry and have crafted a platform that addresses your unique challenges, from data management to fostering a collaborative work culture.


                  </p>
                </div>
              </div>
              
             
            </div>
            </div>  
          
        {/* end container */}
      </section>

      {/* <section className="section bg-light creatorsSectionStyle" id="creators">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-8">
              <div className="text-center mb-5 creatorsSectionHeadingStyle">
                <h2 className="mb-3">AI-Powered Recruitment Management</h2>
                <p className="text-muted">
                  Transform your recruitment approach with Jobzshala Recruit's 
                  innovative AI technology. Witness a remarkable increase in your
                   pool of potential candidates effortlessly. Experience seamless 
                   integration of AI into your workflow, enabling swift 
                   identification of the perfect match for your organization. 
                   Jobzshala Recruit's advanced applicant tracking system 
                   streamlines talent acquisition, providing unmatched efficiency 
                   and invaluable data insights.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <Carousel data-bs-theme="dark" className="customCarousel">
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-6">
                    <img src="/assets/images/homepage/dasboard-screen-1.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <div class="col-md-6">
                    <Carousel.Caption>
                      <h5>Exclusively Designed for Staffing Agencies</h5>
                      <p>In the dynamic world of staffing and recruitment, efficiency is paramount. Jobzshala Recruit
                        is your comprehensive solution, tailored specifically for staffing agencies. We understand the
                        intricacies of your industry and have crafted a platform that addresses your unique
                        challenges, from data management to fostering a collaborative work culture.</p>
                    </Carousel.Caption>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-6">
                    <img src="/assets/images/homepage/dasboard-screen-3.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <div class="col-md-6">
                    <Carousel.Caption>
                      <h5>Streamlined Operations, End-to-End</h5>
                      <p>From the initial stages of candidate sourcing and engagement to the final selection, offer,
                        and contingent workforce management, Jobzshala Recruit streamlines every aspect of your
                        operations. Our platform integrates seamlessly into your workflow, saving you time and
                        resources at every step of the recruitment process.</p>
                    </Carousel.Caption>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-6">
                    <img src="/assets/images/homepage/dasboard-screen-4.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <div class="col-md-6">
                    <Carousel.Caption>
                      <h5>Objective Screening for Optimal Results</h5>
                      <p>Say goodbye to subjective decision-making. Jobzshala Recruit empowers you with objective
                        screening tools that ensure you find the best-fit candidates for your clients. Our AI-powered
                        algorithms analyze candidate data and match it against predefined criteria, allowing you to
                        make informed decisions quickly and confidently.</p>
                    </Carousel.Caption>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div class="row">
                  <div class="col-md-6">
                    <img src="/assets/images/homepage/dasboard-screen-5.png" width="100%" alt="app" class="img-fluid mask-img" />
                  </div>
                  <div class="col-md-6">
                    <Carousel.Caption>
                      <h5>Exclusive Solutions for Staffing Agencies</h5>
                      <p>Unlike generic ATS software, Jobzshala Recruit is exclusively designed to meet the needs of
                        staffing agencies. We&#39;ve identified the pain points of data management and work culture
                        dynamics within your industry and have developed features specifically to address them.
                        With Jobzshala Recruit, you&#39;re not just getting a software solution – you&#39;re getting a partner
                        dedicated to your success.</p>
                    </Carousel.Caption>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
         
        </div>
        
      </section> */}



      <section id="how-it-work" className={styles.processSection}>
        <div class="container">
          <div class="row justify-content-center">
              <div class="col-md-9 col-lg-8">
                  <div class="section-heading text-center ">
                      <h2 className={styles.workProcessSectionHeading}>Why <span style={{ color: '#fdcc01'}}>JOBZSHALA</span> Recruit</h2>
                      {/* <p>Distinctively grow go forward manufactured products and optimal networks. Enthusiastically
                          disseminate user-centric outsourcing through revolutionary</p> */}
                  </div>
              </div>
          </div>
          <div class="row">
            <div class="col-md-12">
                <div class="work-process-wrap">
                    <div class="single-work-process text-center mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                        <div className={styles.processIconBx}>
                            {/* <i class="ti-vector icon-md color-1"></i> */}
                            {/* <img src="/assets/images/homepage/step1Img.png" alt="" style={{padding: 10,}}
                            /> */}
                           <img src="/assets/images/homepage/fast-icons.png" alt="app" class="img-fluid mask-img" />
                            {/* <span class="process-step white-bg color-primary shadow-sm">1</span> */}
                        </div>
                        {/* <span class="work-process-divider">
                          <i class="ri-arrow-right-line align-bottom customDivider"></i>
                        </span> */}
                        <div class="work-process-content mt-4">
                            <h5>Faster Hiring</h5>
                            <p>Jobzshala Recruit streamlines the hiring process, saving you valuable time and resources.</p>
                        </div>
                    </div>
                    <div class="single-work-process text-center mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                        <div className={styles.processIconBx}>
                            {/* <i class="ti-layout-list-thumb icon-md color-2"></i> */}
                            {/* <img src="/assets/images/homepage/step2Img.png" alt="" style={{padding: 10,}}
                            /> */}
                             <img src="/assets/images/homepage/user-interface.png" alt="app" class="img-fluid mask-img" />
                            {/* <span class="process-step white-bg color-primary shadow-sm">2</span> */}
                        </div>
                        {/* <span class="work-process-divider">
                          <i class="ri-arrow-right-line align-bottom customDivider"></i>
                        </span> */}
                        <div class="work-process-content mt-4">
                            <h5>User-Friendly Interface</h5>
                            <p>Our platform is designed to be intuitive and easy to navigate, ensuring a seamless experience for all users.</p>
                        </div>
                    </div>
                    <div class="single-work-process text-center mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                        <div className={styles.processIconBx}>
                            {/* <i class="ti-palette icon-md color-3"></i> */}
                            {/* <img src="/assets/images/homepage/step3Img.png" alt="" style={{padding: 10,}}
                            /> */}
                             <img src="/assets/images/homepage/rupee.png" alt="app" class="img-fluid mask-img" />
                            {/* <span class="process-step white-bg color-primary shadow-sm">3</span> */}
                        </div>
                        {/* <span class="work-process-divider">
                          <i class="ri-arrow-right-line align-bottom customDivider"></i>
                        </span> */}
                        <div class="work-process-content mt-4">
                            <h5>Affordable Solutions</h5>
                            <p>We believe in making advanced recruitment technology accessible to everyone, offering cost-effective options for businesses of all sizes.</p>
                        </div>
                    </div>
                    <div class="single-work-process text-center mt-lg-5 mt-md-5 mt-sm-5 mt-5">
                        <div className={styles.processIconBx}>
                            {/* <i class="ti-cup icon-md color-4"></i> */}
                            {/* <img src="/assets/images/homepage/step4Img.png" alt="" style={{padding: 10,}}
                            /> */}
                             <img src="/assets/images/homepage/ai.png" alt="app" class="img-fluid mask-img" />
                            {/* <span class="process-step white-bg color-primary shadow-sm">4</span> */}
                        </div>
                        <span class="work-process-divider">
                          <i class="ri-arrow-right-line align-bottom customDivider"></i>
                        </span>
                        <div class="work-process-content mt-4">
                            <h5>AI-Driven Matching</h5>
                            <p>Utilizing artificial intelligence, Jobzshala Recruit ensures precise and efficient candidate matching, enhancing your recruitment outcomes.</p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.recruitingSection} id="marketplace">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="text-center mb-5 sellSectionHeadingStyle">
                <h2 className={styles.recuitinghead}>Recruiting Obstacles</h2>
                <p className={styles.recPara}>
                  The process of creating an NFT may cost less than a dollar, but
                  the process of selling it can cost up to a thousand dollars. For
                  example, Allen Gannett, a software developer.
                </p>
              </div>
            </div>
            {/* end col */}
          </div>


          <div class="row">
            <div class="col-md-4 col-12">
              <div className={styles.featuresBox}>
                <span className={styles.hirIcon}>1</span>
                <div className={styles.featuresinnerBox}>
                  <h5 class="mb-2">Slow Hiring</h5>
                  <p>80% of recruiters fail to complete requisitions within the deadline.</p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-12">
            <div className={styles.featuresBox}>
            <span className={styles.hirIcon}>2</span>
                <div className={styles.featuresinnerBox}>
                  <h5 class="mb-2">Hiring Manager's Challenge</h5>
                  <p>70% of hiring managers struggle to attract talented candidates.</p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-12">
            <div className={styles.featuresBox}>
            <span className={styles.hirIcon}>3</span>
                <div className={styles.featuresinnerBox}>
                  <h5 class="mb-2">Lack of Collaboration</h5>
                  <p>Recruiters and hiring managers face challenges due to lack of collaboration.</p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-12">
            <div className={styles.featuresBox}>
            <span className={styles.hirIcon}>4</span>
                <div className={styles.featuresinnerBox}>
                  <h5 class="mb-2">Common Candidate Screening</h5>
                  <p>60% of recruiters find candidate screening to be a challenge.</p>
                </div>
              </div>
            </div>

            <div class="col-md-4 col-12">
            <div className={styles.featuresBox}>
            <span className={styles.hirIcon}>5</span>
                <div className={styles.featuresinnerBox}>
                  <h5 class="mb-2">No Centralized Profile Bank</h5>
                  <p>Ineffective management of candidate databases leads to
                    fragmented data storage.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12">
            <div className={styles.featuresBox}>
            <span className={styles.hirIcon}>6</span>
                <div className={styles.featuresinnerBox}>
                  <h5 class="mb-2">No Centralized Profile Bank</h5>
                  <p>Ineffective management of candidate databases leads to
                    fragmented data storage.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* end Work Process */}
      {/* start cta */}
      <section className="py-5 position-relative CtaSection">
        <div className="bg-overlay bg-overlay-pattern opacity-50" />
        <div className="container">
          <div className="row align-items-center gy-4">
            <div className="col-md-10 m-0 mx-auto-hero CtaSectionInner">
               
              <h4 className={styles.opporHead}>Unlock Opportunities</h4>
              <p className={styles.opporPara}>Attract more clients and expland your business with Jobshaa Recrui. Join our Ecosyst and tranform yur cnadidate management efoffrtlessly with AI technologoy</p>
              <div className="ctaButtons mt-4">
                {/* <a
                  href="apps-nft-create.html"
                  className="btn ctaButtonsOutline"
                >
                  Create NFT
                </a> */}
                <Link
                  to={constant.nonAuthedcomponent.requestDemo.url}
                  className={styles.ctaButtonsFullbtn}
                >
                  Request a free demo <i className='ri-arrow-right-line'></i>
                </Link>
              </div>
            </div>

            {/* <div class="col-md-5 col-md-4">
              <div class="d-flex align-items-end">
                <img src="/assets/images/homepage/ctaDemo.svg" class="img-fluid" alt="download" />
              </div>
            </div> */}
            {/* end col */}
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </section>
      {/* end cta */}
      <section class="how-it-work section recuritTimeSectionMain" id="categories" >
        <div class="container">
          <div class="row justify-content-between">
           
            <div class="col-md-6 col-lg-6">
              <div class="content-info-wrap recuritTimeSection">
                <h2 >Bring Along Your Favorite Time</h2>
                <h4 className={styles.subHeadRecu}>Connect your existing plugins and amplify your mobile app.</h4>
                {/* <p>Energistically disseminate sustainable services without e-business imperatives. Holisticly maximize world-class collaboration and idea-sharing vis-a-vis reliable. Connect your existing plugins and amplify your mobile app.</p> */}

                <div class="row mt-5">
                  <div class="col-md-12 col-lg-12 col-12">
                    <div className={styles.detailInfoBox}>
                      <span className={styles.recIconBox}>
                      <img src="/assets/images/homepage/recuIcon-1.png" alt="" />
                      </span>
                      <div className={styles.detailInfoContent}>
                        <h5 class="mb-2">Design Focused</h5>
                        <p>Appropriately recaptiualize multimedia based top-line e-services.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12 col-12">
                    <div className={styles.detailInfoBox}>
                      <span className={styles.recIconBox}>
                      <img src="/assets/images/homepage/recuIcon-2.png" alt="" />
                      </span>
                      <div className={styles.detailInfoContent}>
                        <h5 class="mb-2">Marketing Activity</h5>
                        <p>Dynamically recaptiualize fully researched e-services applications.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12 col-12">
                    <div className={styles.detailInfoBox}>
                      <span className={styles.recIconBox}>
                      <img src="/assets/images/homepage/recuIcon-3.png" alt="" />
                      </span>
                      <div className={styles.detailInfoContent}>
                        <h5 class="mb-2">Customization at No Extra Cost</h5>
                        <p>Your satisfaction is our priority. We understand that every agency is unique, which is why we offer customization options tailored to your specific needs. Whether it's modifying features or adding new functionalities, we'll make it happen at no extra cost to you.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="img-wrap-recuritTime">
                <img src="/assets/images/homepage/TimeMangementChart.svg" alt="" style={{ padding: 10, }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" class="section featuresSectionStyle">
        <div class="container">
         

          <div class="row ">
          <div class="col-lg-5 col-md-4 col-12">
              <img src="/images/service-Image.svg" width="100%" alt="app" class="img-fluid mask-img" />
            </div>
            <div class="col-lg-7 col-md-12">
              <div class="row">
              <div class="col-md-12 col-lg-12">
              <div className={styles.sectionHeading} >
                <h2>Service Excellence Guaranteed</h2>
                <h4>Efficiently transform granular value with client-focused content. </h4>
              </div>
            </div>
                <div class="col-md-12 col-12">
                  <div className={styles.serviceBox}>
                    <span className={styles.serviceIcon}>
                       <img src="/assets/images/homepage/seIcon-1.png" alt="" />
                    </span>
                    <div class="d-block overflow-hidden ">
                      <h5 class="mb-2">24/7 Support</h5>
                      <p>We're here when you need us. Our dedicated support team is available
                        round-the-clock to assist you with any queries or concerns, ensuring uninterrupted service
                        and peace of mind.</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-12">
                  <div className={styles.serviceBox}>
                  <span className={styles.serviceIcon}>
                       <img src="/assets/images/homepage/seIcon-2.png" alt="" />
                    </span>
                    <div class="d-block overflow-hidden ">
                      <h5 class="mb-2">Comprehensive Training</h5>
                      <p>Empower your team with the knowledge and skills they need to
                        make the most of Jobzshala Recruit. Our experts provide thorough training sessions to your
                        users, ensuring seamless adoption and utilization of our platform.</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 col-12">
                  <div className={styles.serviceBox}>
                  <span className={styles.serviceIcon}>
                       <img src="/assets/images/homepage/seIcon-3.png" alt="" />
                    </span>
                    <div class="d-block overflow-hidden ">
                      <h5 class="mb-2">Customization at No Extra Cost:</h5>
                      <p>Your satisfaction is our priority. We understand that every
                        agency is unique, which is why we offer customization options tailored to your specific
                        needs. Whether it's modifying features or adding new functionalities, we&#39;ll make it happen at
                        no extra cost to you.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
      </section>

      

      {/* <section id="reviews" class="section testimonialSection"> */}
      <section id="reviews" className={styles.clientsSectionBox}>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-8">
              <div class="section-heading mb-5 text-center ">
                <h2 className={styles.testimonialHeadingSection}>What Our Clients Says</h2>
                {/* <p class="lead">
                  Rapidiously morph transparent internal or "organic" sources whereas resource sucking
                  e-business. Conveniently innovate compelling internal.
                </p> */}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-lg-4">
               <div className={styles.testimonialItem}>
              <div className={styles.testimonialSingle}>
                <img className={styles.whiteIcon} src="/assets/images/homepage/quote.png" alt="" />
                <img className={styles.blueIcon} src="/assets/images/homepage/quote-blue.png" alt="" />
                 
                <blockquote>
                  Assertively procrastinate distributed relationships whereas equity invested intellectual capital everything energistically underwhelm proactive.
                </blockquote>
                {/* <div class="client-ratting mt-2">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <span class="ri-star-line ratting-color"></span>
                    </li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                  </ul>
                  <h6 class="font-weight-bold">5.0 <small class="font-weight-lighter">BizBite</small></h6>
                </div> */}
              </div>
              <div class="client-info-wrap d-flex align-items-center mt-5">
                <div class="client-img mr-3">
                  <img src="/assets/images/homepage/client-1.jpg" alt="client" width="60" class="img-fluid rounded-circle shadow-sm" />
                </div>
                <div className={styles.clientInfo}>
                  <h5 class="mb-0">Austin Cesar</h5>
                  <p class="mb-0">ThemeTags</p>
                </div>
              </div>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
            <div className={styles.testimonialItem}>
              <div className={styles.testimonialSingle}>
              <img className={styles.whiteIcon} src="/assets/images/homepage/quote.png" alt="" />
              <img className={styles.blueIcon} src="/assets/images/homepage/quote-blue.png" alt="" />
                <blockquote>
                  Intrinsicly facilitate functional imperatives without next-generation meta-services. Compellingly revolutionize worldwide users vis-a-vis enterprise best practices.
                </blockquote>
                {/* <div class="client-ratting mt-2">
                  <ul class="list-inline">
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                  </ul>
                  <h6 class="font-weight-bold">5.0 <small class="font-weight-lighter">BizBite</small></h6>
                </div> */}
              </div>
              <div class="client-info-wrap d-flex align-items-center mt-5">
                <div class="client-img mr-3">
                  <img src="/assets/images/homepage/client-2.jpg" alt="client" width="60" class="img-fluid rounded-circle shadow-sm" />
                </div>
                <div className={styles.clientInfo}>
                  <h5 class="mb-0">Pirtle Karol</h5>
                  <p class="mb-0">ThemeTags</p>
                </div>
              </div>
            </div>
            </div>
            <div class="col-md-4 col-lg-4">
            <div className={styles.testimonialItem}>
              <div className={styles.testimonialSingle}>
              <img className={styles.whiteIcon} src="/assets/images/homepage/quote.png" alt="" />
                <img className={styles.blueIcon} src="/assets/images/homepage/quote-blue.png" alt="" />

                <blockquote>
                  Interactively grow backend scenarios through one paradigms. Distinctively and communicate efficient information without effective meta-services.
                </blockquote>
                {/* <div class="client-ratting mt-2">
                  <ul class="list-inline">
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                    <li class="list-inline-item"><span class="ri-star-line ratting-color"></span></li>
                  </ul>
                  <h6 class="font-weight-bold">5.0 <small class="font-weight-lighter">BizBite</small></h6>
                </div> */}
              </div>
              <div class="client-info-wrap d-flex align-items-center mt-5">
                <div class="client-img mr-3">
                  <img src="/assets/images/homepage/client-3.jpg" alt="client" width="60" class="img-fluid rounded-circle shadow-sm" />
                </div>
                <div className={styles.clientInfo}>
                  <h5 class="mb-0">Aminul Islam</h5>
                  <p class="mb-0">ThemeTags</p>
                </div>
              </div>
              </div>
            </div>
          </div>

        </div>
      </section>
      <section id="reviews" className={styles.sponsorsSectionBox}>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-9 col-lg-8">
              <div class="section-heading mb-5 text-center testimonialHeadingSection">
                <h2>Our sponsors</h2>
                {/* <p class="lead">
                  Rapidiously morph transparent internal or "organic" sources whereas resource sucking
                  e-business. Conveniently innovate compelling internal.
                </p> */}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-lg-3">
              <div className=''>
              <img  src="/assets/images/Apple.png" alt="" />
              </div>
            </div>
            <div class="col-md-3 col-lg-3">
              <div className=''>
              <img  src="/assets/images/microsoft.png" alt="" />
              </div>
            </div>
            <div class="col-md-3 col-lg-3">
              <div className=''>
              <img  src="/assets/images/Slack.png" alt="" />
              </div>
            </div>
            <div class="col-md-3 col-lg-3">
              <div className=''>
              <img  src="/assets/images/Google.png" alt="" />
              </div>
            </div>
            
            
          </div>

        </div>
      </section>

      <section id="reviews" className={styles.frequentlyAskedSectionBox}>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 col-lg-12">
              <div className={styles.sectionHeading}>
                <h2 style={{color:'#121212'}}>Frequently Asked Questions</h2>
                {/* <p class="lead">
                  Rapidiously morph transparent internal or "organic" sources whereas resource sucking
                  e-business. Conveniently innovate compelling internal.
                </p> */}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div className="faqsection">
             
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  What is backlink outreach, and how can it benefit my website?
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  A backlink is an incoming hyperlink from one web page to another website. It is
                  a form of citation or vote of confidence. Quality backlinks from relevant
                  sources can boost your website's authority and ranking.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="heading-0">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-0" aria-expanded="false" aria-controls="collapse-0">
                  I'm worried about getting penalized by search engines. Is backlink outreach
                  safe?
                </button>
              </h2>
              <div id="collapse-0" class="accordion-collapse collapse" aria-labelledby="heading-0" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  A backlink is an incoming hyperlink from one web page to another website. It is
                  a form of citation or vote of confidence. Quality backlinks from relevant
                  sources can boost your website's authority and ranking.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="heading-1">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                  I'm worried about getting penalized by search engines. Is backlink outreach
                  safe?
                </button>
              </h2>
              <div id="collapse-1" class="accordion-collapse collapse" aria-labelledby="heading-1" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  A backlink is an incoming hyperlink from one web page to another website. It is
                  a form of citation or vote of confidence. Quality backlinks from relevant
                  sources can boost your website's authority and ranking.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="heading-2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                  I'm worried about getting penalized by search engines. Is backlink outreach
                  safe?
                </button>
              </h2>
              <div id="collapse-2" class="accordion-collapse collapse" aria-labelledby="heading-2" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  A backlink is an incoming hyperlink from one web page to another website. It is
                  a form of citation or vote of confidence. Quality backlinks from relevant
                  sources can boost your website's authority and ranking.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="heading-3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                  I'm worried about getting penalized by search engines. Is backlink outreach
                  safe?
                </button>
              </h2>
              <div id="collapse-3" class="accordion-collapse collapse" aria-labelledby="heading-3" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  A backlink is an incoming hyperlink from one web page to another website. It is
                  a form of citation or vote of confidence. Quality backlinks from relevant
                  sources can boost your website's authority and ranking.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="heading-4">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                  I'm worried about getting penalized by search engines. Is backlink outreach
                  safe?
                </button>
              </h2>
              <div id="collapse-4" class="accordion-collapse collapse" aria-labelledby="heading-4" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  A backlink is an incoming hyperlink from one web page to another website. It is
                  a form of citation or vote of confidence. Quality backlinks from relevant
                  sources can boost your website's authority and ranking.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="heading-5">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                  I'm worried about getting penalized by search engines. Is backlink outreach
                  safe?
                </button>
              </h2>
              <div id="collapse-5" class="accordion-collapse collapse" aria-labelledby="heading-5" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  A backlink is an incoming hyperlink from one web page to another website. It is
                  a form of citation or vote of confidence. Quality backlinks from relevant
                  sources can boost your website's authority and ranking.
                </div>
              </div>
            </div>


          </div>
        </div> 
              </div>
            </div>
            
            
            
      

        </div>
      </section>




      {/* Start footer */}
      
    </React.Fragment>

  )
}

export default Landingpage