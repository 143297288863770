import React, { useEffect, useState } from 'react'
import Modal from '../../../common/htmlModal';
import AddCandidateModalForm from './addCandidateModalForm';
import JobQuestionsAnswerModal from './jobQuestionsAnswerModal';
import moment from 'moment';
import DrawerModal from '../../../common/htmlDrawerModal';
import EmailMessageModal from '../../../common/EmailMessageModal';
import { ToastContainer, toast } from 'react-toastify';

import CandidateMapModel from './candidateApplyModal';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import CanididateDetailDrawerModal from '../../candidates/canididateDetailDrawerModal';
import { v3_send_job_applications_mail, v3_update_hiring_status } from '../../../../actions/v3/jobApplication';
import Pagination from '../../../common/Pagination';
import constant from '../../../../constant';
import UpdateHiringStatusModal from './updateHiringStatusModal';
import { v3_lead_owners } from '../../../../actions/v3/candidates';
import { v1_application_status_options } from '../../../../actions/v1/master_data';
import { v3_crm_user_option_based_on_role } from '../../../../actions/v3/administrator';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import BreadCrumb from '../../../common/BreadCrumb'


const List = (props) => {
  const { jobData, appliedCandidates } = props;
  const [showAddCandidateForm, setShowAddCandidateForm] = useState(false);
  const [showCandidateMapModel, setShowCandidateMapModel] = useState(false);
  const [showJobQuestionForm, setShowJobQuestionForm] = useState(false);
  const [currentApplicationId, setCurrentApplicationId] = useState(null);
  const [showCandidateDetailDrawerModal, setShowCandidateDetailDrawerModal] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [updateHiringStatus, setUpdateHiringStatus] = useState(null)

  const [showEmailModal, setShowEmailModal] = useState(null);
  const [searchState, setSearchState] = useState({
    LEAD_OWNER: { name: 'LEAD_OWNER', value: '', options: props.leadOwnerOptions ? props.leadOwnerOptions : [], error: '', isRequired: true },
    HIRING_STATUS: { name: 'HIRING_STATUS', value: '', options: [], error: '', isRequired: true },
    FROM_DATE: { name: 'FROM_DATE', value: '', options: [], error: '', isRequired: true },
    TO_DATE: { name: 'TO_DATE', value: '', options: [], error: '', isRequired: true },

  });

  useEffect(() => {
    // v3_crm_user_option_based_on_role().then(res => {
    //   if (res.status) {
    //     setOptions('LEAD_OWNER', res.result);
    //   }
    // });

    v1_application_status_options().then((res) => {
      if (res.status) {
        setOptions('HIRING_STATUS', res.result)
      }
      else {

      }
    })
  }, []);

  const setOptions = (name, options) => {
    setSearchState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        options: options
      }
    }));
  }

  const onSendEmailMEssage = (model) => {
    model.APPLICATION_ID = showEmailModal.APPLICATION_ID
    v3_send_job_applications_mail(model).then((res) => {
      if (res.status) {
        toast.success('Message Send Successfully')
        setShowEmailModal(null)
      }
      else {
        toast.error(res.error)

      }
    })
  }

  const onUpdateHiringStatus = (model) => {
    v3_update_hiring_status({ ...model, APPLICATION_ID: updateHiringStatus.APPLICATION_ID }).then((res) => {
      if (res.status) {
        toast.success('Hiring Status Changed');
        setUpdateHiringStatus(null);
      }
      else {
        toast.error(res.error)

      }
    })
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchState(prevState => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value
      }
    }));
  }

  const onDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(appliedCandidates.map((item) => {
      const model = {
        'Application ID ': 'RJZSHJA' + item.APPLICATION_ID,
        'Apply Date': moment(item.APPLIED_ON).format('DD-MM-YYYY'),
        'Email': item.EMAIL,
        'Mobile': item.PHONE,
        'Skills': item.SKILLS,
        'Current Company': item.CURRENT_COMPANY ? item.CURRENT_COMPANY : 'Not Available',
        'Current Designation': item.CURRENT_DESIGNATION ? item.CURRENT_DESIGNATION : 'Not Available',
        'Notice Period': item.NOTICE_PERIOD ? item.NOTICE_PERIOD : 'Not Available',
        'Hiring Status': item.CURRENT_STATUS_DETAILS.STATUS_NAME,
        'Last Modified': moment(item.CURRENT_STATUS_DETAILS.CREATED_ON).format('DD-MM-YYYY'),
        'Lead Owner': item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available',
        'Added By': item.CREATED_BY_NAME ? item.CREATED_BY_NAME + ' at ' + moment(item.CREATED_ON).fromNow() : 'Not Available'
      }
      return model
    }));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    saveAs(data, jobData.JOB_TITLE + '.xlsx');
  }
  return (
    <React.Fragment>
      <ToastContainer />
      <DrawerModal
        isOpen={showCandidateDetailDrawerModal}
        onClose={() => { setShowCandidateDetailDrawerModal(false) }}
      >
        {candidateDetails && <CanididateDetailDrawerModal
          candidateDetails={candidateDetails}
        />}
      </DrawerModal>

      <Modal
        isOpen={showCandidateMapModel}
        onClose={() => { setShowCandidateMapModel(false) }}
        header={'Apply with Existing Employee'}
      >
        <CandidateMapModel
          jobData={props.jobData}
        />
      </Modal>

      <Modal
        isOpen={showEmailModal}
        onClose={() => { setShowEmailModal(null) }}
        header={'New Message'}
      >
        {
          showEmailModal && <EmailMessageModal
            TO={showEmailModal.EMAIL}
            onSendEmailMEssage={onSendEmailMEssage}
          />
        }
      </Modal>


      <Modal
        isOpen={updateHiringStatus}
        onClose={() => { setUpdateHiringStatus(null) }}
        header={'Update Hiring Status'}
      >
        {
          updateHiringStatus && <UpdateHiringStatusModal
            onSave={(id) => { onUpdateHiringStatus(id) }}
          />
        }
      </Modal>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <BreadCrumb
              page={jobData.JOB_TITLE}
              parentSection={<ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="javascript: void(0);">Posted Jobs</a>
                </li>
                <li className="breadcrumb-item active">Candidates Leads</li>
              </ol>}
            />
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header border-0">
                    <div className="row g-4 align-items-center">
                      <div className="col-sm-3">
                        <div >

                          <select
                            className={searchState.LEAD_OWNER.error && !searchState.LEAD_OWNER.value ? 'form-select is-invalid' : 'form-select'}
                            onChange={onChange}
                            value={searchState.LEAD_OWNER.value}
                            name={searchState.LEAD_OWNER.name}
                          >
                            <option value=''>
                              Select Lead Owner
                            </option>
                            {searchState.LEAD_OWNER.options && searchState.LEAD_OWNER.options.map((item) => {
                              return (
                                <option key={item.USER_ID} value={item.USER_ID}>
                                  {item.FULL_NAME}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div >

                          <select
                            className={searchState.HIRING_STATUS.error && !searchState.HIRING_STATUS.value ? 'form-select is-invalid' : 'form-select'}
                            onChange={onChange}
                            value={searchState.HIRING_STATUS.value}
                            name={searchState.HIRING_STATUS.name}
                          >
                            <option value=''>
                              Select Hiring Status
                            </option>
                            {searchState.HIRING_STATUS.options && searchState.HIRING_STATUS.options.map((item) => {
                              return (<option value={item.STATUS_ID}>
                                {item.STATUS_NAME}
                              </option>)
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div >

                          <input
                            type='date'
                            className={searchState.FROM_DATE.error && !searchState.FROM_DATE.value ? 'form-control is-invalid' : 'form-control'}
                            onChange={onChange}
                            value={searchState.FROM_DATE.value}
                            name={searchState.FROM_DATE.name}
                          />

                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div >

                          <input
                            type='date'
                            className={searchState.TO_DATE.error && !searchState.TO_DATE.value ? 'form-control is-invalid' : 'form-control'}
                            onChange={onChange}
                            value={searchState.TO_DATE.value}
                            name={searchState.TO_DATE.name}

                          />

                        </div>
                      </div>

                      <div className="col-sm-1">
                        <button
                          className="btn btn-soft-danger"
                          onClick={() => {
                            props.onSearch({
                              STATUS_ID: searchState.HIRING_STATUS.value,
                              LEAD_OWNER: searchState.LEAD_OWNER.value,
                              FROM_DATE: searchState.FROM_DATE.value,
                              TO_DATE: searchState.TO_DATE.value,
                            })
                          }}
                        >
                          <i className="ri-search-line" />
                        </button>
                      </div>
                      <div className="col-sm-auto ms-auto">
                        <div className="hstack gap-2">

                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => { props.setShowJobOverview(true) }}
                          >
                            <i className="ri-rocket-2-line align-bottom me-1" />{" "}
                            View Job Overview
                          </button>
                          <button
                            type="button"
                            className="btn btn-warning add-btn"
                            onClick={() => {
                              setShowCandidateMapModel(true)
                            }}
                          >
                            <i className="ri-add-line align-bottom me-1" /> Apply With Existing Candidate
                          </button>
                          <button
                            type="button"
                            className="btn btn-success add-btn"
                            onClick={() => {
                              setShowAddCandidateForm(true)
                            }}
                          >
                            <i className="ri-add-line align-bottom me-1" /> Add New Candidate
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-header border-0">


                    <div className="row align-items-end">
                      <div className="col">
                        <div id="mail-filter-navlist" style={{ display: "block" }}>
                          <ul
                            className="nav nav-tabs nav-tabs-custom nav-primary gap-1 text-center border-bottom-0"
                          >
                            <li className="nav-item" role="presentation">
                              <button
                                onClick={() => { props.setApplicationStatus('all') }}
                                className="nav-link fw-semibold active"
                                id="pills-all-tab"
                                type="button"
                              >
                                <i className="ri-price-tag-3-fill align-bottom d-inline-block" />


                                <span className="ms-1 d-none d-sm-inline-block">All</span>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                onClick={() => { props.setApplicationStatus('shortlisted') }}

                                className="nav-link fw-semibold"
                                id="pills-shortlisted-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-shortlisted"
                                type="button"
                                role="tab"
                                aria-controls="pills-shortlisted"
                                aria-selected="false"
                                tabIndex={-1}
                              >
                                <i className="ri-price-tag-3-fill align-bottom d-inline-block" />

                                <span className="ms-1 d-none d-sm-inline-block">Shortlisted</span>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                onClick={() => { props.setApplicationStatus('interview') }}

                                className="nav-link fw-semibold"
                                id="pills-interview-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-interview"
                                type="button"
                                role="tab"
                                aria-controls="pills-interview"
                                aria-selected="false"
                                tabIndex={-1}
                              >
                                <i className="ri-price-tag-3-fill align-bottom d-inline-block" />
                                <span className="ms-1 d-none d-sm-inline-block">Interview</span>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                onClick={() => { props.setApplicationStatus('selected') }}

                                className="nav-link fw-semibold"
                                id="pills-selected-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-selected"
                                type="button"
                                aria-controls="pills-selected"
                              >
                                <i className="ri-price-tag-3-fill align-bottom d-inline-block" />
                                <span className="ms-1 d-none d-sm-inline-block">Selected</span>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button
                                onClick={() => { props.setApplicationStatus('rejected') }}

                                className="nav-link fw-semibold"
                                type="button"
                              >
                                <i className="ri-price-tag-3-fill align-bottom d-inline-block" />
                                <span className="ms-1 d-none d-sm-inline-block">Rejected</span>
                              </button>
                            </li>
                          </ul>

                        </div>

                      </div>
                      <div className="col">

                        <button
                          type="button"
                          className="btn btn-primary add-btn"
                          onClick={() => onDownloadExcel()}
                        >
                          <i className="mdi mdi-file-excel align-bottom me-1" />
                          <span className="ms-1 d-none d-sm-inline-block">Download Excel</span>
                        </button>
                      </div>
                    </div>

                  </div>
                  <div className="card-body">
                    {appliedCandidates && appliedCandidates.map(item => {
                      return (
                        <a class="clientSection">
                          <div class="row">
                            <div class="col-md-6 clientSectionLeft">
                              <h3>
                                {item.FULL_NAME}
                                <span>
                                  <h6>{item.WORKING_LOCATION}</h6>
                                </span>
                              </h3>

                              <p>
                                <i class="ri-user-fill"></i> Application Id : RJZSHJA{item.APPLICATION_ID}
                              </p>
                              <p>
                                <i class="ri-user-settings-fill"></i> Apply Date : {moment(item.APPLIED_ON).format('DD-MM-YYYY')}
                              </p>
                              <p>
                                <i class="ri-mail-fill"></i> Email : {item.EMAIL}
                                <span>
                                  <i class="ri-phone-fill"></i> Mobile : {item.PHONE}

                                </span>
                              </p>
                              <p>
                                <i class="ri-user-settings-fill"></i> Skills : {item.SKILLS}
                              </p>
                            </div>
                            <div class="col-md-5 clientSectionRight">
                              <p>
                                <i class="ri-building-fill"></i> Current Company : {item.CURRENT_COMPANY ? item.CURRENT_COMPANY : 'Not Available'}
                              </p>
                              <p>
                                <i class="ri-user-fill"></i> Current Designation
                                :{item.CURRENT_DESIGNATION ? item.CURRENT_DESIGNATION : 'Not Available'}
                              </p>
                              <p>
                                <i class="ri-bar-chart-grouped-line"></i>{" "}
                                Notice Period : {item.NOTICE_PERIOD ? item.NOTICE_PERIOD : 'Not Available'}
                              </p>
                              <p>
                                <i class="ri-chat-new-line"></i> Hiring Status : {item.CURRENT_STATUS_DETAILS.STATUS_NAME}
                              </p>
                              <p>
                                <i class="ri-time-fill"></i> Last Modified : {moment(item.CURRENT_STATUS_DETAILS.CREATED_ON).format('DD-MM-YYYY')}

                              </p>
                              <p>
                                <i class="ri-bar-chart-grouped-line"></i>{" "}
                                Lead Owner : {item.LEAD_OWNER_NAME ? item.LEAD_OWNER_NAME : 'Not Available'}
                              </p>
                              <p>
                                <i class="ri-bar-chart-grouped-line"></i>{" "}
                                Added By : {item.CREATED_BY_NAME ? item.CREATED_BY_NAME + ' at ' + moment(item.CREATED_ON).fromNow() : 'Not Available'}
                              </p>
                            </div>
                            <div class="col-md-1 clientMenuBtnSection">
                              <a href="javascript:void(0);"
                                onClick={() => { setShowEmailModal(item) }}
                              >
                                <i class="ri-question-answer-line"></i>
                              </a>
                              {/* <a   href="javascript:void(0);"
                                        className="text-muted d-inline-block"
                                        onClick={() => { setShowEmailModal(item) }}>
                                    <i class="ri-question-answer"></i>
                                  </a> */}
                              <a
                                onClick={() => {
                                  setCandidateDetails(item);
                                  setShowCandidateDetailDrawerModal(true);
                                }}
                              >
                                <i class="ri-eye-fill"></i>
                              </a>
                              <a href={`tel:${item.PHONE}`}>
                                <i class="ri-phone-fill"></i>
                              </a>


                              <a href="javascript:void(0)"
                                onClick={() => {
                                  setUpdateHiringStatus(item)
                                }}>
                                <i class="ri-edit-box-line"></i>
                              </a>
                            </div>
                          </div>
                        </a>
                      )
                    })
                    }
                    <div className="customPaginationStyle">
                      <Pagination
                        totalCount={props.totalAppliedCandidates}
                        limit={20}
                        range={5}
                        currentPage={Number(props.match.params.page_number)}
                        onPageChange={(page) => { props.history.push(constant.companyUserComponent.jobApplications.url.replace(':job_id', props.match.params.job_id).replace(':page_number', page)) }}
                      />
                    </div>

                    {/* <div>
                      <div className="table-responsive table-card">
                        <table className="table align-middle" id="customerTable">
                          <thead className="table-light">
                            <tr>
                              <th className="sort" >
                                Application ID
                              </th>
                              <th className="sort" >
                                Name
                              </th>

                              <th className="sort" >
                                Email
                              </th>
                              <th className="sort" >
                                Phone
                              </th>
                              <th className="sort" >
                                Apply Date
                              </th>
                              <th className="sort" >
                                Latest Update
                              </th>

                              {props.jobQuestion && props.jobQuestion.length > 0 &&
                                <th className="sort" >
                                  Questions Responded
                                </th>
                              }
                              <th className="sort" >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="list form-check-all">
                            {appliedCandidates && appliedCandidates.map((item, idex) => {
                              return (<tr>
                                <td className="id" >
                                  <a
                                    href="javascript:void(0);"
                                    className="fw-medium link-primary"
                                  >
                                    RJZSHJA{item.APPLICATION_ID}
                                  </a>
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <div className="flex-grow-1 name">
                                      {item.FULL_NAME}
                                    </div>
                                  </div>
                                </td>
                                <td className="company_name">{item.EMAIL}</td>
                                <td className="phone">{item.PHONE}</td>
                                <td className="date">{moment(item.APPLIED_ON).format('DD MMM YYYY')}</td>
                                <td >
                                  <tr><strong>Hiring Status : </strong> {item.CURRENT_STATUS_DETAILS.STATUS_NAME}</tr>
                                  <tr><strong>Updated On : </strong>{moment(item.CURRENT_STATUS_DETAILS.CREATED_ON).format('DD MMM YYYY on hh:mm A')}</tr>
                                </td>
                                {
                                  item.question_answer &&
                                  <td >{item.question_answer.length > 0 ? 'Yes' : 'No'}</td>
                                }
                                <td>
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    <li
                                      className="list-inline-item edit"
                                    >
                                      <a
                                        href={`tel:${item.PHONE}`}
                                        className="text-muted d-inline-block"
                                      >
                                        <i className="ri-phone-line fs-16" />
                                      </a>
                                    </li>
                                    <li
                                      className="list-inline-item edit"
                                    >
                                      <a
                                        href="javascript:void(0);"
                                        className="text-muted d-inline-block"
                                        onClick={() => { setShowEmailModal(item) }}
                                      >
                                        <i className="ri-question-answer-line fs-16" />
                                      </a>
                                    </li>
                                    <li
                                      className="list-inline-item"
                                      aria-label="View"
                                      onClick={() => {
                                        setCandidateDetails(item)
                                        setShowCandidateDetailDrawerModal(true)
                                      }}
                                    >
                                      <a href="javascript:void(0);">
                                        <i className="ri-eye-fill align-bottom text-muted" />
                                      </a>
                                    </li>

                                    <li
                                      className="list-inline-item"
                                      aria-label="View"
                                      onClick={() => {
                                        setUpdateHiringStatus(item)
                                      }}
                                    >
                                      <a href="javascript:void(0);">

                                        <i className="ri-edit-box-line align-bottom text-muted" />
                                      </a>
                                    </li>


                                  </ul>
                                </td>
                              </tr>)
                            })
                            }
                          </tbody>
                        </table>
                        {props.totalAppliedCandidates == 0 && <div className="noresult" >
                          <div className="text-center">
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#121331,secondary:#08a88a"
                              style={{ width: 75, height: 75 }}
                            ></lord-icon>
                            <h5 className="mt-2">Sorry! No Result Found</h5>
                            <p className="text-muted mb-0">
                              We've searched more than 150+ leads We did not find any
                              leads for you search.
                            </p>
                          </div>
                        </div>}
                      </div>
                      <Pagination
                        totalCount={props.totalAppliedCandidates}
                        limit={20}
                        range={5}
                        currentPage={Number(props.match.params.page_number)}
                        onPageChange={(page) => { props.history.push(constant.companyUserComponent.jobApplications.url.replace(':job_id', props.match.params.job_id).replace(':page_number', page)) }}

                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Modal
        isOpen={showJobQuestionForm}
        header='Job Questions'
        onClose={() => { setShowJobQuestionForm(false) }}

      >
        <JobQuestionsAnswerModal
          onClose={() => { setShowJobQuestionForm(false) }}
          QUESTIONS={jobData.JOB_QUESTIONS}
          currentApplicationId={currentApplicationId}
        />

      </Modal>

      <Modal
        isOpen={showAddCandidateForm}
        onClose={() => { setShowAddCandidateForm(false) }}
        header={'Candidate Lead Form'}
      >
        <AddCandidateModalForm
          onClose={() => { setShowAddCandidateForm(false) }}
          setCurrentApplicationId={setCurrentApplicationId}
          openJobQuestionForm={() => {

            if (jobData.JOB_QUESTIONS.length > 0) {
              setShowJobQuestionForm(true)
            }
            else {
              console.log('no job questions')
            }
          }}
        />
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(List)